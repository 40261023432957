interface ReactMethods {
  moveSelectionUp: CallableFunction;
  moveSelectionDown: CallableFunction;
  setResults: CallableFunction;
  // eslint-disable-next-line no-unused-vars
  setShow: (value: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setPos: (x: number, y: number) => void;
  getSelectedEmoji: () => string;
}

class EmojiAdapter {
  public moveSelectionUp: CallableFunction = () => null;

  public moveSelectionDown: CallableFunction = () => null;

  public setResults: CallableFunction = () => null;

  public setShow: CallableFunction = () => null;

  public setPos: CallableFunction = () => null;

  public getSelectedEmoji: CallableFunction = () => null;

  set reactMethods({
    moveSelectionUp,
    moveSelectionDown,
    setResults,
    setShow,
    setPos,
    getSelectedEmoji,
  }: ReactMethods) {
    this.moveSelectionUp = moveSelectionUp;
    this.moveSelectionDown = moveSelectionDown;
    this.setResults = setResults;
    this.setShow = setShow;
    this.setPos = setPos;
    this.getSelectedEmoji = getSelectedEmoji;
  }
}

export default new EmojiAdapter();
