import React from 'react';
import QuickSettings from '../quick-settings';
import ShepherdLogo from '../../icons/shepherd/ShepherdLogo';
import CloseSquareIcon from '../../icons/closeSquare';
import SendCloseSidebarWindowMessage from '../../../utils/sendWindowMessages/closeSidebar';
import AddUserAtom from './AddUserAtom';
import {
  Container, LeftContainer, BetaSign, IconContainer, IconGroupContainer,
} from './utils/styles';
import { DEFAULT_HEADER_MEETING_VIEW, HEADER_DASHBORD_VIEW } from './utils/constants';
import { useUI } from '../../../pages/all-pages/ui-context/uiContext';
import { TopHeaderState } from '../../types/types';

export interface Props {
  view?: TopHeaderState
}

const Header = ({ view = DEFAULT_HEADER_MEETING_VIEW }: Props) => {
  const { openInviteMemberModal } = useUI();
  const handleCloseSidebarClick = () => {
    SendCloseSidebarWindowMessage();
  };

  const renderSettingsAndLogo = () => {
    if (view === DEFAULT_HEADER_MEETING_VIEW || view === HEADER_DASHBORD_VIEW) {
      return (
        <LeftContainer>
          <QuickSettings />
          <ShepherdLogo />
          <BetaSign>BETA</BetaSign>
        </LeftContainer>
      );
    }

    return null;
  };

  return (
    <Container view={view}>
      {renderSettingsAndLogo()}
      <IconGroupContainer>
        <AddUserAtom onClick={openInviteMemberModal} />
        <IconContainer onClick={handleCloseSidebarClick}>
          <CloseSquareIcon width="24" height="24" />
        </IconContainer>
      </IconGroupContainer>
    </Container>
  );
};

Header.defaultProps = {
  view: DEFAULT_HEADER_MEETING_VIEW,
};

export default Header;
