import React, {
  RefObject, useEffect, useState,
} from 'react';
import styled, { css } from 'styled-components';
import { blue6, gray7 } from '../../../shared/colours';
import { UserCenterPage } from '../../../shared/types/types';
import { getTabButtonStartingPosition, getTabButtonWidth } from './utils';

interface IndicatorProps {
  width: number,
  left: number,
  isHover: boolean,
}

const Line = styled.div<IndicatorProps>`
  height: 4px;
  position: absolute;
  bottom: 0;
  left: ${(props: IndicatorProps) => props.left}px;
  width: ${(props: IndicatorProps) => props.width}px;
  border-bottom: 4px solid ${blue6};
  /* Commented out line as per designer request */
  /* ${(props: IndicatorProps) => (props.isHover && css`border-bottom-color: ${gray7};`)} */
  border-radius: 4px 4px 0px 0px;
  transition: all 0.3s ease-in-out;
`;

interface Props {
  section: UserCenterPage;
  buttonsParentRef: RefObject<HTMLElement>;
  isHover: boolean;
}

/**
 * Blue line under the active tab button
 */
const BlueLineAtom = ({ section, buttonsParentRef, isHover }: Props) => {
  const [leftOffset, setLeftOffset] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    if (buttonsParentRef.current === null) return;
    // the data-is-selected attributed is defined in ./PageTabButtonAtom.tsx
    const buttons: HTMLElement[] = Array.from(
      buttonsParentRef.current.querySelectorAll('*[data-is-selected]'),
    );
    setLeftOffset(getTabButtonStartingPosition(buttons));
    setWidth(getTabButtonWidth(buttons));
  }, [section]);

  return (
    <Line left={leftOffset} width={width} isHover={isHover} />
  );
};

export default BlueLineAtom;
