import { Dispatch, SetStateAction } from 'react';
import { pendingGapiMeetingData } from '../../database/utils/gapiMeetingDataUtils';
import updateMeetingDataFromGapiMeetingData from '../../database/utils/updateMeetingDataFromCalendarData';
import { GapiMeetingData, MeetingData, User } from '../../shared/types/types';
import { gapiGetMeeting } from '../../utils/google/GoogleCalendarAPI';

const getGapiData = async (
  meetingData: MeetingData,
  user: User,
  setGapiMeetingData: Dispatch<SetStateAction<GapiMeetingData>>,
) => {
  console.log('Calling getGapiData');
  setGapiMeetingData(pendingGapiMeetingData);
  const gapiMeetingData = await gapiGetMeeting(
    meetingData.googleData.ids.eventId,
    meetingData.googleData.ids.calendarId,
    user.publicUserData.data.email,
    meetingData,
  );
  console.log(`Google Calendar API response: (eventId: ${meetingData.googleData.ids.eventId}), and (calendarId: ${meetingData.googleData.ids.calendarId})`);
  setGapiMeetingData(gapiMeetingData);

  updateMeetingDataFromGapiMeetingData(gapiMeetingData, meetingData, user);
};

export default getGapiData;
