// Core functions always suffix with "Core"
import * as Sentry from '@sentry/browser';
import { ResolveState, SendGridEmailRecipient } from '../shared/types/types';
import { functions } from '../utils/firebase';
import { toastDanger } from '../utils/notifications';

export const cfSendMeetingNotesEmailCore = (
  emails: SendGridEmailRecipient[], meetingTitle: string, html: string, templateId: string,
) => functions()
  .httpsCallable('sendMeetingNotes')({
    emails, meetingTitle, html, templateId,
  })
  .catch((error) => {
    Sentry.captureException(error);
    throw new Error(error);
  });

export const cfSendQuickFeedbackEmailCore = (
  email: string, feedback: string,
) => functions()
  .httpsCallable('sendQuickFeedback')({
    email, feedback,
  })
  .catch((error) => {
    Sentry.captureException(error);
    throw new Error(error);
  });

export const cfGetAssigneeDataByEmailsCore = (emails: string[]) => functions()
  .httpsCallable('searchAssigneeDataByEmails')({ emails })
  .catch((error) => {
    Sentry.captureException(error);
    console.error('cfGetAssigneeDatabyEmailsCore');
    console.error(error);
    toastDanger('Error', error?.message ?? 'Error');
    return {
      data: [],
    };
  });

export const cfSendTaskChangeNotificationEmailCore = (
  email: string,
  operatorName: string,
  actionDescription: string,
  taskAssignee: string,
  taskTitle: string,
  dueDateDisplay: 'none' | 'unset',
  taskDueDate: string,
  overdueColor: string,
  iconUrl: string,
  description: string,
) => functions()
  .httpsCallable('sendTaskChangeNotification')({
    email,
    operatorName,
    actionDescription,
    taskAssignee,
    taskTitle,
    dueDateDisplay,
    taskDueDate,
    overdueColor,
    iconUrl,
    description,
  })
  .catch((error) => {
    Sentry.captureException(error);
    throw new Error(error);
  });

export const cfSendWelcomeEmailCore = async (
  firstName: string,
): Promise<ResolveState> => functions().httpsCallable('sendWelcomeEmail')({ name: firstName })
  .then(() => 'resolved' as ResolveState)
  .catch((error) => {
    console.log('Error sending welcome email', error);
    return 'rejected';
  });

export const cfSendInviteMembersEmailsCore = async (
  emails: string[],
): Promise<ResolveState> => functions().httpsCallable('sendInviteMembersEmailsV2')({ emails })
  .then(() => 'resolved' as ResolveState)
  .catch((error) => {
    console.log('Error sending invite members email', error);
    return 'rejected';
  });
