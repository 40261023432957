/* eslint-disable no-unused-vars */
import { NodeType, Schema } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';
import {
  useMemo,
  useState,
} from 'react';
import commandAdapter from '../logic/suggestions/command-adapter';

interface CommandsSuggesterProps {
  view: EditorView,
  openGifModal: CallableFunction;
}

export default function useCommandsSuggester({
  view,
  openGifModal,
}: CommandsSuggesterProps) {
  const availableCommands = useMemo(() => ([
    {
      name: 'gif',
      callback: () => {
        openGifModal();
      },
    },
    {
      name: 'time',
      useTransactionLogic: true,
      callback: () => {
        if (!view) return;
        const { tr } = view.state;
        const { state, dispatch } = view;
        const timestamp: NodeType = (view.state.schema as Schema).nodes.resolvedTimestamp;
        const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('command-suggesting')[0], 0);
        tr.replaceRangeWith(
          currentPos,
          state.selection.$anchor.pos,
          timestamp.create({}, [(state.schema as Schema).text((new Date()).toUTCString())]),
        );
        view.dispatch!(tr);
      },
    },
  ]), [openGifModal]);

  const [show, setShow] = useState<boolean>(false);

  const [left, setLeft] = useState<number>(0);

  const [top, setTop] = useState<number>(0);

  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const [results, setResults] = useState<any[]>([]);

  function rotateSelectionUp() {
    if (selectedIndex < 1) {
      setSelectedIndex(results.length - 1);
    } else {
      setSelectedIndex(selectedIndex - 1);
    }
  }

  function rotateSelectionDown() {
    setSelectedIndex((selectedIndex + 1) % results.length);
  }

  function setViewable(value: boolean) {
    setShow(value);
  }

  function setPos(x: number, y: number) {
    setTop(y);
    setLeft(Math.min(Math.max(x, 0), 320));
  }

  function getSelectedCommand() {
    if (selectedIndex >= results.length) return '';
    return results[selectedIndex].item;
  }

  function setResultsAndReset(data: any[]) {
    setResults(data);
    setSelectedIndex(0);
  }

  commandAdapter.reactMethods = {
    moveSelectionUp: rotateSelectionUp,
    moveSelectionDown: rotateSelectionDown,
    setResults: setResultsAndReset,
    setShow: setViewable,
    setPos,
    getSelectedCommand,
    getCommands: () => availableCommands,
  };

  return {
    commandsShow: [show, setShow] as const,
    commandsLeft: [left, setLeft] as const,
    commandsTop: [top, setTop] as const,
    commandsContents: [results, setResults] as const,
    commandsSelected: [selectedIndex, setSelectedIndex] as const,
  };
}
