import React, { useState } from 'react';
import { Redirect } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import LogoText from '../../shared/icons/logo';
import OnboardingSubmitButton from '../onboarding/personal/components/onboarding-submit-button';
import GoogleIcon from '../../shared/icons/google';
import RetryAuthModal from './RetryAuthModal';
import Header from '../../shared/components/header';
import { dashboardPath, onboardingPersonalPath } from '../../routes';
import { signInToGoogleAndFirebase } from '../../utils/google/GoogleCalendarSetup';
import {
  Container, ContentWrapper, HeaderText, Subtitle, LogInWithGoogleButtonContainer,
} from './utils/styles';
import { surface } from '../../shared/colours';
import { BUTTON_TEXT, HEADER_TEXT, SUBHEADER_TEXT } from './utils/constants';
import { HEADER_SIGNIN_VIEW } from '../../shared/components/header/utils/constants';

interface PageProps {
  isAuthenticated: boolean,
  isOnboarded: boolean
  isLoading: boolean,
}

const Signin = ({
  isAuthenticated, isOnboarded, isLoading,
}: PageProps) => {
  const { trackEvent } = useIntercom();

  const [isRetryAuthModalOpen, setRetryAuthModalOpen] = useState<boolean>(false);

  const closeRetryModal = () => {
    setRetryAuthModalOpen(false);
  };

  const onRetryClick = () => {
    closeRetryModal();
    handleSigninWithGoogleClick();
  };

  const handleSigninWithGoogleClick = () => {
    signInToGoogleAndFirebase(() => { setRetryAuthModalOpen(true); }, trackEvent);
  };

  if (isAuthenticated && isOnboarded) {
    return <Redirect to={dashboardPath} />;
  }

  if (isAuthenticated && !isOnboarded) {
    return <Redirect to={onboardingPersonalPath} />;
  }

  return (
    <Container>
      <Header view={HEADER_SIGNIN_VIEW} />
      <LogoText textColour={surface} width="267" />
      <ContentWrapper>
        <HeaderText>{HEADER_TEXT}</HeaderText>
        <Subtitle>{SUBHEADER_TEXT}</Subtitle>
        <LogInWithGoogleButtonContainer>
          <OnboardingSubmitButton
            text={BUTTON_TEXT}
            loading={isLoading}
            onClick={handleSigninWithGoogleClick}
            hasIcon
            Icon={GoogleIcon}
          />
        </LogInWithGoogleButtonContainer>
      </ContentWrapper>
      <RetryAuthModal
        isModalOpen={isRetryAuthModalOpen}
        onCancelClick={closeRetryModal}
        onRetryClick={onRetryClick}
      />
    </Container>
  );
};

export default Signin;
