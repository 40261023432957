import React, { useState } from 'react';

import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import { useHistory } from 'react-router-dom';
import ButtonSmall from '../../../shared/components/button-small';
import {
  purple6, red6, purple7, purple5, red5, red7, gray1,
} from '../../../shared/colours';
import {
  logEventButtonClickWelcomeBannerHelp,
  logEventButtonClickWelcomeBannerVideo,
} from '../../../utils/analytics/eventLogger';
import { tutorialVideoPath } from '../../../routes';
import { defaultSmall } from '../../../shared/typography';

const Container = styled.div`

  & > button {
    ${defaultSmall}
    color: ${gray1};
    width: 111px;

    &:not(:last-child) {
      margin-bottom: 6px;
    }

    &:nth-child(1) {
      background-color: ${purple6};
      &:hover {
        background-color: ${purple5};
      }
      &:focus {
        background-color: ${purple6};
      }
      &:active {
        background-color: ${purple7};
        color: ${gray1};
      }
    }

    &:nth-child(2) {
      background-color: ${red6};
      &:hover {
        background-color: ${red5};
      }
      &:focus {
        background-color: ${red6};
      }
      &:active {
        background-color: ${red7};
        color: ${gray1};
      }
    }
  }
`;

const HelpButtonGroup = () => {
  const [isIntercomOpen, setIntercomOpen] = useState<boolean>(false);
  const { show, hide, trackEvent } = useIntercom();
  const history = useHistory();

  const toggleIntercom = () => {
    logEventButtonClickWelcomeBannerHelp(trackEvent);
    if (isIntercomOpen) {
      hide();
      setIntercomOpen(false);
    } else {
      show();
      setIntercomOpen(true);
    }
  };

  const goToTutorialPage = () => {
    logEventButtonClickWelcomeBannerVideo(trackEvent);
    history.push(tutorialVideoPath);
  };

  return (
    <Container>
      <ButtonSmall text="Watch tutorial" onClick={goToTutorialPage} />
      <ButtonSmall text="Help center" onClick={toggleIntercom} />
    </Container>
  );
};

export default HelpButtonGroup;
