import React, {
  Dispatch, SetStateAction, useState,
} from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import { darkBlue1, gray1 } from '../../colours';
import { header200 } from '../../typography';
import SaveTemplateModal from '../templates-modal';
import { MeetingSection, MeetingVersion } from '../../types/types';
import { logTemplateUserAction } from '../../../utils/analytics/eventLogger';
import { OPEN_EVENT, TEMPLATES_BROWSE_MY_TEMPLATES_BUTTON_FIELD, TEMPLATES_SAVE_AS_TEMPLATE_BUTTON_FIELD } from '../../../utils/analytics/enums';

const Container = styled.div`
  position: absolute;
  bottom: 50px;
`;

const DropdownContainer = styled.div`
  background-color: ${gray1};
  box-shadow: 5px 5px 18px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.25);

  border-radius: 8px;
`;

const DropdownOptionsContainer = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;

  list-style: none;
  padding: 8px;
  margin: 0px;

  font-size: ${header200};
  white-space: nowrap;
`;

const DropdownOption = styled.li`
  cursor: pointer;

  padding: 6px 8px;
  border-radius: 8px;

  &:hover {
    background-color: ${darkBlue1};
  }
`;

interface Props {
  meetingTab: MeetingSection
  isOpen: boolean,
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  dropdownRef: React.MutableRefObject<null>,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>,
  version: MeetingVersion,
}

const TemplatesDropDown = ({
  isOpen,
  setIsOpen,
  dropdownRef,
  setIsTemplatesOpen,
  meetingTab,
  version,
}: Props) => {
  const [isSaveTemplateModalOpen, setIsSaveTemplateModalOpen] = useState(false);
  const { trackEvent } = useIntercom();

  const openSaveTemplateModal = () => {
    setIsSaveTemplateModalOpen(true);
    logTemplateUserAction(trackEvent, OPEN_EVENT, TEMPLATES_SAVE_AS_TEMPLATE_BUTTON_FIELD, version);
    setIsOpen(false);
  };

  const closeSaveTemplateModal = () => {
    setIsSaveTemplateModalOpen(false);
  };

  const openBrowseTemplates = () => {
    setIsTemplatesOpen(true);
    logTemplateUserAction(trackEvent, OPEN_EVENT, TEMPLATES_BROWSE_MY_TEMPLATES_BUTTON_FIELD,
      version);
    setIsOpen(false);
  };

  return (
    <>
      <SaveTemplateModal
        isTemplateModalOpen={isSaveTemplateModalOpen}
        setModalClose={closeSaveTemplateModal}
        openTemplates={openBrowseTemplates}
        meetingTab={meetingTab}
      />
      {isOpen && (
        <Container>
          <DropdownContainer ref={dropdownRef}>
            <DropdownOptionsContainer>
              <DropdownOption onClick={openSaveTemplateModal}>
                Save as template
              </DropdownOption>
              <DropdownOption onClick={openBrowseTemplates}>
                Browse my templates
              </DropdownOption>
            </DropdownOptionsContainer>
          </DropdownContainer>
        </Container>
      )}
    </>
  );
};

export default TemplatesDropDown;
