import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '../../App';
import { functions } from '../../utils/firebase';
import { dbGetUserWorkspace, dbSaveUserWorkspace } from '../../database/firebaseNotionAPI';
import CssSpinner from '../../shared/components/css-spinner';
import { surface } from '../../shared/colours';
import { NotionData, ResolveState } from '../../shared/types/types';
import { header400 } from '../../shared/typography';
import useSkipEffect from '../../utils/hook/skipUseEffectRun';

const TextContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  ${header400}

  margin-top: 50px;
`;

export default function NotionTestingPage() {
  const [successfullyIntegratedNotion, setSuccessfullyIntegratedNotion] = useState<ResolveState>('pending');
  const [userWorkspace, setUserWorkspace] = useState<NotionData[]>([]);
  const [newNotionWorkSpace, setNewNotionWorkSpace] = useState<NotionData>({
    accessToken: '',
    botId: '',
    workspaceName: '',
    workspaceIcon: '',
    workspaceId: '',
  });
  const location = useLocation();
  const tempAuthCode = new URLSearchParams(location.search).get('code');

  const authState = useContext(AuthContext);

  const isValidCode = tempAuthCode && tempAuthCode.length > 10;

  useEffect(() => {
    if (isValidCode) {
      functions()
        .httpsCallable('getNotionAccessToken')({ tempAuthCode }).then((data) => {
          console.log(data, JSON.stringify(data));
          setNewNotionWorkSpace({
            accessToken: data.data.access_token,
            botId: data.data.bot_id,
            workspaceName: data.data.workspace_name,
            workspaceIcon: data.data.workspace_icon,
            workspaceId: data.data.workspace_id,
          });
          dbGetUserWorkspace(authState.userId, setUserWorkspace);
        }).catch((err) => {
          console.log('err', err);
          setSuccessfullyIntegratedNotion('rejected');
        });
    }
  }, [tempAuthCode]);

  useSkipEffect(() => {
    console.log(userWorkspace);
    const workSpaceIntegrated = userWorkspace?.filter(
      (workspace:NotionData) => workspace.workspaceId === newNotionWorkSpace?.workspaceId,
    ).length > 0;
    if (!workSpaceIntegrated) {
      const NotionWorkSpace:NotionData[] = [...userWorkspace, newNotionWorkSpace];
      dbSaveUserWorkspace(authState.userId, NotionWorkSpace);
    }
    setSuccessfullyIntegratedNotion('resolved');
  }, [userWorkspace], 1);

  if (!isValidCode) {
    return (
      <TextContainer>
        The code provided is not valid
      </TextContainer>
    );
  }

  if (successfullyIntegratedNotion === 'pending') {
    return (
      <TextContainer>
        Loading...
        {' '}
        <CssSpinner color={surface} size={30} thickness={4} padding={4} />
      </TextContainer>
    );
  }

  if (successfullyIntegratedNotion === 'resolved') {
    return (
      <TextContainer>
        Notion Integrated Successfully! 🎉 you can close this page.
      </TextContainer>
    );
  }

  return (
    <TextContainer>
      Something went wrong
    </TextContainer>
  );
}
