import React from 'react';
import styled from 'styled-components';
import { gray7 } from '../../../shared/colours';
import { EmptyIcon } from '../../../shared/icons/tasks';

const Container = styled.div`
  cursor: pointer;
  svg > rect:nth-child(2) {
    stroke: ${gray7};
  }
`;

const NotesListCheckboxAtom = () => (
  <Container>
    <EmptyIcon />
  </Container>
);

export default NotesListCheckboxAtom;
