import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import AllNotesTemplate from '.';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';
import { GapiMeetingData, UserCenterPage } from '../../shared/types/types';
import { getMeetingsInPeriod } from '../../utils/google/GoogleCalendarAPI';
import { makeGoogleCalendarUrl } from '../../utils/meetings/meetingsUtils';

interface Props {
  section: UserCenterPage;
}

const AllNotesPageCtrl = ({ section }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [meetings, setMeetings] = useState<GapiMeetingData[]>([]);
  const history = useHistory();

  useEffect(() => {
    getMeetings(setMeetings, setIsLoading);
  }, []);

  const handleGoToMeeting = (eventId: string, calendarId: string) => {
    ConsoleImproved.log('handleToGoMeeting', { eventId, calendarId });
    history.push(makeGoogleCalendarUrl(eventId, calendarId));
  };

  return (
    <AllNotesTemplate
      section={section}
      notes={meetings}
      isLoading={isLoading}
      onAction={handleGoToMeeting}
    />
  );
};

export default AllNotesPageCtrl;

const getMeetings = async (
  setMeetings: Dispatch<SetStateAction<GapiMeetingData[]>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
) => {
  // const meetings = await getTodaysMeetings();
  const meetings = await getMeetingsInPeriod(-4, 5);
  ConsoleImproved.log('Meetings in AllNotesPage', meetings);
  const sortedMeetings = meetings.sort(
    (a, b) => (
      // eslint-disable-next-line no-nested-ternary
      (a.start.dateTime > b.start.dateTime) ? -1 : (
        (a.start.dateTime < b.start.dateTime) ? 1 : 0)),
  );
  setMeetings(sortedMeetings);
  setIsLoading(false);
};
