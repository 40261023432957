import React from 'react';
import styled from 'styled-components';
import { surface } from '../../../shared/colours';
import { uiText } from '../../../shared/typography';

const Container = styled.div`
  ${uiText}
  color: ${surface};
`;

interface Props {
  text: string;
}

const NotesListCheckboxLabelAtom = ({ text }: Props) => (
  <Container>
    {text}
  </Container>
);

export default NotesListCheckboxLabelAtom;
