import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import Modal from '../../../shared/components/Modal';
import Scrollbar from '../../../shared/components/scrollbar';
import ButtonSmall from '../../../shared/components/button-small';
import ModalBodyWrapper from '../../../shared/components/Modal/ModalBodyWrapper';
import ModalFooterButtonsRightAlignedWrapper from '../../../shared/components/Modal/ModalFooterButtonsRightAlignedWrapper';
import Signature from './EmailSignature';
import { uiText } from '../../../shared/typography';
import MeetingDataContext from '../context/MeetingDataContext';
import { assembleEmailHTML } from '../../../utils/email/emailUtils';
import { Note, PrivateNoteHtml, TaskItem } from '../../../shared/types/types';
import { setNoteContent } from '../../../utils/meetings/meetingsUtils';
import { AGENDA, PERSONAL_NOTES, SHARED_NOTES } from '../../../utils/enums';
import { AuthContext } from '../../../App';
import { makeSubstringsBold } from '../../../utils/strings';
import { getUTCString } from '../../../utils/dateUtils/date';

export interface PreviewEmailModalProps {
  isModalOpen: boolean,
  onBackClick: () => void,
  emailHeadTemplate: string,
  agendaChecked: boolean,
  sharedNotesChecked: boolean,
  personalNotesChecked: boolean,
  taskChecked: boolean,
  taskItems: TaskItem[],
  agendaHtml: Note,
  sharedNotesHtml: Note,
  personalNotesHtml: Note,
  setAgendaHtml: Dispatch<SetStateAction<Note>>
  setSharedNotesHtml: Dispatch<SetStateAction<Note>>
  setPersonalNotesHtml: Dispatch<SetStateAction<Note>>
  privateSpaceNotesChecked: string[],
  privateNotesHtml: PrivateNoteHtml[],
}

const PreviewEmailModal = ({
  isModalOpen,
  onBackClick,
  emailHeadTemplate,
  agendaChecked,
  sharedNotesChecked,
  personalNotesChecked,
  taskChecked,
  taskItems,
  agendaHtml,
  sharedNotesHtml,
  personalNotesHtml,
  setAgendaHtml,
  setSharedNotesHtml,
  setPersonalNotesHtml,
  privateNotesHtml,
  privateSpaceNotesChecked,
}: PreviewEmailModalProps) => {
  const [emailHtml, setEmailHtml] = useState<string>('');

  const {
    meetingId, data: { title }, date: { start }, version,
  } = useContext(MeetingDataContext);
  const { userId, firstName, lastName } = useContext(AuthContext);

  const wrapStyle = (html: string) => `<div style="${uiText}">${html}</div>`;

  useEffect(() => {
    if (!meetingId) return;

    const emailHeadHtml = makeSubstringsBold(emailHeadTemplate, [title, getUTCString(start.date)]);

    if (version === 2) {
      setNoteContent(setAgendaHtml, AGENDA, meetingId, userId);
      setNoteContent(setSharedNotesHtml, SHARED_NOTES, meetingId, userId);
      setNoteContent(setPersonalNotesHtml, PERSONAL_NOTES, meetingId, userId);
    }

    if (version >= 3) {
      // Filler comment
    }

    const notesContentHtml = assembleEmailHTML(
      agendaChecked, sharedNotesChecked, personalNotesChecked, taskChecked,
      emailHeadHtml, agendaHtml, sharedNotesHtml, personalNotesHtml, taskItems,
      privateSpaceNotesChecked, privateNotesHtml,
    );

    setEmailHtml(wrapStyle(notesContentHtml + Signature(firstName, lastName)));
  }, [isModalOpen, emailHeadTemplate, agendaChecked, sharedNotesChecked,
    personalNotesChecked, meetingId]);

  return (
    <>
      <Modal isOpen={isModalOpen} setModalClosed={onBackClick} title="Preview before sharing">
        <Scrollbar maxHeight="60vh">
          <ModalBodyWrapper>
            <div dangerouslySetInnerHTML={{ __html: emailHtml }} />
          </ModalBodyWrapper>
        </Scrollbar>
        <ModalFooterButtonsRightAlignedWrapper>
          <ButtonSmall onClick={onBackClick} text="Back" isOutline />
        </ModalFooterButtonsRightAlignedWrapper>
      </Modal>
    </>
  );
};

export default PreviewEmailModal;
