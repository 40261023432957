import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { MeetingSection, PrivateNoteData } from '../../../types/types';
import MeetingTabAddPrivateNotes from '../meeting-tab-add-private-notes-molecule';
import MeetingTabSelectPrivateNote from '../meeting-tab-select-private-note-molecule';

interface Props {
  privateNotes: PrivateNoteData[],
  setPrivateNotes: Dispatch<SetStateAction<PrivateNoteData[]>>,
  meetingTab: MeetingSection,
  setMeetingTab: Dispatch<SetStateAction<MeetingSection>>,
  onClickOpenPrivateNotesModal: () => void,
  // eslint-disable-next-line no-unused-vars
  handleSecretNotesSelected: (chatId: string) => void,
  setSelectedChat: Dispatch<SetStateAction<number>>,
  selectedChat: number,
  setIsDeleted: Dispatch<SetStateAction<boolean>>,
  setChatToDelete: Dispatch<SetStateAction<PrivateNoteData>>,
}

const PrivateNotesTab = ({
  privateNotes,
  setPrivateNotes,
  meetingTab,
  setMeetingTab,
  selectedChat,
  setSelectedChat,
  onClickOpenPrivateNotesModal,
  handleSecretNotesSelected,
  setChatToDelete,
  setIsDeleted,
}: Props) => {
  const [doesPrivateNotesExist, setDoesPrivateNotesExist] = useState(false);

  useEffect(() => {
    setDoesPrivateNotesExist(privateNotes.length !== 0);
  }, [privateNotes]);

  if (doesPrivateNotesExist) {
    return (
      <MeetingTabSelectPrivateNote
        doesPrivateNotesExist={doesPrivateNotesExist}
        privateNotes={privateNotes}
        setPrivateNotes={setPrivateNotes}
        handleSecretNotesSelected={handleSecretNotesSelected}
        meetingTab={meetingTab}
        setMeetingTab={setMeetingTab}
        onClickOpenPrivateNotesModal={onClickOpenPrivateNotesModal}
        selectedChat={selectedChat}
        setSelectedChat={setSelectedChat}
        setIsDeleted={setIsDeleted}
        setChatToDelete={setChatToDelete}
      />
    );
  }

  return (
    <MeetingTabAddPrivateNotes
      meetingTab={meetingTab}
      onClickOpenPrivateNotesModal={onClickOpenPrivateNotesModal}
    />
  );
};

export default PrivateNotesTab;
