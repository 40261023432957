import moment from 'moment';
import { dateObject } from '../../utils/dateUtils/date';
import {
  DatabaseMeetingData,
  GapiMeetingData,
  MeetingData,
  SimpleUserData,
  ConferenceData,
  DatabaseGapiMeetingData,
} from '../../shared/types/types';
import { templateSimpleUserData, templateUserPermissions } from '../../utils/user/UserDataUtils';

export const emptyMeetingList: MeetingData[] = [];

export const pendingMeetingData: MeetingData = {
  resolvedState: 'pending',
  attendees: {
    attendees: [],
    resolvedState: 'pending',
  },
  userRole: templateUserPermissions,
  meetingId: '',
  version: 4,
  googleData: {
    ids: {
      eventId: '',
      recurringEventId: '',
      dataEventId: '',
      calendarId: '',
      meetId: '',
    },
    conferenceData: {
      type: 'undefined',
      link: '',
    },
    content: {
      summary: '',
    },
  },
  permissions: {
    users: [templateSimpleUserData('')],
    userGroups: [],
    linkPermissions: 'private',
  },
  data: {
    agenda: [],
    attachments: [],
    attendees: [],
    description: '',
    title: '',
    postMeetingTasks: [],
    preMeetingTasks: [],
  },
  tags: {
    tags: [],
    meetingSeries: {
      name: '',
      id: '',
    },
  },
  date: {
    created: dateObject(),
    start: dateObject(),
    end: dateObject(),
    lastUpdated: dateObject(),
  },
};

export const emptyDatabaseGapiMeetingData: DatabaseGapiMeetingData = {
  updated: '',
  summary: '',
  description: '',
  status: '',
  start: {
    dateTime: '',
  },
  sequence: NaN,
  reminders: {
    useDefault: false,
  },
  organizer: {
    email: '',
    self: false,
  },
  kind: '',
  id: '',
  iCalUID: '',
  eventType: '',
  recurringEventId: '',
  recurrence: [],
  etag: '',
  end: {
    dateTime: '',
  },
  creator: {
    email: '',
    self: false,
  },
  created: '',
  conferenceData: {
    conferenceId: '',
    conferenceSolution: {
      iconUri: '',
      key: {
        type: '',
      },
      name: '',
    },
    entryPoints: [],
    signature: '',
  },
  attendees: [],
};

export const rejectedMeetingData: MeetingData = { ...pendingMeetingData, resolvedState: 'rejected' };

export const mapGoogleMeetingToDatabaseMeetingData = (
  googleMeeting: GapiMeetingData,
  calendarId: string,
  user: SimpleUserData,
): DatabaseMeetingData => {
  const newMeetingData: DatabaseMeetingData = {
    version: 4,
    googleData: {
      ids: {
        eventId: googleMeeting?.id ?? '',
        recurringEventId: googleMeeting?.recurringEventId ?? '',
        dataEventId: '',
        calendarId: calendarId ?? '',
        meetId: googleMeeting?.conferenceData?.conferenceId ?? '',
      },
      content: {
        summary: googleMeeting?.summary ?? '',
      },
      conferenceData: mapGoogleMeetingToConferenceData(googleMeeting),
    },
    permissions: {
      users:
      {
        [user.userId]: user,
      },
      userGroups: [],
      linkPermissions: 'public_edit',
    },
    data: {
      agenda: [],
      attachments: [],
      attendees: googleMeeting?.attendees ?? [],
      description: googleMeeting?.description ?? '',
      title: googleMeeting?.summary ?? '',
      postMeetingTasks: [],
      preMeetingTasks: [],
    },
    tags: {
      tags: [],
      meetingSeries: {
        name: '',
        id: '',
      },
    },
    date: {
      created: dateObject(),
      start: getDateObjectFromGoogleDate(googleMeeting?.start?.dateTime),
      end: getDateObjectFromGoogleDate(googleMeeting?.end?.dateTime),
      lastUpdated: dateObject(),
    },
  };
  return newMeetingData;
};

export const getDateObjectFromGoogleDate = (googleDate: string) => ({
  date: googleDate,
  timestamp: Number(moment(googleDate).format('x')), // Ts with millisecond
});

export const mapGoogleMeetingToConferenceData = (
  gapiMeetingData: GapiMeetingData,
): ConferenceData => {
  if (gapiMeetingData.conferenceData.conferenceSolution.key.type === 'hangoutsMeet') {
    return {
      type: 'googleMeet',
      link: createGoogleMeetLinkFromGapiMeeting(gapiMeetingData),
    } as ConferenceData;
  }
  if (isZoomConferenceInGapiMeeting(gapiMeetingData)) {
    return {
      type: 'zoom',
      link: createZoomLinkFromGapiMeeting(gapiMeetingData),
    } as ConferenceData;
  }
  return {
    type: 'undefined',
    link: '',
  } as ConferenceData;
};

const createGoogleMeetLinkFromGapiMeeting = (gapiMeetingData: GapiMeetingData) => {
  const googleMeetId = gapiMeetingData.conferenceData.conferenceId ?? '';
  if (googleMeetId.length === 0) return '';

  return `https://meet.google.com/${googleMeetId}?authuser=0`;
};

const isZoomConferenceInGapiMeeting = (gapiMeetingData: GapiMeetingData) => {
  const isZoomLinkInDescription = gapiMeetingData.description?.includes('zoom.us/j/');
  if (isZoomLinkInDescription) return true;
  return false;
};

const createZoomLinkFromGapiMeeting = (gapiMeetingData: GapiMeetingData) => {
  const startIndex = gapiMeetingData.description?.indexOf('https://zoom.us/j/') ?? 0;
  if (startIndex === 0) return '';

  const endIndex = gapiMeetingData.description?.indexOf('"', startIndex + 1) ?? 0;
  const zoomLink = gapiMeetingData.description?.substring(startIndex, endIndex) ?? '';
  return zoomLink;
};
