import { captureException } from '@sentry/browser';
import {
  NotionPageData, PrivateNoteHtml, SlackChannel, TaskItem, TrelloData,
} from '../../shared/types/types';
import { logEventButtonClickSendEmailSubmit } from '../analytics/eventLogger';
import { addCallForAction } from '../email/emailUtils';
import { cfSendMeetingNotes } from '../firebase';
import { createAndSendNotionPage } from '../notion/notionAPIs';
import { assembleNotionBlocks } from '../notion/notionUtils';
import { slackCoreSendMessages } from '../slack/SlackCoreAPI';
import { assembleSlackMessage } from '../slack/slackUtils';
import { sendTaskToTrelloBoard } from '../trello/trelloAPIs';

export type ShareNotesDataType = {
  sendHeadlessNotesTemplateId: string
  emails: { email: string }[]
  html: string;
  title: string;
  startTime: string;
  startDate: string;
  agendaChecked: boolean;
  sharedNotesChecked: boolean;
  personalNotesChecked: boolean;
  taskChecked: boolean;
  emailHeadHtml: string;
  emailHeadTemplate: string;
  agendaHtml: string;
  sharedNotesHtml: string;
  personalNotesHtml: string;
  taskItems: TaskItem[];
  checkedPrivateNotesHtml: PrivateNoteHtml[],
  privateSpaceNotesChecked: string[],
}

export const generateSlackMessage = (
  shareNotesData: ShareNotesDataType,
  channelsToReceive: SlackChannel[],
  slackAccessToken: string,
  promiseArr: Promise<any>[],
) => {
  const slackMessage = assembleSlackMessage(shareNotesData);
  const messageWithCallForAction = addCallForAction(slackMessage);
  promiseArr.push(slackCoreSendMessages(
    channelsToReceive,
    slackAccessToken,
    messageWithCallForAction,
  ));
};

export const generateNotionPage = (
  shareNotesData: ShareNotesDataType,
  notionPagesToReceive: NotionPageData[],
  promiseArr: Promise<any>[],
) => {
  const notionPage = assembleNotionBlocks(shareNotesData, notionPagesToReceive);
  promiseArr.push(createAndSendNotionPage(notionPage));
};

export const sendEmails = ({
  emails, title, html, sendHeadlessNotesTemplateId,
  // eslint-disable-next-line no-unused-vars
}: ShareNotesDataType, trackEvent: (event: string, metaData?: object | undefined) => void,
promiseArr: Promise<any>[]) => {
  logEventButtonClickSendEmailSubmit(trackEvent, emails.length);
  promiseArr.push(cfSendMeetingNotes(
    emails, title, html, sendHeadlessNotesTemplateId,
  ));
};

export const sendToTrello = (
  trelloChecked: boolean,
  taskToSend: TaskItem[],
  trelloUserData: TrelloData,
  promiseArr: Promise<any>[],
) => {
  try {
    const promise = sendTaskToTrelloBoard(trelloChecked, taskToSend, trelloUserData);
    promiseArr.push(promise);
  } catch (error) {
    captureException(error);
    console.log('Error sending task to trello board');
    console.log(error);
  }
};

export const getBooleanArray = (secretChatsLength: number) => {
  const booleanArray: boolean[] = [];
  for (let index = 0; index < secretChatsLength; index += 1) {
    booleanArray.push(false);
  }
  return booleanArray;
};
