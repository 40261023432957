import React from 'react';
import styled from 'styled-components';
import { gray1, red7 } from '../../colours';
import { uiTextMedium } from '../../typography';

import TaskOperationDropdownItem from './TaskOperationDropdownItem';

const Container = styled.div`
  position: absolute;
  padding: 8px;
  background-color: ${gray1};
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  // the translate x value should be the width of this element 
  // subtract the width of dropdown button
  // TODO calculate this value programmatically
  transform: translate(-143px, 6px);
  z-index: 25;
`;

const Text = styled.span`
  ${uiTextMedium}
  width: 138px;
`;

const RedText = styled.span`
  color: ${red7};
`;

interface Props {
  onDeleteClick: () => void,
  onDuplicateClick: () => void,
  onGoToMeetingClick: () => void,
  onTaskDetailsClick: () => void,
  disableGoToMeeting: boolean,
}

const TaskOperationDropdownOptions = ({
  onTaskDetailsClick, onDeleteClick, onDuplicateClick, onGoToMeetingClick, disableGoToMeeting,
}: Props) => (
  <Container>
    <TaskOperationDropdownItem onClick={onTaskDetailsClick}>
      <Text>
        View details
      </Text>
    </TaskOperationDropdownItem>
    <TaskOperationDropdownItem onClick={onDuplicateClick}>
      <Text>
        Duplicate
      </Text>
    </TaskOperationDropdownItem>
    {!disableGoToMeeting && (
      <TaskOperationDropdownItem onClick={onGoToMeetingClick}>
        <Text>
          Go to meeting
        </Text>
      </TaskOperationDropdownItem>
    )}
    <TaskOperationDropdownItem onClick={onDeleteClick}>
      <Text>
        <RedText>
          Delete
        </RedText>
      </Text>
    </TaskOperationDropdownItem>
  </Container>
);

export default TaskOperationDropdownOptions;
