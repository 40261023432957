import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { darkBlue1 } from '../../../../../../shared/colours';
import { TabDropdownItem } from '../../../../../../shared/components/simple-dropdown';
import { ResolvedStateItem, TrelloData } from '../../../../../../shared/types/types';

import { header300 } from '../../../../../../shared/typography';
import { getTrelloBoards, getTrelloWorkspace } from '../../../../../../utils/trello/trelloAPIs';
import { updateBoard, updateWorkspace } from '../../../../../../utils/trello/trelloSettingsUpdateUtil';
import { getTrelloBoardsAPIResponse } from '../../../../../../utils/trello/trelloUtils';
import SettingTab from '../SettingTab';

interface Props {
  setTrelloData: Dispatch<SetStateAction<TrelloData>>,
  trelloData: TrelloData
}

const OptionLabel = styled.p`
  ${header300}
  padding: 0px;
  margin: 0px 0px 8px 6px;
  text-align: center;
`;

const Line = styled.div`
  background:  ${darkBlue1};
  height: 2px;
  width: 100%;
  margin: 0px 0px 24px 0px;
`;

const WorkspaceAndBoardSetting = ({ setTrelloData, trelloData }:Props) => {
  const [accessToken, setAccessToken] = useState('');

  const [workspaceLoading, setWorkspaceLoading] = useState(false);
  const [boardsLoading, setBoardsLoading] = useState(false);

  const [workspacePlaceholder, setWorkspacePlaceholder] = useState('');
  const [boardPlaceholder, setBoardPlaceholder] = useState('');

  const [workspaceOptions, setWorkspaceOption] = useState<TabDropdownItem[]>([]);
  const [boardsOptions, setBoardsOption] = useState<TabDropdownItem[]>([]);
  const [boardsResponse, setBoardsResponse] = useState<getTrelloBoardsAPIResponse[]>([]);

  useEffect(() => {
    setAccessToken(trelloData.accessToken);
    setWorkspacePlaceholder(trelloData.workspaceName || 'Select a Workspace');
    setBoardPlaceholder(trelloData.board.boardName || 'Select a Board');
  }, [trelloData]);

  const onWorkspaceChange = (selectedOption: TabDropdownItem) => {
    setWorkspacePlaceholder(selectedOption.value);
    if (selectedOption.value !== workspacePlaceholder) {
      updateWorkspace(selectedOption, setTrelloData);
      setBoardsResponse([]);
      setBoardsOption([]);
    }
  };

  const onBoardChange = (selectedOption: TabDropdownItem) => {
    setBoardPlaceholder(selectedOption.value);
    if (selectedOption.value !== boardPlaceholder) {
      updateBoard(selectedOption, boardsResponse, setTrelloData);
    }
  };

  // TODO Asish: Double check I merged well
  const onWorkspaceDropdownClick = () => {
    if (accessToken.length > 10 && workspaceOptions.length === 0) {
      setWorkspaceLoading(true);
      getTrelloWorkspace(accessToken)
        .then((response: ResolvedStateItem<TabDropdownItem[]>) => {
          setWorkspaceOption(response.item);
        }).finally(() => {
          setWorkspaceLoading(false);
        });
    }
  };

  const onBoardDropdownClick = () => {
    if (accessToken.length > 10 && boardsResponse.length === 0) {
      setBoardsLoading(true);
      getTrelloBoards(accessToken, trelloData.workspaceId)
        .then((response: ResolvedStateItem<getTrelloBoardsAPIResponse[]>) => {
          setBoardsResponse(response.item);
          const options: TabDropdownItem[] = response.item
            .map((board: getTrelloBoardsAPIResponse) => ({
              id: board.id,
              value: board.name,
            }));
          setBoardsOption(options);
        }).catch((error:any) => {
          console.log('Trello CF Failed', error);
        })
        .finally(() => {
          setBoardsLoading(false);
        });
    }
  };

  return (
    <>
      <SettingTab
        label="Choose a Trello Workspace"
        onClick={onWorkspaceDropdownClick}
        onChange={onWorkspaceChange}
        loading={workspaceLoading}
        options={workspaceOptions}
        placeholder={workspacePlaceholder}
        name="trello-workspace-list"
        emptyOptionsText="No Workspace found, try select a Board below"
      />
      <OptionLabel>OR</OptionLabel>
      <SettingTab
        label="Choose a Trello Board"
        onClick={onBoardDropdownClick}
        onChange={onBoardChange}
        loading={boardsLoading}
        options={boardsOptions}
        placeholder={boardPlaceholder}
        name="trello-board-list"
      />
      <Line />
    </>
  );
};

export default WorkspaceAndBoardSetting;
