/* eslint-disable no-unused-vars */
import React, {
  useEffect, useState, useContext, SetStateAction, Dispatch,
} from 'react';
import styled from 'styled-components';
import HistoryIcon from '../../../icons/historyIcon';
import { uiTextMedium } from '../../../typography';
import { blue6 } from '../../../colours';
import MeetingDataContext from '../../../../pages/meeting/context/MeetingDataContext';
import { MeetingData } from '../../../types/types';
import { dbGetPreviousMeetingsByRecurringEventId } from '../../../../database/firebaseMeetingAPI';
import sendPreviousMeetingWindowMessage from '../../../../utils/previousMeetingInjection';
import PreviousMeetingIcon from '../../../icons/PreviousMeetingIcon';
import ButtonMolecule from '../../../../pages/all-pages/bottom-bar/ButtonMolecule';
import ReactTooltip from '../../tooltip/ReactTooltip';
import { AuthContext } from '../../../../App';

const ButtonContainer = styled.button`
  all: unset;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 150px;
  height: 24px;

  padding: 3px 0px 3px 3px;
  margin-right: auto;

  &:hover {
    cursor: pointer;
    filter: brightness(75%);
  }
`;

const ButtonTextContainer = styled.span`
  ${uiTextMedium};
  color: ${blue6};
`;

interface Props {
  meetingData: MeetingData,
  setIsRecurringMeeting: Dispatch<SetStateAction<boolean>>
}

const PreviousMeetingButtonMol = ({ meetingData, setIsRecurringMeeting }:Props) => {
  const authState = useContext(AuthContext);
  const [isPreviousMeetingExists, setIsPreviousMeetingExists] = useState<boolean>(false);
  const [previousMeetingId, setPreviousMeetingId] = useState<string>('');

  const buttonText = 'Previous Meeting';

  useEffect(() => {
    const { recurringEventId } = meetingData.googleData.ids;
    const currentStartTime = meetingData.date.start.timestamp;
    if (recurringEventId !== '' && currentStartTime) {
      console.log('1');
      dbGetPreviousMeetingsByRecurringEventId(recurringEventId, currentStartTime, authState.email)
        .then((previousMeetings:MeetingData[]) => {
          if (previousMeetings.length === 0) return;

          setIsPreviousMeetingExists(true);
          setIsRecurringMeeting(true);

          setPreviousMeetingId(previousMeetings[0].meetingId);
        });
    }
  }, [meetingData.meetingId]);

  const handleClick = () => {
    if (isPreviousMeetingExists) {
      sendPreviousMeetingWindowMessage({
        meetingId: meetingData.meetingId,
        previousMeetingId,
      });
    }
  };

  if (isPreviousMeetingExists) {
    return (
      <ButtonMolecule
        onClick={handleClick}
        text="Previous"
        icon={<PreviousMeetingIcon />}
        tooltipText="View previous meeting in recurring series"
      />
    );
  }

  return null;
};

export default PreviousMeetingButtonMol;
