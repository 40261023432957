import React from 'react';
import styled from 'styled-components';
import { gray1 } from '../../../shared/colours';
import { header500 } from '../../../shared/typography';

const Text = styled.div`
  ${header500};
  color: ${gray1};
`;

interface Props {
  text: string,
}

const OptionAtom = ({ text }: Props) => (
  <Text>
    {text}
  </Text>
);

export default OptionAtom;
