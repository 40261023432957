import React from 'react';
import {
  addColumnBefore, addColumnAfter, deleteColumn, addRowBefore, addRowAfter,
  deleteRow, deleteTable, mergeCells, splitCell, setCellAttr,
} from 'prosemirror-tables';
import { insertTable } from '../helpers/table-utils';
import TableIcon from '../../../components/icons/table';
import TableColorIcon from '../../../components/icons/table-color';
import AddColumnBeforeIcon from '../../../components/icons/add-column-before';
import AddColumnAfterIcon from '../../../components/icons/add-column-after';
import DeleteColumnIcon from '../../../components/icons/delete-column';
import AddRowBeforeIcon from '../../../components/icons/add-row-before';
import AddRowAfterIcon from '../../../components/icons/add-row-after';
import DeleteRowIcon from '../../../components/icons/delete-row';
import DeleteTableIcon from '../../../components/icons/delete-table';
import MergeCellsIcon from '../../../components/icons/merge-cells';
import SplitCellsIcon from '../../../components/icons/split-cells';

export default [
  {
    label: 'Insert Table',
    callback: insertTable,
    icon: <TableIcon />,
  },
  {
    colorPicker: true,
    label: 'Add color',
    callback: (color: string | null, state: any, dispatch: any) => setCellAttr('background', color)(state, dispatch),
    icon: <TableColorIcon />,
  },
  {
    label: 'Add Column Before',
    callback: addColumnBefore,
    icon: <AddColumnBeforeIcon />,
  },
  {
    label: 'Add Column After',
    callback: addColumnAfter,
    icon: <AddColumnAfterIcon />,
  },
  {
    label: 'Delete Column',
    callback: deleteColumn,
    icon: <DeleteColumnIcon />,
  },
  {
    label: 'Add Row Before',
    callback: addRowBefore,
    icon: <AddRowBeforeIcon />,
  },
  {
    label: 'Add Row After',
    callback: addRowAfter,
    icon: <AddRowAfterIcon />,
  },
  {
    label: 'Delete Row',
    callback: deleteRow,
    icon: <DeleteRowIcon />,
  },
  {
    label: 'Delete Table',
    callback: deleteTable,
    icon: <DeleteTableIcon />,
  },
  {
    label: 'Merge Cells ',
    callback: mergeCells,
    icon: <MergeCellsIcon />,
  },
  {
    label: 'Split Cell',
    callback: splitCell,
    icon: <SplitCellsIcon />,
  },
] as Array<Record<string, any>>;
