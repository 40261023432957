/* eslint-disable arrow-body-style */
import React, {
  ReactNode, useContext, useEffect, useState,
} from 'react';
import styled, { StyledComponent, css } from 'styled-components';
import { useDetectClickOutside } from 'react-detect-click-outside';
import {
  darkBlue1, darkBlue6, blue1, blue6,
} from '../../../colours';
import ChevronIcon from './icons/chevron';
import { EditorContext } from '../index';
import ReactTooltip from '../../tooltip/ReactTooltip';

interface MenuItemContainerProps {
  selected: boolean;
}

const MenuItemContainer = styled.div<MenuItemContainerProps>`
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
  padding: 2px;
  border-radius: 6px;
  ${({ selected }) => selected && css`
    /* Toggled On */
    background-color: ${blue1};
    svg path {
      fill: ${blue6};
    }
  `};
  ${({ selected }) => !selected && css`
    /* Toggled Off */
    &:hover {
      background: ${darkBlue1};
      svg path {
        fill: ${darkBlue6};
      }
    }
  `};
`;

export const MainIconContainer = styled.div`
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ChevronContainer = styled.div`
  height: 18px;
  width: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ListContentsWrapperProps {
  listAlign: 'left' | 'right' | 'horizontalLeft';
}

const getAlignTransformation = (alignProp: string) => {
  switch (alignProp) {
    case 'right':
      return 'translate(calc(-50% + 12px), calc(50% + 20px))';
    case 'horizontalLeft':
      return 'translate(calc(-100% - 22px), calc(50% - 20px))';
    default:
      return 'translate(calc(50% - 18px), calc(50% + 20px))';
  }
};

const ListContentsWrapper = styled.div<ListContentsWrapperProps>`
  position: absolute;
  z-index: 19;
  transform: ${({ listAlign }) => getAlignTransformation(listAlign)};
  max-width: 100%;
  width: fit-content;
`;

/* eslint-disable no-unused-vars */
interface CustomMenuItemProps {
  /**
   * This property marks wether the menu item is of list
   * type or not. Marking this is as true will apply some
   * custom styling and enable extra behavior.
   */
  list?: boolean;
  /**
   * If the list property is marked as true, this will
   * be the component that will be rendered as the list.
   * It is provided as a function, so the sub-menu context
   * can be passed to the parent as well.
   *
   * (An example use case for this is checking wether the menu
   * is open or not - i.e. accessing it's inner state)
   */
  listContents?: (...args: any) => ReactNode;
  /**
   * The contents of the menu item button. Usually
   * contains an icon, but could be different depending
   * on where it is used.
   */
  contents?: (...args: any) => ReactNode;
  /**
   * Used for custom styling when the menu item is selected.
   */
  selected?: boolean;
  /**
   * Callback which on call determines wether the current menu item is active.
   * This is usually used with marks, so if the text under the cursor is bold,
   * the menu item will be styled as such.
   */
  activeCallback?: () => boolean;
  /**
   * Function ran when the menu item *content* is pressed.
   * Usually used by marks, to toggle them by pressing the icon.
   *
   * List show/hide is enabled by another mechanism, determined by
   * the *list* prop.
   */
  action?: CallableFunction;
  /**
   * Provides custom placement. The alignment is not automatic, so for
   * alignment issues this is the "manual" control.
   *
   * @option *left* - the left side of the list is aligned to the button.
   * @option *right* - the right side of the list is aligned to the button.
   * @option *horizontalLeft* - the top side of the list is aligned to the button.
   * It is also shown to the leftmost position,
   */
  listAlign?: 'left' | 'right' | 'horizontalLeft';
  /**
   * Situational prop, used for custom menu item toggle buttons.
   */
  Container?: StyledComponent<any, any, any, any>;
  /**
   * If the item is of list type, and you wish to hide the dropdown chevron,
   * mark this as true.
   */
  hideChevron?: boolean;
  /**
   * Toggles event capturing behavior. This will prevent default and stop
   * propagation if marked as true. Used mainly for clicks.
   */
  captureEvents?: boolean;
  /**
   * This property represents a function that is called
   * upon menu initialization, where the menuItem is available.
   *
   * It is useful for binding key combinations to menu items.
   */
  init?: (
    showState: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
    activeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
  ) => void;
  /**
   * If marked as true, this will be closed when clicking away.
   */
  closeOnClickAway?: boolean;
  /**
   * If closeOnClickAway is true, this will be executed when closed by a click away event
   */
  onCloseClickAway?: () => void;
  /**
   * This is called when the menu is opened.
   */
  onOpen?: CallableFunction;
  /**
   * This is called when the menu is closed.
   */
  onClose?: CallableFunction;
  /**
   * Tooltip Text shown above the icon
   * If not provided, there will not be any tooltip
   */
  tooltipText?: string;
}
/* eslint-enable no-unused-vars */

const CustomMenuItem = ({
  list,
  listContents,
  selected,
  activeCallback,
  action,
  listAlign,
  hideChevron,
  captureEvents,
  init,
  closeOnClickAway,
  onCloseClickAway,
  Container,
  contents,
  onOpen,
  onClose,
  tooltipText = '',
}: CustomMenuItemProps) => {
  const [isActive, setIsActive] = useState(false);

  const [show, setShow] = useState(false); // TODO: Rename show to isDropdownOpen?

  const ctx = useContext(EditorContext);

  useEffect(() => {
    if (!ctx?.view) return;
    setIsActive(activeCallback!());
  }, [ctx?.update]);

  useEffect(() => {
    init!([show, setShow], [isActive, setIsActive]);
  }, []);

  useEffect(() => {
    if (show) {
      onOpen!();
    } else onClose!();
  }, [show]);

  const closeContents = () => {
    if (!closeOnClickAway) return;

    onCloseClickAway!();
    setShow(false);
    setIsActive(false);
  };

  const tooltipDisabled = tooltipText.length === 0 || show;
  const ref = useDetectClickOutside({ onTriggered: () => { closeContents(); } });

  const UsableContainer = Container ?? MenuItemContainer;
  return (
    <ReactTooltip tip={tooltipText} place="top" textEditor disabled={tooltipDisabled}>
      <UsableContainer
        ref={ref}
        selected={isActive || (selected ?? false)}
        onMouseDown={(e: React.MouseEvent<HTMLUnknownElement>) => {
          if (!captureEvents!) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        {Container === null
          ? (
            <MainIconContainer
              onMouseDown={(e) => {
                e.preventDefault(); e.stopPropagation();
              action!();
              if (list) {
                setShow(!show);
              }
              }}
            >
              {contents!({
                show,
              })}
            </MainIconContainer>
          )
          : (
            <span
              role="none"
              onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
                e.preventDefault(); e.stopPropagation();
              action!();
              if (list) {
                setShow(!show);
              }
              }}
            >
              {contents!({
                show,
              })}
            </span>
          )}
        {list && !hideChevron
          ? (
            <ChevronContainer
              onMouseDown={(e) => {
                e.preventDefault(); e.stopPropagation();
                if (list) {
                  setShow(!show);
                }
              }}
            >
              <ChevronIcon />
            </ChevronContainer>
          )
          : ''}
        {listContents!() !== null && show
          ? (
            <ListContentsWrapper
              listAlign={listAlign!}
            >
              {listContents!({
                ctx,
                show,
                setShow,
                isActive,
                setIsActive,
                closeContents,
              })}
            </ListContentsWrapper>
        )
          : ''}
      </UsableContainer>
    </ReactTooltip>
  );
};

CustomMenuItem.defaultProps = {
  list: false,
  listAlign: 'left',
  listContents: () => null,
  selected: false,
  activeCallback: () => false,
  action: () => null,
  hideChevron: false,
  captureEvents: true,
  init: () => null,
  closeOnClickAway: false,
  Container: null,
  contents: () => null,
  onCloseClickAway: () => { },
  onOpen: () => null,
  onClose: () => null,
  tooltipText: '',
};

export default CustomMenuItem;
