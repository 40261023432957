import React, {
  Dispatch, SetStateAction, useRef, useState,
} from 'react';
import styled from 'styled-components';
import { surface } from '../../../shared/colours';
import { UserCenterPage } from '../../../shared/types/types';
import BlueLineAtom from './BlueLineAtom';
import PageTabButtonAtom from './PageTabButtonAtom';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${surface};
`;

const ButtonsContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  width: 100%;
`;

interface Props {
  section: UserCenterPage,
  setSection: Dispatch<SetStateAction<UserCenterPage>>
}

const Navbar = ({ section, setSection }: Props) => {
  const tabButtonsContainerRef = useRef<HTMLDivElement>(null);
  const [isHover, setIsHover] = useState<boolean>(false);
  const handleAllTasksClicked = () => {
    setSection('tasks');
  };

  const handleCurrentMeetingClicked = () => {
    setSection('currentMeeting');
  };

  // eslint-disable-next-line no-unused-vars
  const handleAllNotesClicked = () => {
    setSection('notes');
  };

  return (
    <Container>
      <ButtonsContainer ref={tabButtonsContainerRef}>
        {/* <PageTabButtonAtom
          text="All notes"
          onClick={handleAllNotesClicked}
          isSelected={section === 'notes'}
          tooltipText="Dashboard of all your notes"
          setIsHover={setIsHover}
        /> */}
        <PageTabButtonAtom
          text="All tasks"
          onClick={handleAllTasksClicked}
          isSelected={section === 'tasks'}
          tooltipText="Dashboard of all your tasks"
          setIsHover={setIsHover}
        />
        <PageTabButtonAtom
          text="Current meeting"
          onClick={handleCurrentMeetingClicked}
          isSelected={section === 'currentMeeting'}
          tooltipText="Current meeting notes and tasks"
          setIsHover={setIsHover}
        />
        <BlueLineAtom
          section={section}
          buttonsParentRef={tabButtonsContainerRef}
          isHover={isHover}
        />
      </ButtonsContainer>
    </Container>
  );
};

export default Navbar;
