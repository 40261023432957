import React from 'react';
import * as Sentry from '@sentry/react';
import {
  AuthState, IntercomTrackEvent, LoginOrSignup,
} from '../../shared/types/types';
import {
  API_KEY, CLIENT_ID, DISCOVERY_DOCS, SCOPES,
} from '../constants';
import { LOGGED_IN_STATE, LOGGED_OUT_STATE } from '../enums';
import { analytics, auth } from '../firebase';
import { LOGIN_EVENT, SIGNUP_EVENT } from '../analytics/enums';
import { setMixpanelUserAlias } from '../mixpanel';
import { trackLoginAnalytics, trackSignUpAnalytics } from './GoogleCalendarUtils';

declare let gapi: any;

type SetAuthState = React.Dispatch<React.SetStateAction<AuthState>>;

const initializeGoogleAPI = (setAuthState: SetAuthState) => {
  gapi.load('client:auth2', () => {
    initClient(setAuthState);
  });
};

function initClient(setLoggedIn: SetAuthState) {
  gapi.client.init({
    apiKey: API_KEY,
    clientId: CLIENT_ID,
    discoveryDocs: DISCOVERY_DOCS,
    scope: SCOPES,
  }).then(
    () => listenForFirebaseState(setLoggedIn),
    (error: any) => {
      console.log('Something went wrong while initializing the Google API');
      console.log(error);
      Sentry.captureException(error);
    },
  );
}

const listenForFirebaseState = (setLoggedIn: SetAuthState) => (
  auth().onAuthStateChanged((user) => {
    if (user) {
      const {
        uid, displayName, email, photoURL,
      } = user;
      const index = displayName?.indexOf(' ') || 0;

      const firstName = displayName?.substr(0, index) || '';
      const lastName = displayName?.substr(index + 1) || '';

      setLoggedIn({
        userId: uid,
        userState: LOGGED_IN_STATE,
        firstName,
        lastName,
        email: email || '',
        photoUrl: photoURL || '',
      });
    } else {
      setLoggedIn({
        userId: '',
        userState: LOGGED_OUT_STATE,
        firstName: '',
        lastName: '',
        email: '',
        photoUrl: '',
      });
    }
  })
);

const logAnalytics = (
  intercomTrackEvent: IntercomTrackEvent,
  authenticationType: LoginOrSignup,
  signUpEmail: string = '',
) => {
  if (authenticationType === LOGIN_EVENT) trackLoginAnalytics(intercomTrackEvent);

  trackSignUpAnalytics(intercomTrackEvent, signUpEmail);
};

export const signInToGoogleAndFirebase = (
  onScopesNotGranted: any,
  intercomTrackEvent: any,
) => {
  const options = new gapi.auth2.SigninOptionsBuilder();
  options.setPrompt('select_account');

  gapi.auth2.getAuthInstance().signIn(options)
    .then((googleUser: any) => {
      // https://developers.google.com/identity/sign-in/web/reference#googleusergetgrantedscopes
      // console.log(googleUser.getGrantedScopes());
      if (checkGrantedScopes(SCOPES, googleUser.getGrantedScopes())) {
        const token = googleUser.getAuthResponse().id_token;
        const credentials = auth.GoogleAuthProvider.credential(token);
        analytics().logEvent('login', { method: 'google' });
        auth().signInWithCredential(credentials)
          .then(async (user: any) => {
            console.log('Signed in with Firebase. Yeah!');

            const isNewUser = user.additionalUserInfo?.isNewUser ?? false;
            const userId = user.user?.uid ?? '';
            const email = user.user?.email ?? '';

            if (isNewUser) {
              setMixpanelUserAlias(userId);
              logAnalytics(intercomTrackEvent, SIGNUP_EVENT, email);
              analytics().logEvent('signup', { method: 'firebase' });
              return;
            }

            logAnalytics(intercomTrackEvent, LOGIN_EVENT);
            analytics().logEvent('login', { method: 'firebase' });
          })
          .catch((error: Error) => {
            console.log('Something went wrong when signing in to Shepherd (firebase)');
            console.log(error);
            Sentry.captureException(error);
            signOutOfGoogleAndFirebase();
          });
      } else {
        onScopesNotGranted();
        // TODO
        // Add analytics after that the branch is merged
        // logEventGoogleAuthNotAllChecked()
      }
    });
};

const checkGrantedScopes = (
  demandedScopes: string,
  grantedScopes: string,
): boolean => demandedScopes.split(' ')
  .every((demandedScope) => grantedScopes.split(' ').includes(demandedScope));

export const signOutOfGoogleAndFirebase = () => {
  auth().signOut().then(() => {
    analytics().logEvent('logout', { method: 'firebase' });
  });
  gapi.auth2.getAuthInstance().signOut().then(() => {
    analytics().logEvent('logout', { method: 'google' });
  });
};

export default initializeGoogleAPI;
