import { Dispatch, SetStateAction } from 'react';
import * as Sentry from '@sentry/browser';
import firebase, { firestore, functions, performance } from '../utils/firebase';
import {
  AttendeeV2,
  ConferenceData,
  DatabaseMeetingData,
  GapiMeetingData,
  MeetingData,
  SetMeetingDataType,
  ShepherdMeetingId,
} from '../shared/types/types';
import {
  handleDocDoesNotExist,
  handleOnSnapshotError,
} from './firebaseHandleError';
import mapDatabaseMeetingDataToMeetingData from './utils/mapMeetingData';
import { getClosestMeeting, makeGoogleCalendarUrl, makeMeetingUrl } from '../utils/meetings/meetingsUtils';
import { rejectedMeetingData } from './utils/templateMeetingData';
import log from '../utils/logging';
import { gapiGetMeeting } from '../utils/google/GoogleCalendarAPI';
import { rejectedGapiMeetingData } from './utils/gapiMeetingDataUtils';
import { cfSearchPublicUserDataV2ByEmailsV2 } from '../external/publicUserData/PublicUserDataAPI';
import { CONFERENCE_DATA_PATH } from '../utils/constants';
import { gapiAPIGetMeetingByMeetId } from './gapiCalendarAPI';
import ConsoleImproved from '../shared/classes/ConsoleImproved';

type DocType = firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;

const dbListenToMeetingData = (
  meetingId: string,
  userId: string,
  setMeetingData: SetMeetingDataType,
  setGapiMeetingData: Dispatch<SetStateAction<GapiMeetingData>>,
) => meetingRef(meetingId).onSnapshot(
  async (doc: DocType) => {
    if (!doc.exists) {
      setMeetingData(rejectedMeetingData);
      setGapiMeetingData(rejectedGapiMeetingData);
      return handleDocDoesNotExist(
        'Meeting does not exist',
        { meetingId, userId },
        () => {},
      );
    }

    const meetingData = mapDatabaseMeetingDataToMeetingData(meetingId, doc.data(), userId);
    setMeetingData(meetingData);
    console.log('Got new meeting data from Firebase');
    console.log(meetingData);

    const attendees = await cfGetAttendeesFromGoogleAttendees(meetingData.data.attendees);

    const updatedMeetingData = combineMeetingDataWithAttendees(meetingData, attendees);
    setMeetingData(updatedMeetingData);
    return null;
  }, (error) => {
    Sentry.captureException(error);
    handleOnSnapshotError('Something went wrong while listening to meeting', { meetingId, userId }, () => {});
    setMeetingData(rejectedMeetingData);
    setGapiMeetingData(rejectedGapiMeetingData);
  },
);

export const dbGetMeetingsByGoogleMeetId = async (googleMeetId: string, userId: string) => firestore().collection('meetings')
  .where('googleData.ids.meetId', '==', googleMeetId)
  .get()
  .then((docs) => {
    const meetings = docs.docs.map(
      (doc) => mapDatabaseMeetingDataToMeetingData(doc.id, doc.data(), userId),
    );
    console.log(meetings);
    return meetings;
  })
  .catch((error) => {
    console.error('Something went wrong inside dbGetMeetingsByGoogleMeetId ');
    console.error(error);
  });

export const dbFindAndNavigateToMeeting = async (
  googleMeetId: string, userId: string, history: any, setError: Dispatch<SetStateAction<boolean>>,
) => {
  const trace = performance().trace('dbFindAndNavigateToMeeting');
  trace.start();

  const meetings = await functions()
    .httpsCallable('searchMeetingsByGoogleMeetId')({ googleMeetId })
    .then((newMeetings) => newMeetings.data)
    .catch((error) => {
      console.log('Error searching meeting by google meet id', error);
      Sentry.captureException(error);
      return [] as MeetingData[];
    });
  if (meetings.length === 0) {
    // Try to find meeting using GAPI
    // If they have not already created the meeting in Shepherd, it might still be
    // in their calendar.
    const meeting: GapiMeetingData = await gapiAPIGetMeetingByMeetId(googleMeetId);
    if (meeting.resolvedState === 'resolved') {
      ConsoleImproved.log('Actually found a meeting using GAPI');
      history.push(makeGoogleCalendarUrl(meeting.id, meeting.organizer.email));
      trace.stop();
      return;
    }

    trace.stop();
    log(`Could not find any meetings in the database with meetId: ${googleMeetId}`);
    setError(true);
    return;
  }
  log('Meet meetings', meetings);
  const closesMeeting = getClosestMeeting(meetings);
  history.push(makeMeetingUrl(closesMeeting.meetingId));
  trace.stop();
};

const meetingRef = (meetingId: string) => firestore().collection('meetings').doc(meetingId);

export default dbListenToMeetingData;

/**
 * This is just using searching in the database by the EventId.
 * Since depending on who reads the event, different calendarIds might be used
 */
export const dbGetMeetingByEventAndCalendarId = async (
  eventId: string, calendarId: string,
) => {
  const trace = performance().trace('dbGetMeetingByEventAndCalendarId');
  trace.start();
  const meetings = await functions()
    .httpsCallable('searchMeetingsByEventAndCalendarId')({ eventId, calendarId })
    .then((newMeetings) => newMeetings.data)
    .catch((error) => {
      console.log('Error searching meeting by google meet id', error);
      Sentry.captureException(error);
      return rejectedMeetingData;
    });
  if (meetings.length === 0) return rejectedMeetingData;
  const closestMeeting = getClosestMeeting(meetings);
  trace.stop();
  return closestMeeting;
};

export const dbAddMeeting = (meetingData: DatabaseMeetingData) => firestore()
  .collection('meetings')
  .add(meetingData)
  .then((docRef) => {
    console.log('Added new meeting', meetingData);
    return { resolvedState: 'resolved', meetingId: docRef.id } as ShepherdMeetingId;
  })
  .catch((error) => {
    console.log('Something went wrong while adding meeting');
    console.log(meetingData);
    Sentry.captureException(error);
    return { resolvedState: 'rejected', meetingId: '' } as ShepherdMeetingId;
  });

export const dbUpdateMeetingData = (newValue: any, meetingId: string, DATA_PATH: string) => {
  firestore().collection('meetings').doc(meetingId).update({
    [DATA_PATH]: newValue,
  })
    .then(() => {
      log(`Updated path: ${DATA_PATH} successfully, with value:`);
      console.log(newValue);
    })
    .catch((error) => {
      log(`Unsuccessfully updated path ${DATA_PATH}, for meetingId: ${meetingId}, with value:`);
      console.log(newValue);
      console.log(error);
      Sentry.captureException(error);
    });
};

export const dbGetPreviousMeetingsByRecurringEventId = async (
  recurringEventId: string,
  currentStartTime: number,
  userEmail: string,
) => functions()
  .httpsCallable('searchPreviousMeetingsByRecurringEventId')({ recurringEventId, currentStartTime })
  .then((results) => results.data.slice(0, 10))
  .then(async (previousMeetings:MeetingData[]) => {
    const validatedPreviousMeetings = await Promise.all(previousMeetings.map(async (
      meeting:MeetingData,
    ) => {
      const userIsAttendee = await validateUserIsAttendeeInMeeting(
        meeting.googleData.ids.eventId,
        meeting.googleData.ids.calendarId,
        userEmail,
      );

      if (userIsAttendee) return meeting;

      return null;
    }));

    const filteredPreviousMeetings:MeetingData[] = validatedPreviousMeetings.filter((
      meeting,
    ) => meeting !== null) as MeetingData[] ?? [];

    return filteredPreviousMeetings.reverse().slice(0, 5);
  })
  .catch((error) => {
    console.log('Error searching meeting by recurring event id', error);
    Sentry.captureException(error);
    return [];
  });

export const validateUserIsAttendeeInMeeting = async (
  eventId: string,
  calendarId: string,
  userEmail: string,
) => {
  const meetingGapiData:GapiMeetingData = await gapiGetMeeting(
    eventId,
    calendarId,
    userEmail,
  );

  if (meetingGapiData.resolvedState === 'resolved') return true;

  return false;
};

const cfGetAttendeesFromGoogleAttendees = async (googleAttendees: any[]): Promise<AttendeeV2[]> => {
  const emails = googleAttendees.map((attendee) => (attendee?.email as string) ?? '');
  const publicUsers = await cfSearchPublicUserDataV2ByEmailsV2(emails);
  const attendees = publicUsers.map((publicUser) => ({
    ...publicUser,
    responseStatus: googleAttendees.filter((googleAttendee) => googleAttendee?.email === publicUser.data.email)[0]?.responseStatus ?? 'needsAction',
  } as AttendeeV2));
  return attendees;
};

const combineMeetingDataWithAttendees = (
  meetingData: MeetingData, attendees: AttendeeV2[],
): MeetingData => ({
  ...meetingData,
  attendees: {
    attendees,
    resolvedState: 'resolved',
  },
} as MeetingData);

export const dbUpdateConferenceData = async (meetingId: string, conferenceData: ConferenceData) => {
  dbUpdateMeetingData(conferenceData, meetingId, CONFERENCE_DATA_PATH);
};
