import React, { useContext } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../../../App';
import { dbUpdatePrivateNoteTitleAndMembers } from '../../../../database/firebasePrivateNotesAPI';
import { PrivateNoteData } from '../../../../shared/types/types';
import { getTimeFromNow } from '../../../../utils/dateUtils/date';
import MeetingDataContext from '../../context/MeetingDataContext';
import { returnNotifiedMembers, sortSecretChatDataChronologically } from '../../../../shared/components/private-notes/utils/functions';
import PrivateSpaceInvitedPopupOrganism from './components/PrivateSpaceInvitedPopupOrganism';

const PrivateSpacePopupContainer = styled.div`
  position: absolute;
  left: 56px;
  top:30%;
  z-index: 100;
`;

interface Props {
  privateNotes: PrivateNoteData[],
  isPrivateNoteInviteNotifOpen: boolean,
  /* eslint-disable-next-line */
  onClickSelectPrivateNote: (chatId: string) => void,
  onClickClosePrivateNoteInviteNotif: () => void,
}

const PrivateNoteInviteNotification = ({
  privateNotes,
  isPrivateNoteInviteNotifOpen,
  onClickSelectPrivateNote,
  onClickClosePrivateNoteInviteNotif,
}: Props) => {
  const chronologicallySortedChats = sortSecretChatDataChronologically(privateNotes);
  const latestChat = chronologicallySortedChats[0];

  const authState = useContext(AuthContext);
  const meetingData = useContext(MeetingDataContext);
  const currentUser = latestChat?.members?.find((member:any) => (
    member.userId === authState.userId
  ));

  if (currentUser?.isNotified) return null;
  if (latestChat?.creator?.userId === authState.userId) return null;

  const handleViewChat = () => {
    onClickSelectPrivateNote(latestChat.chatId);
    updateMembers();
    onClickClosePrivateNoteInviteNotif();
  };

  const updateMembers = () => {
    const updatedMembers = returnNotifiedMembers(latestChat, authState.userId);
    dbUpdatePrivateNoteTitleAndMembers(
      latestChat.title,
      meetingData.meetingId,
      latestChat.chatId,
      updatedMembers,
    );
  };

  const handleSetClosed = () => {
    updateMembers();
    onClickClosePrivateNoteInviteNotif();
  };

  const created = latestChat?.created?.date ?? '';

  const createdAgo = created.length === 0 ? '' : getTimeFromNow(created);

  if (!isPrivateNoteInviteNotifOpen) return null;

  if (typeof latestChat === 'undefined') return null;

  return (
    <PrivateSpacePopupContainer>
      <PrivateSpaceInvitedPopupOrganism
        isOpen={isPrivateNoteInviteNotifOpen}
        createdAgo={createdAgo}
        creator={latestChat.creator}
        setClosed={handleSetClosed}
        viewChat={handleViewChat}
      />
    </PrivateSpacePopupContainer>
  );
};

export default PrivateNoteInviteNotification;
