import React, { useState } from 'react';
import styled from 'styled-components';
import { blue6, surface } from '../../../colours';
import ArrowRight2 from '../../../icons/ArrowRight2';
import ExplanationAtom from './ExplanationAtom';
import TitleAtom from './TitleAtom';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0px 12px 0px;
`;

const LeftContainer = styled.div`
  div:first-child {
    margin-bottom: 12px;
  }
`;

const ArrowContainer = styled.div`
  cursor: pointer;
`;

interface Props {
  handleSlackNotifModalOpen: () => void,
}

const SlackNotificationsSection = ({ handleSlackNotifModalOpen }: Props) => {
  const [isHoveredOver, setIsHoveredOver] = useState<boolean>(false);
  const arrowFill = isHoveredOver ? blue6 : surface;

  return (
    <Container
      onMouseOver={() => setIsHoveredOver(true)}
      onMouseLeave={() => setIsHoveredOver(false)}
    >
      <LeftContainer>
        <TitleAtom isHoveredOver={isHoveredOver} title="Slack notifications" />
        <ExplanationAtom explanation="Choose the notifications you would like to receive" />
      </LeftContainer>
      <ArrowContainer onClick={handleSlackNotifModalOpen}>
        <ArrowRight2 fill={arrowFill} />
      </ArrowContainer>
    </Container>
  );
};

export default SlackNotificationsSection;
