import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { AllNotesSection } from '../../../shared/types/types';
import { NOTES_LIST_ALL, NOTES_LIST_MEETING, NOTES_LIST_QUICK } from '../../../utils/enums';
import DateFilterButtonAtom from './DateFilterButtonAtom';
import NotesTabInfoTextAtom from './NotesTabInfoTextAtom';
import SwitchNotesTabMolecule from './SwitchNotesTabMolecule';
import TextFilterBoxAtom from './TextFilterBoxAtom';

const Container = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
`;

const InputWidgetsContainer = styled.div`
  display: flex;
  width: 100%;
`;

const AllNotesText = () => (<>Show all the meeting notes created in Shepherd.</>);

const MeetingNotesText = () => (
  <>Meeting notes are connected to meeting events in your calendar.</>
);

const QuickNotesText = () => (
  <>
    Quick notes are &nbsp;
    <strong><u>not</u></strong>
    &nbsp; connected to meeting events in your calendar.
  </>
);

interface Props {
  section: AllNotesSection;
  setSection: Dispatch<SetStateAction<AllNotesSection>>;
  // TODO more props
  // keywords: string
  // startDate:
  // endDate:
}

const NotesFilterBoardOrganism = ({ section, setSection }: Props) => (
  <Container>
    <SwitchNotesTabMolecule section={section} setSection={setSection} />
    <NotesTabInfoTextAtom>
      {section === NOTES_LIST_ALL && <AllNotesText />}
      {section === NOTES_LIST_MEETING && <MeetingNotesText />}
      {section === NOTES_LIST_QUICK && <QuickNotesText />}
    </NotesTabInfoTextAtom>
    <InputWidgetsContainer>
      <TextFilterBoxAtom />
      <DateFilterButtonAtom />
    </InputWidgetsContainer>
  </Container>
);

export default NotesFilterBoardOrganism;
