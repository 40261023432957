import React from 'react';
import styled from 'styled-components';
import NotesListCheckboxAtom from './NotesListCheckboxAtom';
import NotesListCheckboxLabelAtom from './NotesListCheckboxLabelAtom';

const Container = styled.div`
  display: flex;
  padding: 4px 16px;
  margin-top: 8px;
  margin-bottom: 12px;
  
  & > div:not(:last-child) {
    margin-right: 16px;
  }
`;

const SelectAllMolecule = () => (
  <Container>
    <NotesListCheckboxAtom />
    <NotesListCheckboxLabelAtom text="Select all" />
  </Container>
);

export default SelectAllMolecule;
