import React, {
  useRef, Dispatch, SetStateAction,
} from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import SinglePlusIcon from '../../icons/SinglePlusIcon';
import TemplatesDropDown from '../templates-dropdown';
import useDetectOutsideClick from '../../../utils/hook/detectOutsideClick';
import { MeetingData, MeetingSection } from '../../types/types';
import { logTemplateUserAction } from '../../../utils/analytics/eventLogger';
import ButtonMolecule from '../../../pages/all-pages/bottom-bar/ButtonMolecule';
import { OPEN_EVENT, TEMPLATES_TEMPLATES_PLUS_BUTTON_FIELD } from '../../../utils/analytics/enums';

const Container = styled.div``;

interface Props {
  meetingData: MeetingData,
  meetingTab: MeetingSection,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>
}

const Templates = ({
  meetingData,
  meetingTab,
  setIsTemplatesOpen,
}: Props) => {
  if (meetingTab === 'task') return null;
  if (meetingData.resolvedState !== 'resolved') return null;

  const dropDownRef = useRef(null);
  const { trackEvent } = useIntercom();
  const [isDrawerOpen, setIsDrawerOpen] = useDetectOutsideClick(dropDownRef, false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
    logTemplateUserAction(trackEvent, OPEN_EVENT, TEMPLATES_TEMPLATES_PLUS_BUTTON_FIELD,
      meetingData.version);
  };

  return (
    <Container>
      <ButtonMolecule onClick={toggleDrawer} text="Template" icon={<SinglePlusIcon />} tooltipText="Save or insert agenda template" />
      <TemplatesDropDown
        isOpen={isDrawerOpen}
        setIsOpen={setIsDrawerOpen}
        dropdownRef={dropDownRef}
        setIsTemplatesOpen={setIsTemplatesOpen}
        meetingTab={meetingTab}
        version={meetingData.version}
      />
    </Container>
  );
};

export default Templates;
