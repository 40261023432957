import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { useIntercom } from 'react-use-intercom';
import { DropResult, ResponderProvided } from 'react-beautiful-dnd';
import {
  TaskItem, TaskOrderField, TasksPage, UserCenterPage,
} from '../../types/types';
import EmptyTaskTab from './EmptyTaskTab';
import DroppableTaskCardsHolder from './DroppableTaskCardsHolder';
import DraggableTaskCard from './DraggableTaskCard';
import { dbBatchUpdateTaskOrder } from '../../../database/firebaseTasksAPI';
import { logEventDragAndDropTasks } from '../../../utils/analytics/eventLogger';

interface Props {
  tasks: TaskItem[],
  setSection?: Dispatch<SetStateAction<UserCenterPage>>
  page: TasksPage,
  taskOrderField: TaskOrderField,
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  disableCalendarSelect?: boolean,
  disableAssigneeSelect?: boolean,
  disableGoToMeeting?: boolean,
}

const TaskTabContent = ({
  tasks, page, setCreateTaskModalOpen, taskOrderField, setSection,
  disableAssigneeSelect, disableCalendarSelect, disableGoToMeeting,
}: Props) => {
  const [displayTasks, setDisplayTasks] = useState<TaskItem[]>(tasks);
  const { trackEvent } = useIntercom();

  useEffect(() => {
    setDisplayTasks(tasks);
  }, [tasks]);

  // eslint-disable-next-line no-unused-vars
  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const reorderedTasks = reorder(
      displayTasks,
      result.source.index,
      result.destination.index,
    );

    setDisplayTasks(reorderedTasks);
    dbBatchUpdateTaskOrder(reorderedTasks, taskOrderField);
    logEventDragAndDropTasks(page, trackEvent);
  };

  const reorder = (taskArray: TaskItem[], source: number, destination: number) => {
    const result = [...taskArray];
    const [removedItem] = result.splice(source, 1);
    result.splice(destination, 0, removedItem);
    result.forEach((task, index) => {
      result[index].order[taskOrderField] = index;
    });
    return result;
  };

  const compareTasksOrderIndex = (
    firstTask: TaskItem, secondTask: TaskItem,
  ) => firstTask.order[taskOrderField] - secondTask.order[taskOrderField];

  if (tasks.length === 0) {
    return (
      <EmptyTaskTab setCreateTaskModalOpen={setCreateTaskModalOpen} />
    );
  }

  return (
    <DroppableTaskCardsHolder onDragEnd={onDragEnd}>
      {displayTasks.sort(compareTasksOrderIndex).map((task, index) => (
        <DraggableTaskCard
          key={task.taskId}
          task={task}
          setSection={setSection}
          index={index}
          disableCalendarSelect={disableCalendarSelect}
          disableAssigneeSelect={disableAssigneeSelect}
          disableGoToMeeting={disableGoToMeeting}
        />
      ))}
    </DroppableTaskCardsHolder>
  );
};

TaskTabContent.defaultProps = {
  setSection: () => {},
  disableCalendarSelect: false,
  disableAssigneeSelect: false,
  disableGoToMeeting: false,
};

export default TaskTabContent;
