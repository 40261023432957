import React, {
  useContext, useEffect, useState, Dispatch, SetStateAction,
} from 'react';
import { useHistory } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { captureException } from '@sentry/minimal';
import { AuthContext } from '../../App';
import { dbAddMeeting, dbGetMeetingByEventAndCalendarId } from '../../database/firebaseMeetingAPI';
import { dbGetSimpleUserDataByUserId } from '../../database/firebaseUsersAPI';
import { mapGoogleMeetingToDatabaseMeetingData } from '../../database/utils/templateMeetingData';
import SpinnerLoadingPage from '../../shared/components/loading/spinner-loading-page';
import { AuthState, ShepherdMeetingId, SimpleUserData } from '../../shared/types/types';
import { performance } from '../../utils/firebase';
import { logEventPageViewGoogleCalendar } from '../../utils/analytics/eventLogger';
import { gapiGetMeeting } from '../../utils/google/GoogleCalendarAPI';
import { makeMeetingUrl } from '../../utils/meetings/meetingsUtils';
import GoogleCalendarErrorPage from './GoogleCalendarErrorPage';
import ConsoleImproved from '../../shared/classes/ConsoleImproved';

type Props = {
  match: any;
};

const GoogleCalendar = ({ match }: Props) => {
  const [error, setError] = useState<boolean>(false);
  const authState = useContext(AuthContext);
  const history = useHistory();
  const { update } = useIntercom();

  const eventId: string = match.params?.eventId ?? '';
  const calendarId: string = match.params?.calendarId ?? '';

  useEffect(() => {
    getOrMakeMeetingByEventAndCalendarId(eventId, calendarId, authState, history, setError);
  }, [eventId, calendarId]);

  useEffect(() => {
    ConsoleImproved.log('GoogleCalendar.jsx: useEffect');
  }, []);

  useEffect(() => {
    logEventPageViewGoogleCalendar(authState, update);
  }, []);

  const handleRetry = () => {
    setError(false);
    getOrMakeMeetingByEventAndCalendarId(eventId, calendarId, authState, history, setError);
  };

  if (error) {
    return <GoogleCalendarErrorPage email={authState.email} retry={handleRetry} />;
  }

  return (
    <SpinnerLoadingPage />
  );
};

export default GoogleCalendar;

const getOrMakeMeetingByEventAndCalendarId = async (
  eventId: string,
  calendarId: string,
  authState: AuthState,
  history: any,
  setError: Dispatch<SetStateAction<boolean>>,
) => {
  if (eventId.length === 0 || calendarId.length === 0) {
    console.log('EventId or calendarId is not long enough', eventId, calendarId);
    captureException('EventId or calendarId is not long enough');
    setError(true);
    return;
  }

  console.log('Calling', eventId, calendarId);
  let { meetingId } = await dbGetMeetingByEventAndCalendarId(eventId, calendarId);
  if (meetingId.length === 0) {
    const newMeeting = await makeNewMeetingByEventAndCalendarId(
      eventId, calendarId, authState,
    );

    if (newMeeting.resolvedState === 'rejected') {
      setError(true);
      return;
    }

    meetingId = newMeeting.meetingId;
  }

  history.push(makeMeetingUrl(meetingId));
};

const makeNewMeetingByEventAndCalendarId = async (
  eventId: string,
  calendarId: string,
  userData: AuthState,
) => {
  const trace = performance().trace('makeNewMeetingByEventAndCalendarId');
  trace.start();
  const googleMeeting = await gapiGetMeeting(
    eventId, calendarId, userData.email,
  );
  if (googleMeeting.resolvedState === 'rejected') {
    trace.stop();
    return { resolvedState: 'rejected', meetingId: '' } as ShepherdMeetingId;
  }

  const user: SimpleUserData = await dbGetSimpleUserDataByUserId(userData.userId, 'admin');
  const newMeeting = mapGoogleMeetingToDatabaseMeetingData(
    googleMeeting,
    calendarId,
    user,
  );
  const newMeetingIdResponse: ShepherdMeetingId = await dbAddMeeting(newMeeting);
  trace.stop();

  return newMeetingIdResponse;
};
