import { keymap } from 'prosemirror-keymap';
import { history } from 'prosemirror-history';
import { baseKeymap } from 'prosemirror-commands';
import { EditorState, Plugin } from 'prosemirror-state';
import { dropCursor } from 'prosemirror-dropcursor';
import { gapCursor } from 'prosemirror-gapcursor';
import {
  columnResizing,
  tableEditing,
} from 'prosemirror-tables';
import { Node } from 'prosemirror-model';
import buildKeymap from './keymap/index';
import { buildInputRules } from './input-rules/index';
import placeholderPlugin from './images/placeholder-plugin';
import suggestionNavigator from './keymap/suggestion-navigator';
import { todoItemKeymap } from './nodes/checkbox';
import indentation from './keymap/indentation';
import autoLink from './input-rules/auto-link';
import immediateDecoration from './decorations/immediate-decorations';

export default function setup(options: Record<string, any>) {
  delete baseKeymap.Enter;
  const plugins: Plugin<any, any>[] = [
    buildInputRules(options.schema),
    keymap(suggestionNavigator),
    keymap(todoItemKeymap(options.schema)),
    keymap(buildKeymap(options.schema, options.mapKeys)),
    keymap(baseKeymap),
    keymap(indentation),
    dropCursor(),
    gapCursor(),
    placeholderPlugin,
    tableEditing(),
    columnResizing({}),
    autoLink,
    immediateDecoration,
  ];

  if (options.history !== false) {
    plugins.push(history());
  }

  function toggleTodoItemAction(state: EditorState, pos: number, todoItemNode: Node) {
    return state.tr.setNodeMarkup(pos, undefined, { done: !todoItemNode.attrs.done });
  }

  return plugins.concat(new Plugin({
    props: {
      attributes: {
        class: 'ProseMirror-setup-style',
      },
      handleClickOn(editorView, pos, node, nodePos, event) {
        if ((event!.target! as any).classList.contains('todo-checkbox')) {
          editorView.dispatch(toggleTodoItemAction(editorView.state, nodePos, node));
          return true;
        }
        return false;
      },
    },
  }));
}
