import React from 'react';
import styled from 'styled-components';
import {
  red3, darkBlue1, gray1,
} from '../colours';
import { uiTextMedium } from '../typography';

interface IconProps {
  backgroundColor: string;
  fontSize: number;
  iconSize: number;
  borderSize: number;
  isNonUser: boolean;
}

const Icon = styled.div<IconProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;    
  position: relative;
  height: ${(props) => props.iconSize + 2}px;
  width: ${(props) => props.iconSize + 2}px;
  border-radius: 50%;

  ${uiTextMedium}
  font-size: ${(props) => props.fontSize}px;

  background: ${(props) => props.backgroundColor};
  border: ${(props) => (props.isNonUser
    ? `2px solid ${gray1}`
    : `${props.borderSize}px solid ${gray1}`)};
  cursor: pointer;
  z-index: 20;
`;
export interface Props {
  email: string,
  color?: string,
  size?: number,
  nonUser?: boolean,
}

const ProfileIconFirstLetter = ({
  email, color = red3, size = 24, nonUser = false,
}: Props) => {
  const firstLetterUppercase = email.toUpperCase()[0];
  const backgroundColor = nonUser ? darkBlue1 : color;
  const fontSize = ((14 * (size as number)) / 24);
  const borderSize = (2 * (size as number)) / 24;

  return (
    // the 14 here is the standard font size, 24 and 2 are the standrad icon size.
    // See the default font size definition in src/shared/typography/index.tsx
    <Icon
      backgroundColor={backgroundColor}
      fontSize={fontSize}
      iconSize={size}
      borderSize={borderSize}
      isNonUser={nonUser}
    >
      {firstLetterUppercase}
    </Icon>
  );
};

export default ProfileIconFirstLetter;
