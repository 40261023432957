import { Dispatch, SetStateAction } from 'react';
import * as Sentry from '@sentry/react';
import { functions } from '../../../../../utils/firebase';
import { logInviteEvent } from '../../../../../utils/analytics/eventLogger';
import { toastSuccess } from '../../../../../utils/notifications';
import {
  IntercomTrackEvent, User, InviterData,
  InviteeData, InviteEmailInput, ResolvedState,
} from '../../../../types/types';
import { cfSearchPublicUserDataV2ByEmailsV2 } from '../../../../../external/publicUserData/PublicUserDataAPI';
import { INVITE_NON_SHEPHERD_USERS_MODAL, SENT_INVITE_ACTION } from '../../../../../utils/analytics/enums';
import { dateISOObject } from '../../../../../utils/dateUtils/date';

/**
 * @description sends invites to non-shepherd users
 * @param emails list of emails to send invites to
 * @param setValues set the values of the invite input field
 * @param closeModal close the invite emails modal
 * @param setMembersInvited set where the invites were successfully sent
 * @param setLoading set whether the invites are still being sent
 * @param trackEvent for analytics
 */
export const sendInviteEmails = async (
  emails: string[],
  setValues: Dispatch<SetStateAction<InviteEmailInput[]>>,
  closeModal: () => void,
  setMembersInvited: Dispatch<SetStateAction<boolean>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
  trackEvent: IntercomTrackEvent,
) => {
  const resolvedState = await cfSendInviteEmails(emails);
  if (resolvedState !== 'resolved') {
    setLoading(false);
    return;
  }

  updatesAfterSuccessfullySendingInviteEmails(
    emails,
    setValues,
    closeModal,
    setMembersInvited,
    setLoading,
    trackEvent,
  );
};

/**
 * @description calls the cloud function sendInviteMembersEmailsV2 to send out
 * the invites to the list of emails
 * @param emails list of emails to send invites to
 */
const cfSendInviteEmails = async (
  emails: string[],
) => (
  functions()
    .httpsCallable('sendInviteMembersEmailsV2')({ emails })
    .then((response) => {
      if (response.data.status !== 200) {
        console.log('error sending email invites');
        return 'rejected' as ResolvedState;
      }

      console.log('successfuly sent email invites');
      return 'resolved' as ResolvedState;
    })
    .catch((error) => {
      console.log('error sending email invites', error);
      Sentry.captureException('error sending email invites', error);
      return 'rejected' as ResolvedState;
    })
);

/**
 * @description updates the state after successfully sending invites
 */
const updatesAfterSuccessfullySendingInviteEmails = (
  emails: string[],
  setValues: Dispatch<SetStateAction<InviteEmailInput[]>>,
  closeModal: () => void,
  setMembersInvited: Dispatch<SetStateAction<boolean>>,
  setLoading: Dispatch<SetStateAction<boolean>>,
  trackEvent: IntercomTrackEvent,
) => {
  setValues([]);
  closeModal();
  setMembersInvited(true);
  toastSuccess('Success', 'Invite sent');
  logInviteEvent(trackEvent, INVITE_NON_SHEPHERD_USERS_MODAL, SENT_INVITE_ACTION, emails.length);
  setLoading(false);
};

/**
 * @description track user invites in firebase
 * @param emails list of emails that invites were sent to
 * @param userData user data of the user who sent the invites
 */
export const trackUserInvites = async (
  emails: string[],
  userData: User,
) => {
  const inviter = mapUserDataToInviterData(userData);
  const emailsOfNonShepherdUsers = await filterEmailsForNonShepherdUsers(emails);
  emailsOfNonShepherdUsers.map(async (email) => createOrUpdateInviteDataInDatabase(email, inviter));
};

const mapUserDataToInviterData = (userData: User): InviterData => ({
  userId: userData.userId,
  email: userData.data.email,
  name: `${userData.data.firstName} ${userData.data.lastName}`,
  date: {
    inviteSent: dateISOObject(),
  },
});

/**
 * @returns all emails that are not associated to any Shepherd users
 */
const filterEmailsForNonShepherdUsers = async (emails: string[]) => {
  const users = await cfSearchPublicUserDataV2ByEmailsV2(
    emails,
  );
  const nonShepherdUsers = users
    .filter((user) => (!user.isShepherdUser));
  const emailsOfNonShepherdUsers = nonShepherdUsers
    .map((publicUserData) => (
      publicUserData.data.email
    ));
  return emailsOfNonShepherdUsers;
};

const createOrUpdateInviteDataInDatabase = async (
  email: string,
  inviter: InviterData,
) => {
  const { resolvedState, inviteId } = await cfCreateOrUpdateInviteData(email, inviter);
  if (resolvedState !== 'resolved' || inviteId.length === 0) return;
  await updateUserDataInvitedList(inviteId, email, inviter);
};

// TODO Matt: Make this strongly typed
const cfCreateOrUpdateInviteData = async (
  email: string,
  inviter: InviterData,
) => functions()
  .httpsCallable('createOrUpdateInviteDataV2')({ email, inviter })
  // TODO: Use await in stead of .then()
  .then((res) => {
    if (res.data.resolvedState === 'rejected') {
      console.log('error saving invite data');
      return res.data;
    }

    console.log('successfully saved invite data');
    return res.data;
  })
  .catch((error) => {
    console.log('error saving invite data', error);
    return { resolvedState: 'rejected', inviteId: '' };
  });

const updateUserDataInvitedList = async (
  inviteId: string,
  inviteeEmail: string,
  inviter: InviterData,
) => {
  const inviteeData = mapInviteDataToInviteeData(inviteeEmail);
  await cfUpdateUserDataWithEmailInvite(inviteId, inviteeData, inviter);
};

const mapInviteDataToInviteeData = (email: string) => ({
  email,
  isSignedUp: false,
  date: {
    inviteSent: dateISOObject(),
  },
} as InviteeData);

const cfUpdateUserDataWithEmailInvite = async (
  inviteId: string,
  invitee: InviteeData,
  inviter: InviterData,
) => (
  functions()
    .httpsCallable('addInviteDataToUserData')({ inviterUserId: inviter.userId, inviteId, invitee })
    .then(() => console.log('invitees successfully added to user data'))
    .catch((error) => console.log('error adding invitees to user data', error))
);
