import { Dispatch, SetStateAction } from 'react';
import { EditorView } from 'prosemirror-view';
import { Transaction } from 'prosemirror-state';
import { DOMParser } from 'prosemirror-model';
import { performance } from '../../../../utils/firebase';
import { gapiGetMeeting, gapiInsertFirstMeeting } from '../../../../utils/google/GoogleCalendarAPI';
import {
  AuthState, GoogleMeetingIds, ShepherdMeetingId, SimpleUserData,
} from '../../../../shared/types/types';
import { mapGoogleMeetingToDatabaseMeetingData } from '../../../../database/utils/templateMeetingData';
import { dbGetSimpleUserDataByUserId } from '../../../../database/firebaseUsersAPI';
import { dbAddMeeting } from '../../../../database/firebaseMeetingAPI';
import { toastDanger } from '../../../../utils/notifications';
import FIRST_MEETING_CONTENT from './constants';

export const createEventInGoogleCalendarAndMeetingInDatabase = async (
  authState: AuthState,
  setFirstMeetingId: Dispatch<SetStateAction<string>>,
  setIsError: Dispatch<SetStateAction<boolean>>,
) => {
  const { eventId, calendarId, resolvedState }:GoogleMeetingIds = await gapiInsertFirstMeeting();
  if (resolvedState === 'rejected' || !eventId || !calendarId) {
    console.log('error creating event in google calendar');
    setIsError(true);
  }

  const newMeetingObj: ShepherdMeetingId = await makeNewMeetingByEventAndCalendarId(
    eventId,
    calendarId,
    authState,
  );
  if (newMeetingObj.resolvedState === 'rejected') {
    console.log('error creating meeting in db by eventId and calendarId');
    setIsError(true);
  }

  setFirstMeetingId(newMeetingObj.meetingId);
};

export const makeNewMeetingByEventAndCalendarId = async (
  eventId: string,
  calendarId: string,
  userData: AuthState,
) => {
  const trace = performance().trace('makeNewMeetingByEventAndCalendarId');
  trace.start();
  const googleMeeting = await gapiGetMeeting(
    eventId, calendarId, userData.email,
  );
  if (googleMeeting.resolvedState === 'rejected') {
    trace.stop();
    return { resolvedState: 'rejected', meetingId: '' } as ShepherdMeetingId;
  }

  const user: SimpleUserData = await dbGetSimpleUserDataByUserId(userData.userId, 'admin');
  const newMeeting = mapGoogleMeetingToDatabaseMeetingData(
    googleMeeting,
    calendarId,
    user,
  );
  const newMeetingIdResponse: ShepherdMeetingId = await dbAddMeeting(newMeeting);
  trace.stop();
  return newMeetingIdResponse;
};

export const insertFirstMeetingNotes = async (
  editorView: EditorView,
  setIsNotesInserted: Dispatch<SetStateAction<boolean>>,
  setIsError: Dispatch<SetStateAction<boolean>>,
) => {
  const { schema } = editorView.state;
  const { state } = editorView;
  const parser = DOMParser.fromSchema(schema);

  const tmpNode = document.createElement('div');
  tmpNode.innerHTML = FIRST_MEETING_CONTENT;

  const newNodes = parser.parse(tmpNode);

  const { tr } = state;
  const head = state.selection.$head.pos ?? 0;
  if (head === 0) {
    console.log('Head is zero');
    toastDanger('Error', 'Issue inserting first meeting notes.');
    setIsError(true);
    return;
  }
  try {
    editorView.dispatch((tr as Transaction).insert(head, newNodes.content));
  } catch (error) {
    console.log('Error', 'Issue inserting first meeting notes');
    console.log(error);
    setIsError(true);
  } finally {
    setIsNotesInserted(true);
  }
};
