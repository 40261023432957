import React from 'react';
import styled from 'styled-components';
import { gray7 } from '../../../shared/colours';
import { small } from '../../../shared/typography';
import { dateInMeetingCard } from '../../../utils/dateUtils/date';

const Container = styled.span`
  ${small};
  color: ${gray7};
`;

interface Props {
  // TODO replace type
  date: any
}

const NoteItemDateAtom = ({ date }: Props) => {
  const dateString = dateInMeetingCard(date);

  return (
    <Container>
      {dateString}
    </Container>
  );
};

export default NoteItemDateAtom;
