import React, { Dispatch, SetStateAction } from 'react';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import TaskCard from '../task-card';
import { TaskItem, UserCenterPage } from '../../types/types';
import useDraggableInPortal from '../../../utils/hook/useDraggableInPortal';

interface Props {
  task: TaskItem,
  setSection?: Dispatch<SetStateAction<UserCenterPage>>
  index: number,
  disableCalendarSelect?: boolean,
  disableAssigneeSelect?: boolean,
  disableGoToMeeting?: boolean,
}

const DraggableTaskCard = ({
  task, index, disableAssigneeSelect, disableCalendarSelect, disableGoToMeeting, setSection,
}: Props) => {
  const renderDraggable = useDraggableInPortal();

  // the styles should be mostly consistent to those of task card (the child)
  const getTaskCardStyle = (
    isDragging: boolean, isDropAnimating: boolean, draggableStyle: any,
  ) => ({
    userSelect: 'none',
    boxShadow: (isDropAnimating && 'unset') || (isDragging && '0px 2px 8px 0px #00000026'),
    borderRadius: '8px',
    marginBottom: '8px',
    position: isDragging ? 'fixed' : '',
    ...draggableStyle,
  });

  return (
    <Draggable key={task.taskId} draggableId={task.taskId} index={index}>
      {renderDraggable((provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <div
          ref={provided.innerRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.draggableProps}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.dragHandleProps}
          style={getTaskCardStyle(
            snapshot.isDragging,
            snapshot.isDropAnimating,
            provided.draggableProps.style,
          )}
        >
          <TaskCard
            taskItem={task}
            setSection={setSection}
            disableCalendarSelect={disableCalendarSelect}
            disableAssigneeSelect={disableAssigneeSelect}
            disableGoToMeeting={disableGoToMeeting}
          />
        </div>
      ))}
    </Draggable>
  );
};

DraggableTaskCard.defaultProps = {
  setSection: () => {},
  disableCalendarSelect: false,
  disableAssigneeSelect: false,
  disableGoToMeeting: false,
};

export default DraggableTaskCard;
