import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { AuthContext } from './App';
import { IntercomState } from './shared/types/types';
import { INTERCOM_APP_ID } from './utils/constants';
import { setupIntercom } from './utils/intercom';

export const IntercomContext = createContext<IntercomState>(false);

interface Props {
  children: React.ReactNode;
}

const IntercomContainer = ({ children } : Props) => {
  const [intercomState, setIntercomState] = useState<IntercomState>(false);

  // onHide and onShow is apparently not working (don't know why)
  // I will keep the IntercomContext as we might need it in the future
  const onHideIntercom = () => {
    console.log('onHide');
    setIntercomState(false);
  };
  const onShowIntercom = () => {
    console.log('onShow');
    setIntercomState(true);
  };

  return (
    <IntercomProvider
      appId={INTERCOM_APP_ID}
      onHide={onHideIntercom}
      onShow={onShowIntercom}
    >
      <IntercomContext.Provider value={intercomState}>
        <IntercomBooter>
          {children}
        </IntercomBooter>
      </IntercomContext.Provider>
    </IntercomProvider>
  );
};

const IntercomBooter = ({ children }: Props) => {
  const authState = useContext(AuthContext);
  const {
    boot, shutdown,
  } = useIntercom();

  useEffect(() => {
    setupIntercom(authState, boot, shutdown);
  }, [authState]);

  return (
    <>
      { children }
    </>
  );
};

export default IntercomContainer;
