import React, { useContext, useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useLocation } from 'react-router';
import { pendingMeetingData } from '../../database/utils/templateMeetingData';
import Header from '../../shared/components/header';
import {
  MeetingData, MeetingSection, PrivateNoteData, TaskItem, TaskTab,
  UserCenterPage, TemplateData,
} from '../../shared/types/types';
import Meeting from '../meeting';
import AllTasksOrganism from '../userCenter/AllTasksOrganism';
import MeetingDashboard from '../userCenter/meetingDashboard';
import Navbar from '../userCenter/navbar';
import BottomBar from './bottom-bar';
import MeetingDataContext from '../meeting/context/MeetingDataContext';
import ProseMirrorEditorViewContext from '../meeting/context/ProseMirrorEditorViewContext';
import { mapDatabaseTemplateDataToTemplateData } from '../../shared/components/templates-modal/utils';
import NewFeaturesModalOrgCtrl from '../../shared/components/new-feaures-modal/NewFeaturesModalOrgCtrl';
import { NewFeaturesV2 } from '../../shared/components/new-feaures-modal/newFeaturesConstant';
import ConnectionLostPopupAndModal from './connection-lost/ConnectionLostPopupAndModal';
import { dbListenToPrivateNotesData } from '../../database/firebasePrivateNotesAPI';
import { AuthContext } from '../../App';
import { DEFAULT_SECTION, PRIVATE_NOTES, TODO } from '../../utils/enums';
import useGetQueryParam from '../../utils/hook/useGetParams';
import { isValidTaskTab } from '../../utils/tasks/tasksUtils';
import PrivateNotesModals from '../../shared/components/private-notes/create-private-notes-modal-or-show-invite-notif';
import TemplateEditBottomBar from '../meeting/templates/components/TemplateEditBottomBar';
import AllNotesPageCtrl from '../all-notes/AllNotesPageCtrl';
import { DEFAULT_HEADER_MEETING_VIEW } from '../../shared/components/header/utils/constants';

interface Props {
  match: any,
}

const AllPages = ({ match }: Props) => {
  const authState = useContext(AuthContext);
  const location = useLocation();

  const [page, setPage] = useState<UserCenterPage>('currentMeeting');
  const [meetingData, setMeetingData] = useState<MeetingData>(pendingMeetingData);
  const [isEditTemplateEnabled, setIsEditTemplateEnabled] = useState<boolean>(false);
  const [selectedTemplateToEdit, setSelectedTemplateToEdit] = useState<TemplateData>(
    mapDatabaseTemplateDataToTemplateData('', ''),
  );
  const [meetingSection, setMeetingSection] = useState<MeetingSection>(DEFAULT_SECTION);
  const [isTemplatesOpen, setIsTemplatesOpen] = useState<boolean>(false);
  const [isRecurringMeeting, setIsRecurringMeeting] = useState<boolean>(false);
  const [proseMirrorEditorView, setProseMirrorEditorView] = useState<any>({ page: '', editorView: '' });

  const [isPrivateNoteModalOpen, setIsPrivateNoteModalOpen] = useState<boolean>(false);
  const [isPrivateNoteInviteNotifOpen, setIsPrivateNoteInviteNotifOpen] = useState(false);
  const [activePrivateNoteId, setActivePrivateNoteId] = useState<string>('');
  const [privateNotesData, setPrivateNotesData] = useState<PrivateNoteData[]>([]);

  const [taskTab, setTaskTab] = useState<TaskTab>(TODO);
  const [taskItems, setTaskItems] = useState<TaskItem[]>([]);
  const [isCreateTaskModalOpen, setCreateTaskModalOpen] = useState<boolean>(false);
  useHotkeys('alt+t', () => setCreateTaskModalOpen(true), {}, [meetingSection]);

  const onClickSelectPrivateNote = (chatId: string) => {
    if (chatId.length === 0) return;
    setActivePrivateNoteId(chatId);
    setMeetingSection(PRIVATE_NOTES);
  };

  const onClickClosePrivateNoteModal = () => setIsPrivateNoteModalOpen(false);
  const onClickClosePrivateNoteInviteNotif = () => setIsPrivateNoteInviteNotifOpen(false);

  useEffect(() => {
    if (meetingData.meetingId.length === 0) return;
    dbListenToPrivateNotesData(
      meetingData.meetingId,
      authState.userId,
      authState.email,
      setPrivateNotesData,
    );
  }, [meetingData, activePrivateNoteId]);

  useEffect(() => {
    if (privateNotesData.length > 0) setIsPrivateNoteInviteNotifOpen(true);
  }, [privateNotesData]);

  useEffect(() => {
    if (meetingSection !== PRIVATE_NOTES) setActivePrivateNoteId('');
  }, [meetingSection]);

  useEffect(() => {
    if (selectedTemplateToEdit.templateId.length === 0) {
      setIsEditTemplateEnabled(false);
    } else {
      setIsEditTemplateEnabled(true);
    }
  }, [selectedTemplateToEdit]);

  useEffect(() => {
    const taskSectionQueryParam: string = useGetQueryParam('taskSection'); // TODO Asish: Add type for these query params
    if (isValidTaskTab(taskSectionQueryParam)) {
      setTaskTab(taskSectionQueryParam as TaskTab);
    }
  }, [location]);
  return (
    <MeetingDataContext.Provider value={meetingData}>
      <ProseMirrorEditorViewContext.Provider value={proseMirrorEditorView}>
        <Header view={DEFAULT_HEADER_MEETING_VIEW} />
        <Navbar section={page} setSection={setPage} />
        <AllNotesPageCtrl section={page} />
        <AllTasksOrganism
          section={page}
          setSection={setPage}
          taskTab={taskTab}
          setTaskTab={setTaskTab}
        />
        <MeetingDashboard section={page} />
        <Meeting
          section={page}
          setSection={setPage}
          taskTab={taskTab}
          setTaskTab={setTaskTab}
          meetingData={meetingData}
          setMeetingData={setMeetingData}
          taskItems={taskItems}
          setTaskItems={setTaskItems}
          match={match}
          meetingTab={meetingSection}
          setMeetingTab={setMeetingSection}
          isTemplatesOpen={isTemplatesOpen}
          setIsTemplatesOpen={setIsTemplatesOpen}
          isEditTemplateEnabled={isEditTemplateEnabled}
          setSelectedTemplateToEdit={setSelectedTemplateToEdit}
          selectedTemplateToEdit={selectedTemplateToEdit}
          setCreateTaskModalOpen={setCreateTaskModalOpen}
          isRecurringMeeting={isRecurringMeeting}
          setProseMirrorEditorView={setProseMirrorEditorView}
          setPrivateNoteOpen={setIsPrivateNoteModalOpen}
          activePrivateNoteId={activePrivateNoteId}
          onClickSelectPrivateNote={onClickSelectPrivateNote}
          privateNotes={privateNotesData}
          setPrivateNotes={setPrivateNotesData}
        />
        <BottomBar
          isEditTemplateBottomBarEnabled={isEditTemplateEnabled}
          meetingData={meetingData}
          setIsTemplatesOpen={setIsTemplatesOpen}
          meetingTab={meetingSection}
          taskItems={taskItems}
          section={page}
          setCreateTaskModalOpen={setCreateTaskModalOpen}
          isCreateTaskModalOpen={isCreateTaskModalOpen}
          setIsRecurringMeeting={setIsRecurringMeeting}
          privateNotes={privateNotesData}
        />
        <PrivateNotesModals
          isPrivateNotesModalOpen={isPrivateNoteModalOpen}
          onClickClosePrivateNotesModal={onClickClosePrivateNoteModal}
          isPrivateNoteInviteNotifOpen={isPrivateNoteInviteNotifOpen}
          onClickClosePrivateNoteInviteNotif={onClickClosePrivateNoteInviteNotif}
          privateNotes={privateNotesData}
          onClickSelectPrivateNote={onClickSelectPrivateNote}
        />
        <TemplateEditBottomBar
          isEnabled={isEditTemplateEnabled}
          meetingTab={meetingSection}
          setSelectedTemplateToEdit={setSelectedTemplateToEdit}
          selectedTemplateToEdit={selectedTemplateToEdit}
        />
        <NewFeaturesModalOrgCtrl newFeature={NewFeaturesV2} />
        <ConnectionLostPopupAndModal />
      </ProseMirrorEditorViewContext.Provider>
    </MeetingDataContext.Provider>
  );
};

export default AllPages;
