import React from 'react';
import styled from 'styled-components';
import ReactTooltip from '../../../shared/components/tooltip/ReactTooltip';
import IconAtom from './IconAtom';
import OptionAtom from './OptionAtom';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 16px;
  border-radius: 14px;

  :hover {
    background: black;
  }

  & > {
   *:first-child{
     margin-right: 8px;
   } 
  } 
`;

interface Props {
  icon: any,
  text: string,
  onClick: () => void,
  tooltipText?: string,
}

const ButtonMolecule = ({
  icon, text, onClick, tooltipText = '',
}: Props) => (
  <ReactTooltip tip={tooltipText} delay disabled={tooltipText?.length === 0 ?? true}>
    <Container onClick={onClick}>
      <IconAtom icon={icon} />
      <OptionAtom text={text} />
    </Container>
  </ReactTooltip>
);

ButtonMolecule.defaultProps = {
  tooltipText: '',
};

export default ButtonMolecule;
