// ============ Sidebar State Analytics ===========

export const APP_EVENT = 'app';

export const APP_FIELD = 'loaded_state';

// ============ Sidebar State Analytics ===========

export const SIDEBAR_EVENT = 'sidebar';

export const SIDEBAR_FIELD = 'visible_state';

// ============ Page Analytics ===========

export const PAGE_EVENT = 'page';

export const PAGE_FIELD = 'visible_state';

export const MEETING_PAGE = 'meeting';
export const LOGIN_PAGE = 'login';

export const ONBOARDING_FORM_PAGE = 'onboarding_form';
export const ONBOARDING_MEET_PAGE = 'onboarding_meet';
export const ONBOARDING_CALENDAR_PAGE = 'onboarding_calendar';
export const ONBOARDING_TASKS_PAGE = 'onboarding_tasks';
export const ONBOARDING_INTEGRATIONS_PAGE = 'onboarding_connect_integrations';
export const ONBOARDING_VIDEO_PAGE = 'onboarding_video_intro';
export const ONBOARDING_FIRST_MEETING_PAGE = 'onboarding_first_meeting';
export const INVITE_NON_SHEPHERD_USERS_MODAL = 'invite_non_shepherd_users_modal';
export const GOOGLE_CALENDAR_ERROR_PAGE = 'google_calendar_error_page';
export const GOOGLE_MEET_ERROR_PAGE = 'google_meet_error_page';

// ============ Onboarding Analytics ===========

export const ONBOARDING_EVENT = 'onboarding';

export const CREATE_MEETING_ERROR_EVENT = 'create_meeting_error';

// ============ Update Database Analytics ===========

export const DATABASE_EVENT = 'database';

export const DATABASE_PHOTO_URL_FIELD = 'photoUrl';

// ============ Consolidated Tasks Analytics ===========

export const CONSOLIDATED_TASKS_EVENT = 'consolidated_tasks';

export const CONSOLIDATED_TASKS_BACK_BUTTON_FIELD = 'back_button';

// ============ Page Analytics ===========

export const AUTHORIZATION_EVENT = 'authorization';

export const INVITED_SIGN_UP = 'invited';
export const ORGANIC_SIGN_UP = 'organic';

// ============ Invite Analytics ===========

export const INVITE_EVENT = 'invite';

export const SENT_INVITE_ACTION = 'sent';

// ============ Templates Analytics ===========

export const TEMPLATES_EVENT = 'templates';

export const TEMPLATES_NOTE_TEMPLATE_FIELD = 'note_template';
export const TEMPLATES_BROWSE_MY_TEMPLATES_BUTTON_FIELD = 'browse_my_templates_in_dropdown';
export const TEMPLATES_SAVE_AS_TEMPLATE_BUTTON_FIELD = 'save_as_template_in_dropdown';
export const TEMPLATES_SAVE_TEMPLATES_MODAL_FIELD = 'save_templates_modal';
export const TEMPLATES_TEMPLATES_PLUS_BUTTON_FIELD = 'templates_plus_button';
export const TEMPLATES_BROWSE_TEMPLATES_VIEW_FIELD = 'browse_templates_view';

// ============ Notion Analytics ===========

export const NOTION_EVENT = 'notion';

export const NOTION_PAGE_FIELD = 'page';
export const NOTION_WORKSPACE_PAGE_FIELD = 'workspace_page';
export const NOTION_SUB_PAGE_FIELD = 'sub_page';

// ============ Slack Analytics ===========

export const SLACK_EVENT = 'slack';

export const SLACK_CHANNEL = 'channel';
export const SLACK_DM = 'direct_message';
export const SLACK_USER = 'userInfo';
export const SLACK_NOTIFICATION = 'notification';

// ============ Tasks Analytics ===========

export const TASKS_EVENT = 'tasks';

export const TASKS_STATUS_FIELD = 'status';
export const TASKS_TITLE_FIELD = 'title';
export const TASKS_DUE_DATE_FIELD = 'due_date';
export const TASKS_ASSIGNEE_FIELD = 'assignee';
export const TASKS_MODAL_FIELD = 'modal';
export const TASKS_CREATE_TASK_BUTTON_FIELD = 'create_task_button';
export const TASKS_DELETE_TASK_BUTTON_FIELD = 'delete_task_button';

// ============ GAPI Analytics ===========

export const GAPI_EVENT = 'gapi';

export const GAPI_MEETING_FIELD = 'meeting';

// ============ Event Types Analytics ===========

export const EDIT_EVENT = 'edit';
export const GET_EVENT = 'get';
export const CREATE_EVENT = 'create';
export const SEND_EVENT = 'send';
export const DELETE_EVENT = 'delete';
export const OPEN_EVENT = 'open';
export const CLOSE_EVENT = 'close';
export const INSERT_EVENT = 'insert';
export const UPDATE_EVENT = 'update';
export const LOGIN_EVENT = 'login';
export const SIGNUP_EVENT = 'signup';
export const LOADED_EVENT = 'loaded';
export const VIEW_EVENT = 'view';
export const SUBMIT_EVENT = 'submit';
export const ERROR_EVENT = 'error';
