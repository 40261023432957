import React from 'react';
import styled from 'styled-components';
import { green6, surface } from '../../../colours';
import TickIcon from '../../../icons/TickIcon';
import { ResolveStateExtended } from '../../../types/types';
import { uiTextMedium } from '../../../typography';
import CssSpinner from '../../css-spinner';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  :first-child {
    margin-right: 4px;
  }
`;

const Text = styled.span`
  color: ${green6};
  ${uiTextMedium};
  margin: 0px;
`;

interface Props {
  isUpdated: ResolveStateExtended,
}

const UpdatedAtom = ({ isUpdated }: Props) => {
  if (isUpdated === 'null') return null;

  if (isUpdated === 'pending') {
    return (
      <Container>
        <CssSpinner color={surface} size={30} thickness={4} padding={4} />
      </Container>
    );
  }
  return (
    <Container>
      <TickIcon fill={green6} />
      <Text>Updated</Text>
    </Container>
  );
};

export default UpdatedAtom;
