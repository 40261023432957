/* eslint-disable no-unused-vars */
import { suggest, Suggester } from 'prosemirror-suggest';
import emoji from 'node-emoji';
import Fuse from 'fuse.js';
import emojiAdapter from './emoji-adapter';
import mentionsAdapter from './mentions-adapter';
import commandsAdapter from './command-adapter';

const suggestEmojis: (...args: any) => Suggester = () => ({
  char: ':', // The character to match against
  name: 'emoji-suggestion', // a unique name
  appendText: '', // Text to append to the created match
  suggestClassName: 'emoji-suggesting',

  onChange: (params) => {
    if (params.exitReason) {
      emojiAdapter.setShow(false);
      return;
    }
    const v = params.view;
    const query = params.query.full;
    const results = emoji.search(query).slice(0, 10);
    console.log(results);
    if (!results || results.length === 0) {
      emojiAdapter.setShow(false);
      return;
    }
    const position = v.coordsAtPos(v.state.selection.$anchor.pos);
    emojiAdapter.setResults(results);
    emojiAdapter.setPos(position.left, position.bottom);
    emojiAdapter.setShow(true);
  },
});

const suggestMentions: (...args: any) => Suggester = () => ({
  char: '@', // The character to match against
  name: 'mentions-suggestion', // a unique name
  appendText: '', // Text to append to the created match
  suggestClassName: 'mentions-suggesting',

  onChange: (params) => {
    if (params.exitReason) {
      mentionsAdapter.setShow(false);
      return;
    }

    const v = params.view;
    const query = params.query.full;
    const results = (new Fuse(mentionsAdapter.getAttendees(), {
      keys: ['name', 'email'],
    })).search(query);

    if (!results || results.length === 0) {
      mentionsAdapter.setShow(false);
      return;
    }

    const position = v.coordsAtPos(v.state.selection.$anchor.pos);
    mentionsAdapter.setResults(results);
    mentionsAdapter.setPos(position.left, position.bottom);
    mentionsAdapter.setShow(true);
  },
});

const suggestCommands: () => Suggester = () => ({
  char: '/', // The character to match against
  name: 'command-suggestion', // a unique name
  appendText: '', // Text to append to the created match
  suggestClassName: 'command-suggesting',

  onChange: (params) => {
    if (params.exitReason) {
      commandsAdapter.setShow(false);
      return;
    }

    const v = params.view;
    const query = params.query.full;
    const results = (new Fuse(commandsAdapter.getCommands(), {
      keys: ['name'],
    })).search(query);

    if (!results || results.length === 0) {
      commandsAdapter.setShow(false);
      return;
    }

    const position = v.coordsAtPos(v.state.selection.$anchor.pos);
    commandsAdapter.setResults(results);
    commandsAdapter.setPos(position.left, position.bottom);
    commandsAdapter.setShow(true);
  },
});

export default suggest(suggestEmojis(), suggestMentions(), suggestCommands());
