import React from 'react';
import styled from 'styled-components';
import { gray9 } from '../../../shared/colours';
import { header500 } from '../../../shared/typography';

const Container = styled.div`
  ${header500}
  color: ${gray9};
`;

interface Props {
  text: string;
}

const NotesListLabelAtom = ({ text }: Props) => (
  <Container>
    {text}
  </Container>
);

export default NotesListLabelAtom;
