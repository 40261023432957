import {
  AttendeeV2, AuthState, PrivateNoteAttendeeV2,
} from '../../../../../shared/types/types';

export const validateAttendeesV2HaveValidUserId = (attendees: AttendeeV2[]) => (
  attendees.filter((attendee) => attendee.userId.length !== 0)
);

export const getUserAttendeeDataFromAttendees = (attendees: AttendeeV2[], authData: AuthState) => (
  attendees.filter((attendee) => (attendee.data.email === authData.email))[0]
);

export const filterCurrentUserFromAttendees = (attendees: AttendeeV2[], authData: AuthState) => (
  attendees.filter((attendee) => (attendee.data.email !== authData.email))
);

export const checkIfUserIsAlreadyInvitedToPrivateNote = (
  privateNoteMembers: PrivateNoteAttendeeV2[],
  email: string,
) => (
  privateNoteMembers.filter((member) => (
    member.data.email === email
  )).length > 0
);

export const removeMemberFromPrivateMemberByEmail = (
  privateNoteMembers: PrivateNoteAttendeeV2[],
  email: string,
) => (
  privateNoteMembers.filter((member) => (
    member.data.email !== email
  ))
);
