/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
import { Keymap } from 'prosemirror-commands';
import { NodeType, Schema } from 'prosemirror-model';
import commandAdapter from '../suggestions/command-adapter';
import emojiAdapter from '../suggestions/emoji-adapter';
import mentionsAdapter from '../suggestions/mentions-adapter';

export default {
  ArrowUp: (state, dispatch, view) => {
    if (view?.dom.getElementsByClassName('emoji-suggesting').length! > 0) {
      emojiAdapter.moveSelectionUp();
      return true;
    }

    if (view?.dom.getElementsByClassName('mentions-suggesting').length! > 0) {
      mentionsAdapter.moveSelectionUp();
      return true;
    }

    if (view?.dom.getElementsByClassName('command-suggesting').length! > 0) {
      commandAdapter.moveSelectionUp();
      return true;
    }

    return false;
  },
  ArrowDown: (state, dispatch, view) => {
    if (view?.dom.getElementsByClassName('emoji-suggesting').length! > 0) {
      emojiAdapter.moveSelectionDown();
      return true;
    }

    if (view?.dom.getElementsByClassName('mentions-suggesting').length! > 0) {
      mentionsAdapter.moveSelectionDown();
      return true;
    }

    if (view?.dom.getElementsByClassName('command-suggesting').length! > 0) {
      commandAdapter.moveSelectionDown();
      return true;
    }

    return false;
  },
  Enter: (state, dispatch, view) => {
    if (view?.dom.getElementsByClassName('emoji-suggesting').length! > 0) {
      const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('emoji-suggesting')[0], 0);
      const e = emojiAdapter.getSelectedEmoji();
      if (!e || !currentPos) return true;
      const { tr } = state;
      tr.insertText(e, currentPos, state.selection.$anchor.pos);
      dispatch!(tr);
      emojiAdapter.setShow(false);
      return true;
    }

    if (view?.dom.getElementsByClassName('mentions-suggesting').length! > 0) {
      const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('mentions-suggesting')[0], 0);
      const e = mentionsAdapter.getSelectedMention();
      if (!e || !currentPos) return true;
      const { tr } = state;
      const mention: NodeType = (state.schema as Schema).nodes.resolvedMention;
      tr.replaceRangeWith(
        currentPos,
        state.selection.$anchor.pos,
        mention.create({
          name: e.name,
          email: e.email,
        }, [(state.schema as Schema).text(e.name || e.email)]),
      );
      dispatch!(tr);
      mentionsAdapter.setShow(false);
      return true;
    }

    if (view?.dom.getElementsByClassName('command-suggesting').length! > 0) {
      const currentPos = view?.posAtDOM(view.dom.getElementsByClassName('command-suggesting')[0], 0);
      const c = commandAdapter.getSelectedCommand();
      if (!c || !currentPos) return true;
      const { tr } = state;
      c.callback();
      if (!c.useTransactionLogic) {
        tr.deleteRange(currentPos, state.selection.$anchor.pos);
        dispatch!(tr);
      }
      commandAdapter.setShow(false);
      return true;
    }

    return false;
  },
} as Keymap;
