import React, {
  useState, useContext, useEffect, SetStateAction, Dispatch,
} from 'react';
import styled from 'styled-components';
import { EditorView } from 'prosemirror-view';
import { AuthContext } from '../../../../App';
import Notes from '../../../../pages/meeting/notes';
import { MeetingSection, Shortcut, TaskTab } from '../../../types/types';
import Tasks from '../../tasks';

const Container = styled.div`
  width: 520px;
  height: calc(100vh - 150px - 60px);
  overflow: hidden;
`;

interface Props {
  meetingId: string
  meetingTab: MeetingSection
  meetingTasks: any[]
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  taskTab: TaskTab,
  setTaskTab: Dispatch<SetStateAction<TaskTab>>,
}

const PreviousMeetingNotesView = ({
  meetingId,
  meetingTab,
  meetingTasks,
  setCreateTaskModalOpen,
  taskTab,
  setTaskTab,
}:Props) => {
  const authState = useContext(AuthContext);

  /* eslint-disable-next-line no-unused-vars */
  const [notesContent, setNotesContent] = useState<string>('');
  /* eslint-disable-next-line no-unused-vars */
  const [isTabContentSynchronized, setTabContentSynchronized] = useState<boolean>(true);
  const [proseMirrorEditorView, setProseMirrorEditorView] = useState<EditorView | null>(null);

  useEffect(() => {
    console.log(proseMirrorEditorView);
  }, [proseMirrorEditorView]);

  const handleShortcutTrigger = (shortcut: Shortcut) => {
    if (shortcut === 'navigateLeft') {
      // leftKeyNavigation(meetingTab, setMeetingTab);
      // TODO: Handle
    }
    if (shortcut === 'navigateRight') {
      // rightKeyNavigation(meetingTab, setMeetingTab);
      // TODO: Handle
    }
    if (shortcut === 'openCreateTask') {
      // setCreateTaskModalOpen(true);
      // TODO: Handle
    }
  };

  if (meetingTab === 'task') {
    return (
      <Tasks
        taskTab={taskTab}
        setTaskTab={setTaskTab}
        taskItems={meetingTasks}
        setCreateTaskModalOpen={setCreateTaskModalOpen}
        height="calc(100vh - 150px - 60px)"
        page="meeting"
      />
    );
  }

  return (
    <Container>
      <Notes
        page={meetingTab}
        userId={authState.userId}
        meetingId={meetingId}
        setNotesContent={setNotesContent}
        setIsSynchronized={setTabContentSynchronized}
        setProseMirrorEditorView={setProseMirrorEditorView}
        onShortcutTrigger={handleShortcutTrigger}
        isPreviousMeeting
      />
    </Container>
  );
};

export default PreviousMeetingNotesView;
