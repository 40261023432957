import {
  SlackChannel, SlackData, SlackNotifications, TaskItem,
  SlackUser, AuthState, User, PrivateNoteHtml,
} from '../../shared/types/types';
import { makeSlackHeader, isEmptyHTML } from '../strings';
import { mapTaskStatusToEmoji } from '../tasks/tasksUtils';
import { dateISOObject, DateToTemplateDateFormat } from '../dateUtils/date';

// TODO: Move type definitions to types file
export type SlackMessage = {
  title: string,
  content: string,
}

export interface Notification {
  email: string,
  blocks: Block[],
  context: AuthState,
}

// referce: https://api.slack.com/reference/block-kit/blocks
export interface Block {
  type: string,
  text: {
    type: string,
    text: string,
  }
}

type assembleSlackMessageParamType = {
  agendaChecked: boolean,
  sharedNotesChecked: boolean,
  personalNotesChecked: boolean,
  emailHeadTemplate: string,
  agendaHtml: string,
  sharedNotesHtml: string,
  personalNotesHtml: string,
  taskChecked: boolean,
  title: string,
  startTime: string,
  taskItems: TaskItem[],
  checkedPrivateNotesHtml: PrivateNoteHtml[],
  privateSpaceNotesChecked: string[],
}

export const generateTaskToSlackHTML = (taskObj: TaskItem) => `
  <p>${mapTaskStatusToEmoji(taskObj.data.status)}&nbsp${taskObj.data.title} ${taskObj.data.assignee.name && ` <b>|</b> @${taskObj.data.assignee.name}`} ${taskObj.date.dueDate.date.date && ` <b>|</b> Due <b>${DateToTemplateDateFormat(taskObj.date.dueDate.date.date)}</b> :calendar: `}</p>
`;

export const assembleSlackMessage = ({
  agendaChecked, sharedNotesChecked, personalNotesChecked,
  emailHeadTemplate, agendaHtml, sharedNotesHtml,
  personalNotesHtml, taskChecked,
  taskItems, title, startTime, checkedPrivateNotesHtml, privateSpaceNotesChecked,
}: assembleSlackMessageParamType) => {
  const slackMessageHeader = makeSlackHeader(
    emailHeadTemplate,
    [title, startTime],
  );
  const message: SlackMessage[] = [];
  if (agendaChecked && !isEmptyHTML(agendaHtml)) {
    message.push({ title: 'Agenda', content: agendaHtml });
  }
  if (sharedNotesChecked && !isEmptyHTML(sharedNotesHtml)) {
    message.push({ title: 'Shared notes', content: sharedNotesHtml });
  }
  if (personalNotesChecked && !isEmptyHTML(personalNotesHtml)) {
    message.push({ title: 'Sender\'s notes', content: personalNotesHtml });
  }

  if (privateSpaceNotesChecked.length !== 0) {
    checkedPrivateNotesHtml.forEach((privateNoteHtml) => {
      if (privateSpaceNotesChecked.includes(privateNoteHtml.noteId)) {
        message.push({ title: 'Private note', content: privateNoteHtml.contents });
      }
    });
  }

  if (taskChecked && taskItems.length !== 0) {
    let content = '';
    taskItems.forEach((taskObj: TaskItem) => {
      content += generateTaskToSlackHTML(taskObj);
    });
    content = `<ul>${content}</ul>`;
    message.push({ title: 'Tasks', content });
  }

  let html = '<div style="padding-left: 20px;" >';
  message.forEach((section: SlackMessage) => {
    html += `<br><b>${section.title}:</b><p>${section.content}</p><br>`;
  });
  html += '</div>';
  html = `<div>${slackMessageHeader.split('\n').join('<br>')}</div>${html}`;
  return html;
};

export const isValidSlackChannel = (defaultChannel: SlackChannel) => {
  if (!defaultChannel.id.length || !defaultChannel.name.length) return false;
  return true;
};

export const slackAuthUrl = 'https://slack.com/oauth/v2/authorize?client_id=1490440008293.2369381747799&scope=chat:write&user_scope=channels:read,im:read,chat:write,users:read,groups:read';

// TODO: Check if this function is correct
export const mapDatabaseSlackDataToSlackData = (databaseData: any): SlackData[] => {
  if (databaseData?.integrations?.slack?.length === 0 || typeof databaseData?.integrations?.slack === 'undefined') return [];
  const databaseSlackData = databaseData.integrations.slack[0] ?? {};

  const slackData: SlackData = {
    version: databaseSlackData?.version ?? 1,
    userAccessToken: databaseSlackData?.userAccessToken ?? '',
    botAccessToken: databaseSlackData?.botAccessToken ?? '',
    userId: databaseSlackData?.userId ?? '', //  TODO: What userId is this?
    defaultChannels: databaseSlackData?.defaultChannels ?? [],
    notifications: {
      meetingStartsSoon:
        databaseSlackData?.notifications?.meetingStartsSoon ?? false,
      mentionedInNotes:
        databaseSlackData?.notifications?.mentionedInNotes ?? false,
      taskOverdue: databaseSlackData?.notifications?.taskOverdue ?? false,
      taskCreated: databaseSlackData?.notifications?.taskCreated ?? false,
      taskUpdated: databaseSlackData?.notifications?.taskUpdated ?? false,
      taskDeleted: databaseSlackData?.notifications?.taskDeleted ?? false,
    },
    date: {
      created: databaseSlackData?.date?.created ?? dateISOObject(),
      updated: databaseSlackData?.date?.updated ?? dateISOObject(),
    },
  };
  return [slackData];
};

export const mapUserIdAccessTokenToSlackData = (
  userId: string,
  userAccessToken: string,
  botAccessToken: string,
) => {
  const slackData: SlackData = {
    version: 2,
    userAccessToken,
    botAccessToken,
    userId,
    defaultChannels: [{
      id: '',
      name: '',
    }],
    notifications: {
      meetingStartsSoon: true,
      mentionedInNotes: true,
      taskOverdue: true,
      taskCreated: true,
      taskUpdated: true,
      taskDeleted: true,
    },
    date: {
      created: dateISOObject(),
      updated: dateISOObject(),
    },
  };
  return slackData;
};

export const emptySlackData = () => {
  const slackData: SlackData[] = [{
    version: 2,
    userAccessToken: '',
    botAccessToken: '',
    userId: '',
    defaultChannels: [],
    notifications: {
      meetingStartsSoon: false,
      mentionedInNotes: false,
      taskOverdue: false,
      taskCreated: false,
      taskUpdated: false,
      taskDeleted: false,
    },
    date: {
      created: dateISOObject(),
      updated: dateISOObject(),
    },
  }];
  return slackData;
};

export const mapDefaultChannelToSlackData = (
  defaultChannel: SlackChannel[],
  previousData: SlackData[],
) => {
  const previousSlackData = previousData[0];

  const slackData: SlackData = {
    version: previousSlackData.version,
    userAccessToken: previousSlackData.userAccessToken,
    botAccessToken: previousSlackData.botAccessToken,
    userId: previousSlackData.userId,
    defaultChannels: defaultChannel,
    notifications: {
      // TODO Ivan: Can previousSLackData not contain some fields? Do we need the
      // ternary operator?
      meetingStartsSoon: previousSlackData.notifications.meetingStartsSoon ?? false,
      mentionedInNotes: previousSlackData.notifications.mentionedInNotes ?? false,
      taskOverdue: previousSlackData.notifications.taskOverdue ?? false,
      taskCreated: previousSlackData.notifications.taskCreated ?? false,
      taskUpdated: previousSlackData.notifications.taskUpdated ?? false,
      taskDeleted: previousSlackData.notifications.taskDeleted ?? false,
    },
    date: {
      created: previousSlackData.date.created,
      updated: previousSlackData.date.updated,
    },
  };
  return [slackData];
};

export const mapDatabaseNotificationsToSlackNotifications = (
  slackData: SlackData[],
): SlackNotifications => {
  const slackNotifications: SlackNotifications = {
    meetingStartsSoon: slackData[0]?.notifications?.meetingStartsSoon ?? false,
    mentionedInNotes: slackData[0]?.notifications?.mentionedInNotes ?? false,
    taskOverdue: slackData[0]?.notifications?.taskOverdue ?? false,
    taskCreated: slackData[0]?.notifications?.taskCreated ?? false,
    taskUpdated: slackData[0]?.notifications?.taskUpdated ?? false,
    taskDeleted: slackData[0]?.notifications?.taskDeleted ?? false,
  };
  return slackNotifications;
};

export const mapNotificationsToSlackData = (
  newNotifications: SlackNotifications,
  previousData: SlackData[],
) => {
  const previousSlackData = previousData[0];

  const slackData: SlackData = {
    version: previousSlackData.version,
    userAccessToken: previousSlackData.userAccessToken ?? '',
    botAccessToken: previousSlackData.botAccessToken ?? '',
    userId: previousSlackData.userId ?? '',
    defaultChannels: previousSlackData.defaultChannels ?? [],
    notifications: {
      // TODO Ivan: Can newNotification not contain some fields?
      meetingStartsSoon: newNotifications.meetingStartsSoon ?? false,
      mentionedInNotes: newNotifications.mentionedInNotes ?? false,
      taskOverdue: newNotifications.taskOverdue ?? false,
      taskCreated: newNotifications.taskCreated ?? false,
      taskUpdated: newNotifications.taskUpdated ?? false,
      taskDeleted: newNotifications.taskDeleted ?? false,
    },
    date: {
      created: previousSlackData.date.created,
      updated: previousSlackData.date.updated,
    },
  };
  return [slackData];
};

export const emptySlackUser = () => {
  const emptyUser: SlackUser = {
    name: '',
    iconUrl: '',
  };
  return emptyUser;
};

export const sortSlackChannelsAlphabetically = (
  slackChannels: SlackChannel[],
) => slackChannels.sort((channel1, channel2) => {
  if (channel1.name < channel2.name) return -1;
  if (channel1.name > channel2.name) return 1;
  return 0;
});

export const returnProperDueDate = (oldDueDate: string, newDueDate?: string): Date => {
  if (typeof newDueDate !== 'undefined' && newDueDate?.length !== 0) return new Date(newDueDate);
  return new Date(oldDueDate);
};

export const calculateIfSlackIsEnabled = (userData: User): boolean => {
  if (userData.integrations.slack.length === 0) return false;
  if (userData.integrations.slack[0].userAccessToken.length > 10) return true;
  return false;
};
