import React from 'react';
import styled from 'styled-components';
import { chunk, uniqueId } from 'lodash';
import { header500 } from '../typography';
import ReactTooltip from '../components/tooltip/ReactTooltip';
import ProfileIcon from './attendeeIcon';
import { AttendeeV2 } from '../types/types';
import { darkBlue1 } from '../colours';

const Icon = styled.button<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: ${(props) => `${props.size}px`};
  width: ${(props) => `${props.size}px`};
  border-radius: 50%;
  ${header500};
  background: ${darkBlue1};

  border: 2px solid #FFFFFF;
  font-size: 11px;
  line-height: 14px;
  z-index: ${(props) => (props.zIndex)};
  margin-left: -5px;
  margin-bottom: 1px;
  `;

interface ContainerProps {
  size: number,
  zIndex: number,
}

export interface Props {
  attendees: AttendeeV2[],
  onClick?: () => void,
  size?: number,
  zIndex?: number,
  noTooltip?: boolean,
}

const PlusIcon = ({
  attendees, onClick, size = 26, zIndex = 1, noTooltip,
}: Props) => {
  if (attendees.length === 0) return null;
  if (attendees.length === 1) {
    return (
      <ProfileIcon
        email={attendees[0].data.email}
        name={attendees[0].data.name}
        photoUrl={attendees[0].data.photoUrl}
        size={size === 26 ? 24 : size}
      />
    );
  }

  const tooltipText = () => {
    const chunks = chunk(attendees, 2);
    const tooltip = chunks.map((attendeesPair) => {
      if (attendeesPair.length === 2) {
        return (
          <div key={uniqueId()}>
            {`${attendeesPair[0].data.email}, ${attendeesPair[1].data.email}`}
            <br />
          </div>
        );
      }

      return (
        <div key={uniqueId()}>
          {attendeesPair[0].data.email}
        </div>
      );
    });

    return <>{tooltip}</>;
  };

  if (noTooltip) {
    return (
      <Icon onClick={onClick} size={size === 26 ? 24 : size} zIndex={zIndex}>
        +
        {attendees.length}
      </Icon>
    );
  }

  return (
    <ReactTooltip place="bottom" tip={tooltipText()}>
      <Icon onClick={onClick} size={size === 26 ? 24 : size} zIndex={zIndex}>
        +
        {attendees.length}
      </Icon>
    </ReactTooltip>
  );
};

PlusIcon.defaultProps = {
  onClick: () => { },
  zIndex: 1,
  noTooltip: false,
};

export default PlusIcon;
