import { cfSendTaskAssignNotificationEmail, cfSendTaskUpdateNotificationEmail } from '../../../database/cloudFunctionEmailAPI';
import {
  dbTaskUpdateAssignee, dbTaskUpdateDueDate, dbTaskUpdateStatus, dbTaskUpdateTitle,
} from '../../../database/firebaseTasksAPI';
import {
  EDIT_EVENT, TASKS_ASSIGNEE_FIELD, TASKS_DUE_DATE_FIELD, TASKS_STATUS_FIELD, TASKS_TITLE_FIELD,
} from '../../../utils/analytics/enums';
import {
  logTasksUserAction,
} from '../../../utils/analytics/eventLogger';
import {
  TASK_UPDATE_FIELD_DUE_DATE, TASK_UPDATE_FIELD_STATUS, TASK_UPDATE_FIELD_TITLE,
} from '../../../utils/enums';
import { toastDanger, toastInfo } from '../../../utils/notifications';
import { slackAPINotificationForTaskUpdate } from '../../../utils/slack/SlackAPI';
import {
  getStatusName, getTaskWithNewAssignee, getTaskWithNewDueDate,
  getTaskWithNewStatus, getTaskWithNewTitle,
} from '../../../utils/tasks/tasksUtils';
import { updateTrelloCard } from '../../../utils/trello/trelloUtils';
import { mapPublicUserDataV2ToAssignee } from '../../../utils/user/publivUserDataV2/PublicUserDataV2Utils';
import {
  User, TaskItem, TaskStatus, PublicUserDataV2, AuthState,
} from '../../types/types';

export const getDbUpdateDueDateHandler = (
  userData: User, oldTask: TaskItem, trackEvent: any, authState: AuthState,
) => (newDueDate: Date) => {
  dbTaskUpdateDueDate(oldTask.taskId, newDueDate);
  // dbUpdateTask(oldTask.taskId, {
  //   'date.dueDate.date': dateToSDateObject(newDueDate),
  //   'date.dueDate.type': 'date',
  // });
  updateTrelloCard(userData.integrations.trello, oldTask, 'single', 'due', newDueDate.toISOString());
  toastInfo('Updated', 'The due date was updated');
  slackAPINotificationForTaskUpdate(
    oldTask,
    authState,
    'due date',
    newDueDate.toDateString(),
  );
  logTasksUserAction(trackEvent, EDIT_EVENT, TASKS_DUE_DATE_FIELD);
  cfSendTaskUpdateNotificationEmail(
    getTaskWithNewDueDate(oldTask, newDueDate),
    userData,
    [TASK_UPDATE_FIELD_DUE_DATE],
  ).catch((error) => toastDanger('Failed to send notification', error));
};

export const getDbUpdateAssigneeHandler = (
  userData: User, oldTask: TaskItem, trackEvent: any, authState: AuthState,
) => (assignee: PublicUserDataV2) => {
  const newAssignee = mapPublicUserDataV2ToAssignee(assignee);
  slackAPINotificationForTaskUpdate(
    oldTask,
    authState,
    'assignee',
  );
  dbTaskUpdateAssignee(oldTask.taskId, newAssignee);
  if (newAssignee.userId.length !== 0) {
    toastInfo('Updated', `The assignee was updated to '${newAssignee.name} (${newAssignee.email})'`);
  } else {
    toastInfo('Updated', 'The assignee has been removed');
  }
  updateTrelloCard(userData.integrations.trello, oldTask, 'single', 'idMembers', newAssignee.name);
  logTasksUserAction(trackEvent, EDIT_EVENT, TASKS_ASSIGNEE_FIELD);
  cfSendTaskAssignNotificationEmail(getTaskWithNewAssignee(oldTask, newAssignee), userData)
    .catch((error) => toastDanger('Failed to send notification', error));
};

export const getDbUpdateTitleHandler = (
  userData: User, oldTask: TaskItem, trackEvent: any, authState: AuthState,
) => (newTitle: string) => {
  if (newTitle === oldTask.data.title) return;
  slackAPINotificationForTaskUpdate(
    oldTask,
    authState,
    'title',
    '',
    newTitle,
  );
  dbTaskUpdateTitle(oldTask.taskId, newTitle);
  updateTrelloCard(userData.integrations.trello, oldTask, 'single', 'name', newTitle);
  toastInfo('Updated', `The title was updated to '${newTitle}'`);
  logTasksUserAction(trackEvent, EDIT_EVENT, TASKS_TITLE_FIELD);
  cfSendTaskUpdateNotificationEmail(
    getTaskWithNewTitle(oldTask, newTitle),
    userData,
    [TASK_UPDATE_FIELD_TITLE],
  ).catch((error) => toastDanger('Failed to send notification', error));
};

export const getDbUpdateStatusHandler = (
  userData: User, oldTask: TaskItem, trackEvent: any, authState: AuthState,
) => (newStatus: TaskStatus) => {
  dbTaskUpdateStatus(oldTask.taskId, newStatus);
  slackAPINotificationForTaskUpdate(
    oldTask,
    authState,
    'status',
  );
  updateTrelloCard(userData.integrations.trello, oldTask, 'single', 'idList', newStatus);
  toastInfo('Updated', `The status was updated to '${getStatusName(newStatus)}'`);
  logTasksUserAction(trackEvent, EDIT_EVENT, TASKS_STATUS_FIELD);
  cfSendTaskUpdateNotificationEmail(
    getTaskWithNewStatus(oldTask, newStatus),
    userData,
    [TASK_UPDATE_FIELD_STATUS],
  ).catch((error) => toastDanger('Failed to send notification', error));
};
