import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { TrelloData } from '../../../../../../shared/types/types';
import SyncSettingTab from './SyncSettingTab';

interface Props {
  setTrelloData: Dispatch<SetStateAction<TrelloData>>,
  trelloData: TrelloData
}

export default function AutoSyncSettings({ setTrelloData, trelloData }:Props) {
  const [autoSyncChecked, setAutoSyncChecked] = useState<boolean>(true);
  const [allowOtherSyncChecked, setAllowOtherSyncChecked] = useState<boolean>(true);

  useEffect(() => {
    setAutoSyncChecked(trelloData.settings.isAutoSyncEnabled);
    setAllowOtherSyncChecked(trelloData.settings.isAllowOtherToSyncEnabled);
  }, [trelloData]);

  const handleIsAutoSyncEnabled = (isAutoSyncEnabled: boolean) => {
    setTrelloData((prev) => ({
      ...prev,
      settings: {
        ...prev.settings,
        isAutoSyncEnabled,
      },
    }));
  };

  const handleIsAllowOtherToSyncEnabled = (isAllowOtherToSyncEnabled: boolean) => {
    setTrelloData((prev) => ({
      ...prev,
      settings: {
        ...prev.settings,
        isAllowOtherToSyncEnabled,
      },
    }));
  };

  return (
    <>
      <SyncSettingTab
        settingLabel="Automatic sync"
        settingSubText="To have your tasks automatically reflect in Trello toggle on the button below. When you create or update the task's status, this will automatically update in Shepherd and in Trello."
        toggleText="Sync automatically to Trello"
        setChecked={handleIsAutoSyncEnabled as React.Dispatch<React.SetStateAction<boolean>>}
        checked={autoSyncChecked}
      />
      <SyncSettingTab
        settingLabel="Permissions"
        settingSubText="By toggling on the button below, you allow others in your team to export tasks where you are the assignee to Trello. Please note: If a task is set to private, you are the only one that can sync this to Trello."
        toggleText="Allow others to sync my Trello tasks for me"
        setChecked={
          handleIsAllowOtherToSyncEnabled as React.Dispatch<React.SetStateAction<boolean>>
        }
        checked={allowOtherSyncChecked}
      />
    </>
  );
}
