import React, {
  Dispatch, SetStateAction, useContext, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import * as Sentry from '@sentry/browser';
import { TaskItem, TrelloData, UserCenterPage } from '../../types/types';
import TaskDropdown from './TaskDropdown';
import TaskOperationButton from './TaskOperationButton';
import TaskOperationDropdownOptions from './TaskOperationDropdownOptions';
import TaskDeletionConfirmModal from './TaskDeletionConfirmModal';
import { dbCreateTask, dbUpdateTask } from '../../../database/firebaseTasksAPI';
import { AuthContext, UserDataContext } from '../../../App';
import { toastDanger, toastInfo } from '../../../utils/notifications';
import { cfSendTaskAssignNotificationEmail, cfSendTaskUpdateNotificationEmail } from '../../../database/cloudFunctionEmailAPI';
import TaskDetailsModal from '../task-modal/TaskDetailsModal';
import { logTasksUserAction } from '../../../utils/analytics/eventLogger';
import { getDuplicatedTaskWithNewUpdatedTime } from '../../../utils/tasks/tasksUtils';
import { updateTrelloCard } from '../../../utils/trello/trelloUtils';
import { EDIT_EVENT, TASKS_MODAL_FIELD } from '../../../utils/analytics/enums';
import { slackCoreAPISendNotificationForTaskUpdate } from '../../../utils/slack/SlackCoreAPI';

interface Props {
  taskItem: TaskItem,
  setSection?: Dispatch<SetStateAction<UserCenterPage>>
  disableCalendarSelect?: boolean,
  disableAssigneeSelect?: boolean,
  disableGoToMeeting?: boolean,
  enable?: boolean,
}

const TaskSettingsThreeDots = ({
  taskItem, disableAssigneeSelect, setSection,
  disableCalendarSelect, disableGoToMeeting, enable,
}: Props) => {
  const [isTaskDetailsOpen, setTaskDetailsOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [isDetailsDropdownOpen, setIsDetailsDropdownOpen] = useState<boolean>(false);
  const openDeleteModal = () => setDeleteModalOpen(true);
  const closeDeleteModal = () => setDeleteModalOpen(false);
  const userData = useContext(UserDataContext);
  const openTaskDetailsModal = () => setTaskDetailsOpen(true);
  const history = useHistory();
  const { trackEvent } = useIntercom();
  const authState = useContext(AuthContext);

  const handleDuplicateClick = () => {
    const duplicateTask = getDuplicatedTaskWithNewUpdatedTime(taskItem);
    dbCreateTask(duplicateTask)
      .then(() => {
        cfSendTaskAssignNotificationEmail(duplicateTask, userData)
          .catch((error) => toastDanger('Failed to send notification', error));
      });
  };

  const handleGoToMeetingClick = () => {
    const taskMeetingId = taskItem.meeting.meetingId;
    const currentMeetingId = window.location.pathname.split('/')[2];
    if (taskMeetingId === currentMeetingId) {
      toastInfo('Already in meeting', 'You are already in the meeting the task was made in');
      return;
    }
    console.log(`Redirecting to meeting ${taskMeetingId}`);
    if (setSection) {
      setSection('currentMeeting');
      history.push(`/meeting/${taskMeetingId}?meetingSection=task&taskSection=${taskItem.data.status}`);
      // TODO Asish: Add logging event here. Talk with Matthew how you want to structure the event
    }
  };

  const handleUpdateTaskItem = (
    newTaskItem: TaskItem,
    trelloData: TrelloData = userData.integrations.trello,
    isTrelloSyncChecked: boolean,
    callback: any,
    updatedSection: string,
  ) => {
    const newTaskItemToUpdate: TaskItem = newTaskItem;
    newTaskItemToUpdate.integrations.trello.isTrelloSyncEnabled = isTrelloSyncChecked;
    dbUpdateTask(taskItem.taskId, newTaskItemToUpdate, callback).then(() => {
      updateTrelloCard(trelloData, newTaskItemToUpdate as TaskItem, 'many', 'all');
      toastInfo('Updated', 'Task updated');
      logTasksUserAction(trackEvent, EDIT_EVENT, TASKS_MODAL_FIELD);
      cfSendTaskUpdateNotificationEmail(newTaskItem, userData)
        .catch((error) => toastDanger('Failed to send notification', error));
      slackCoreAPISendNotificationForTaskUpdate(
        newTaskItemToUpdate,
        authState,
        updatedSection,
      );
    }).catch((error) => {
      console.log(taskItem.taskId);
      console.log(taskItem);
      console.error(error);
      Sentry.captureException(error);
      toastDanger('Task Could Not Be Updated', error.message);
    });
  };

  if (!enable) return <></>;

  return (
    <>
      <TaskDropdown
        options={TaskOperationDropdownOptions(
          {
            onDeleteClick: openDeleteModal,
            onDuplicateClick: handleDuplicateClick,
            onTaskDetailsClick: openTaskDetailsModal,
            onGoToMeetingClick: handleGoToMeetingClick,
            disableGoToMeeting: disableGoToMeeting || taskItem.meeting.meetingId.length === 0,
          },
        )}
        willCloseAfterClick
        setIsOpen={setIsDetailsDropdownOpen}
      >
        <TaskOperationButton isOpen={isDetailsDropdownOpen} />
      </TaskDropdown>
      <TaskDetailsModal
        taskItem={taskItem}
        updateTaskItem={handleUpdateTaskItem}
        isOpen={isTaskDetailsOpen}
        setOpen={setTaskDetailsOpen}
        disableCalendarSelect={disableCalendarSelect}
        disableAssigneeSelect={disableAssigneeSelect}
        openDeleteModal={openDeleteModal}
      />
      <TaskDeletionConfirmModal
        isOpen={isDeleteModalOpen}
        closeModal={closeDeleteModal}
        taskItem={taskItem}
      />
    </>
  );
};

TaskSettingsThreeDots.defaultProps = {
  setSection: () => {},
  disableCalendarSelect: false,
  disableAssigneeSelect: false,
  disableGoToMeeting: false,
  enable: true,
};

export default TaskSettingsThreeDots;
