import Firepad from 'firepad';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/analytics';
import 'firebase/performance';
import * as Sentry from '@sentry/browser';
import { AGENDA, PERSONAL_NOTES, SHARED_NOTES } from './enums';
import { MeetingSection, TextEditor } from '../shared/types/types';

const firebaseConfig = {
  apiKey: 'AIzaSyCdYthlXdgBfDokonqdb0okI8xG3oLZtZc',
  authDomain: 'shepherd-mvp.firebaseapp.com',
  databaseURL: 'https://shepherd-mvp.firebaseio.com',
  projectId: 'shepherd-mvp',
  storageBucket: 'shepherd-mvp.appspot.com',
  messagingSenderId: '232311426352',
  appId: '1:232311426352:web:5fadb4ea0ddf3b72ac0cf5',
  measurementId: 'G-B4GK90WM5M',
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const {
  auth, functions, firestore, database, analytics, performance, storage,
} = firebase;

export default firebase;

// TODO some functions should be placed else where

export const getFirepadPath = (noteTab: MeetingSection, meetingId: string, userId?: string) => {
  switch (noteTab) {
    case AGENDA:
      return `/meetings/${meetingId}/agenda_Firepad`;
    case PERSONAL_NOTES:
      return `users/${userId}/meetings/${meetingId}/privateNotes_Firepad`;
    case SHARED_NOTES:
      return `/meetings/${meetingId}/sharedNotes_Firepad`;
    default:
      console.log('Unexpected behavior when getting firepad path');
      return '';
  }
};

export const getFirepadPathV2 = (
  noteTab: MeetingSection, meetingId: string, textEditor: TextEditor, userId?: string,
) => {
  const suffix = textEditor === 'firepad' ? '_Firepad' : '_Prosemirror';
  switch (noteTab) {
    case AGENDA:
      return `/meetings/${meetingId}/agenda${suffix}`;
    case PERSONAL_NOTES:
      return `users/${userId}/meetings/${meetingId}/privateNotes${suffix}`;
    case SHARED_NOTES:
      return `/meetings/${meetingId}/sharedNotes${suffix}`;
    default:
      console.log('Unexpected behavior when getting firepad path');
      return '';
  }
};

export const getFirepadSecretChatPath = (
  secretChatId: string, textEditor: TextEditor,
) => {
  const suffix = textEditor === 'firepad' ? '_Firepad' : '_Prosemirror';
  return `secretNotes/${secretChatId}${suffix}`;
};

export const cfSendMeetingNotes = (
  emails: { email: string }[], meetingTitle: string, html: string, templateId: string,
) => functions()
  .httpsCallable('sendMeetingNotes')({
    emails, meetingTitle, html, templateId,
  });

export const cfSendQuickFeedback = (
  email: string, feedback: string,
) => functions()
  .httpsCallable('sendQuickFeedback')({
    email, feedback,
  })
  .catch((error) => {
    Sentry.captureException(error);
    throw new Error(error);
  });

// The Promise result is an array of users data
export const cfGetAssigneeDataByEmail = (emails: string[]) => functions()
  .httpsCallable('searchUserIDAndNameByEmail')({ emails });

export const getDbRef = (path: string) => database().ref(path);

export const getStorageRef = (path: string) => storage().ref(path);

export const createHeadlessFirepad = (
  noteTab: MeetingSection, meetingId: string, userId?: string,
) => new Firepad.Headless(getDbRef(getFirepadPath(noteTab, meetingId, userId)));
