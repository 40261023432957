import { ensureNotUndefined } from '../../../utils/array';

export const getTabButtonStartingPosition = (buttons: HTMLElement[]): number => {
  const buttonIndex = buttons
    .findIndex((button) => button.dataset.isSelected === 'true');

  // if tab not found hide the indicator
  if (buttonIndex === -1) return 0;

  const firstButtonLeftOffset = buttons[0].offsetLeft;

  const startPosition = buttons.slice(0, buttonIndex)
    .map((button) => Number(
      ensureNotUndefined(window.getComputedStyle(button).marginRight.match(/\d+/))[0],
    ) + button.offsetWidth)
    .reduce((pre, cur) => pre + cur, 0) + firstButtonLeftOffset;

  return startPosition;
};

export const getTabButtonWidth = (buttons: HTMLElement[]): number => {
  const buttonIndex = buttons
    .findIndex((button) => button.dataset.isSelected === 'true');

  // if tab not found hide the indicator
  if (buttonIndex === -1) return 0;

  return buttons[buttonIndex].offsetWidth;
};
