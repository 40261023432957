import React, {
  Dispatch, SetStateAction, useContext, useEffect,
} from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../../App';
import ProseMirrorComponent from '../../../shared/components/prosemirror';
import { Note, PrivateNoteData, PrivateNoteHtml } from '../../../shared/types/types';
import {
  AGENDA, PERSONAL_NOTES, PRIVATE_NOTES, SHARED_NOTES,
} from '../../../utils/enums';
import { getFirepadPathV2, getFirepadSecretChatPath } from '../../../utils/firebase';
import MeetingDataContext from '../context/MeetingDataContext';
import ProseMirrorEditorViewContext from '../context/ProseMirrorEditorViewContext';

const EditorsContainer = styled.div`
  display: none;
`;

interface Props {
  setAgendaHtml: Dispatch<SetStateAction<Note>>
  setSharedNotesHtml: Dispatch<SetStateAction<Note>>
  setPersonalNotesHtml: Dispatch<SetStateAction<Note>>
  setPrivateNotesHtml: Dispatch<SetStateAction<PrivateNoteHtml[]>>
  privateNotes: PrivateNoteData[]
}

const FetchProseMirrorTextEditors = ({
  setAgendaHtml,
  setSharedNotesHtml,
  setPersonalNotesHtml,
  setPrivateNotesHtml,
  privateNotes,
}: Props) => {
  const { userId } = useContext(AuthContext);
  const { meetingId, version } = useContext(MeetingDataContext);
  const { page, editorView } = useContext(ProseMirrorEditorViewContext);

  const displayNotes = false;

  useEffect(() => {
    if (page === AGENDA && version <= 3) setAgendaHtml(editorView.dom?.innerHTML ?? '');
    if (page === SHARED_NOTES) setSharedNotesHtml(editorView.dom?.innerHTML ?? '');
    if (page === PERSONAL_NOTES) setPersonalNotesHtml(editorView.dom?.innerHTML ?? '');
  }, [page]);

  if (version >= 2) {
    return (
      <EditorsContainer>
        {page !== AGENDA && version <= 3 && (
          <ProseMirrorComponent
            path={getFirepadPathV2(AGENDA, meetingId, 'prosemirror', userId)}
            page={AGENDA}
            displayNotes={displayNotes}
            setMeetingNotes={setAgendaHtml}
          />
        )}
        {page !== SHARED_NOTES && (
          <ProseMirrorComponent
            path={getFirepadPathV2(SHARED_NOTES, meetingId, 'prosemirror', userId)}
            page={SHARED_NOTES}
            displayNotes={displayNotes}
            setMeetingNotes={setSharedNotesHtml}
          />
        )}
        {page !== PERSONAL_NOTES && (
          <ProseMirrorComponent
            path={getFirepadPathV2(PERSONAL_NOTES, meetingId, 'prosemirror', userId)}
            page={PERSONAL_NOTES}
            displayNotes={displayNotes}
            setMeetingNotes={setPersonalNotesHtml}
          />
        )}
        {
          privateNotes.map((note) => (
            <ProseMirrorComponent
              path={getFirepadSecretChatPath(note.chatId, 'prosemirror')}
              page={PRIVATE_NOTES}
              displayNotes={displayNotes}
              privateNoteData={note}
              setPrivateNotes={setPrivateNotesHtml}
            />
          ))
        }
      </EditorsContainer>
    );
  }

  return null;
};

export default FetchProseMirrorTextEditors;
