import React from 'react';
import styled from 'styled-components';
import ProfileIcon from '../../../../../shared/components/profile-icon';
import ReactTooltip from '../../../../../shared/components/tooltip/ReactTooltip';
import DeleteCrossIcon from '../../../../../shared/icons/DeleteCrossIcon';
import { AttendeeV2 } from '../../../../../shared/types/types';
import EmailAtom from './EmailAtom';
import { emailTagsColors, emailTextColors } from './secretModalUtils';

const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props: ContainerProps) => emailTagsColors[props.index]};
  border-radius: 16px;

  height: 22px;
  width: fit-content;
  padding: 3px;

  *> {
    :first-child {
      margin-right: 6px;
    }
  }
`;

const TagCrossIconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 34px;
`;

const DeleteIconContainer = styled.div`
  cursor: pointer;
`;

interface ContainerProps {
  index: number,
}

interface Props {
  attendee: AttendeeV2;
  index: number,
  /* eslint-disable no-unused-vars */
  handleRemove: (email: string) => void,
}

const IconEmailMolecule = ({ attendee, index, handleRemove }: Props) => (
  <TagCrossIconContainer>
    <Container index={index}>
      <ProfileIcon user={attendee} size={16} />
      <ReactTooltip tip={`${attendee.data.firstName} ${attendee.data.lastName} - ${attendee.data.email}`} place="top">
        <EmailAtom email={attendee.data.email} color={emailTextColors[index]} />
      </ReactTooltip>
    </Container>
    <DeleteIconContainer onClick={() => handleRemove(attendee.data.email)}>
      <DeleteCrossIcon />
    </DeleteIconContainer>
  </TagCrossIconContainer>
);

export default IconEmailMolecule;
