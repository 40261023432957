import { NodeSpec } from 'prosemirror-model';

const TimestampSpec: NodeSpec = {
  attrs: {
    time: {
      default: new Date(),
    },
  },
  defining: true,
  inline: true,
  isolating: true,
  content: 'text?',
  atom: true,
  group: 'inline',
  /**
   * Here an empty string of marks is given, since
   * it is not desirable to apply diverse and possibly
   * confusing styles to what should be a static
   * timestamp element.
   */
  marks: '',
  toDOM: (node) => [
    'span',
    {
      timestamp: node.attrs.time.toUTCString(),
      class: 'resolved-timestamp',
      contenteditable: 'false',
    },
    0,
  ],
  parseDOM: [{
    tag: 'span[timestamp]',
    getAttrs: (dom) => ({
      timestamp: (dom as Element).getAttribute('timestamp') ?? '',
    }),
  }],
};

export default TimestampSpec;
