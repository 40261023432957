import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import MeetingDetailsHeaderOrg from '../../shared/components/MeetingDetailsHeader/MeetingDetailsHeaderOrg';
import {
  AuthState, MeetingAnalyticsData, MeetingData, MeetingSection,
  Shortcut, TaskItem, MeetingVersion, TaskTab, UserCenterPage, PrivateNoteData,
  TemplateData,
} from '../../shared/types/types';
import MeetingAnalyticsDataContext from './context/MeetingAnalyticsDataContext';
import { darkBlue1, surface } from '../../shared/colours';
import TabsMenu from './tab/TabsMenu';
import TabBody from './tab/TabBody';
import FireworkConfetti from '../../shared/confetti/FireworkConfetti';
import TemplateTitleEditBar from './templates/components/TemplateTitleEditBar';

const HorizontalLine = styled.hr`
  margin: 0;
  border: 1px solid ${darkBlue1};
  background-color: ${darkBlue1};
`;

const Container = styled.div`
  background-color: ${surface};
  overflow-y: hidden;
`;

type Props = {
  meetingData: MeetingData,
  setSection: Dispatch<SetStateAction<UserCenterPage>>,
  taskTab: TaskTab,
  setTaskTab: Dispatch<SetStateAction<TaskTab>>,
  meetingAnalyticsData: MeetingAnalyticsData,
  attendeesLoading: boolean,
  calendarError: boolean,
  setMembersInvited: Dispatch<SetStateAction<boolean>>,
  // eslint-disable-next-line react/no-unused-prop-types
  closeTemplatesView: () => void
  unviewedTasksAssignedToMe: number,
  meetingDataVersion: MeetingVersion,
  authState: AuthState,
  meetingId: string,
  setNotesContent: Dispatch<SetStateAction<string>>
  setTabContentSynchronized: Dispatch<SetStateAction<boolean>>
  // eslint-disable-next-line no-unused-vars
  handleShortcutTrigger: (shortcut: Shortcut) => void
  membersInvited: boolean,
  taskItems: TaskItem[],
  meetingTab: MeetingSection,
  setMeetingTab: Dispatch<SetStateAction<MeetingSection>>,
  isTemplatesOpen: boolean,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>,
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  isRecurringMeeting: boolean,
  setProseMirrorEditorView: Dispatch<SetStateAction<any>>,
  setPrivateNoteOpen?: Dispatch<SetStateAction<boolean>>,
  privateNotes: PrivateNoteData[],
  // eslint-disable-next-line no-unused-vars
  onClickSelectPrivateNote: (chatId: string) => void,
  setPrivateNotes: Dispatch<SetStateAction<PrivateNoteData[]>>,
  activePrivateNoteId: string,
  isEditTemplateEnabled: boolean,
  setSelectedTemplateToEdit: Dispatch<SetStateAction<TemplateData>>,
  selectedTemplateToEdit:TemplateData
}

export default function MeetingPageTemplate({
  meetingData,
  setSection,
  taskTab,
  setTaskTab,
  meetingAnalyticsData,
  attendeesLoading,
  calendarError,
  setMembersInvited,
  closeTemplatesView,
  unviewedTasksAssignedToMe,
  meetingDataVersion,
  authState,
  meetingId,
  setNotesContent,
  setTabContentSynchronized,
  handleShortcutTrigger,
  membersInvited,
  taskItems,
  meetingTab,
  setMeetingTab,
  isTemplatesOpen,
  setIsTemplatesOpen,
  setCreateTaskModalOpen,
  isRecurringMeeting,
  setProseMirrorEditorView,
  privateNotes,
  setPrivateNotes,
  setPrivateNoteOpen,
  onClickSelectPrivateNote,
  activePrivateNoteId,
  isEditTemplateEnabled,
  setSelectedTemplateToEdit,
  selectedTemplateToEdit,
}: Props) {
  return (
    <MeetingAnalyticsDataContext.Provider value={meetingAnalyticsData}>
      <Container>
        <MeetingDetailsHeaderOrg
          meetingData={meetingData}
          isRecurringMeeting={isRecurringMeeting}
          attendeesLoading={attendeesLoading}
          calendarError={calendarError}
          setMembersInvited={setMembersInvited}
        />
        <HorizontalLine />
        {isEditTemplateEnabled
          ? (
            <TemplateTitleEditBar
              setSelectedTemplateToEdit={setSelectedTemplateToEdit}
              selectedTemplateToEdit={selectedTemplateToEdit}
            />
          )
          : (
            <TabsMenu
              meetingData={meetingData}
              meetingTab={meetingTab}
              setMeetingTab={setMeetingTab}
              closeTemplatesView={closeTemplatesView}
              unviewedTasks={unviewedTasksAssignedToMe}
              meetingDataVersion={meetingDataVersion}
              setPrivateNotes={setPrivateNotes}
              onClickSelectPrivateNote={onClickSelectPrivateNote}
              setPrivateNoteOpen={setPrivateNoteOpen}
              privateNotes={privateNotes}
              activePrivateNoteId={activePrivateNoteId}
            />
          )}
        <HorizontalLine />
        <TabBody
          tab={meetingTab}
          setSection={setSection}
          taskTab={taskTab}
          setTaskTab={setTaskTab}
          userId={authState.userId}
          meetingId={meetingId}
          setNotesContent={setNotesContent}
          setIsSynchronized={setTabContentSynchronized}
          taskItems={taskItems}
          setCreateTaskModalOpen={setCreateTaskModalOpen}
          isTemplatesOpen={isTemplatesOpen}
          setIsTemplatesOpen={setIsTemplatesOpen}
          setSelectedTemplateToEdit={setSelectedTemplateToEdit}
          onShortcutTrigger={handleShortcutTrigger}
          setProseMirrorEditorView={setProseMirrorEditorView}
          secretChatId={activePrivateNoteId}
        />
        {/* <ManageNotesBar /> */}
        <FireworkConfetti shouldTrigger={membersInvited} />
      </Container>
    </MeetingAnalyticsDataContext.Provider>
  );
}

MeetingPageTemplate.defaultProps = {
  setPrivateNoteOpen: null,
};
