import React, { Dispatch, SetStateAction, useState } from 'react';
import { AttendeeV2 } from '../../../types/types';
import ProfileIconsRow from '../../profile-icon/ProfileIconsRow';
import ExtraAttendeesPopup from '../../profile-icon/ExtraAttendeesPopup';
import InviteMembersToShepherdModal from '../InviteMembersToShepherd';

interface Props {
  nonShepherdAttendees: AttendeeV2[],
  numberOfIconsToShow: number,
  setMembersInvited: Dispatch<SetStateAction<boolean>>,
}

const NonShepherdAttendeesList = ({
  nonShepherdAttendees,
  numberOfIconsToShow,
  setMembersInvited,
}: Props) => {
  if (nonShepherdAttendees.length === 0) return null;

  const [extraAttendeesModalOpen, setExtraAttendeesModalOpen] = useState<boolean>(false);
  const [isInviteOpen, setIsInviteOpen] = useState<boolean>(false);
  const handleCloseExtraAttModal = () => {
    setExtraAttendeesModalOpen(false);
  };

  const handleOpenCloseExtraAttModal = () => {
    setExtraAttendeesModalOpen(true);
  };

  const handleInviteClick = () => {
    setIsInviteOpen(true);
    setExtraAttendeesModalOpen(false);
  };

  const nonShepherdAttendeesToShowProfileIcon = nonShepherdAttendees
    .slice(0, numberOfIconsToShow);
  const nonShepherdAttendeesExtra = nonShepherdAttendees.slice(numberOfIconsToShow);

  return (
    <>
      <div onMouseOver={handleOpenCloseExtraAttModal} onFocus={handleOpenCloseExtraAttModal}>
        <ProfileIconsRow
          profileIconAttendees={nonShepherdAttendeesToShowProfileIcon}
          extraAttendees={nonShepherdAttendeesExtra}
        />
      </div>
      <ExtraAttendeesPopup
        isOpen={extraAttendeesModalOpen}
        attendees={nonShepherdAttendees}
        handleClose={handleCloseExtraAttModal}
        onInviteClick={handleInviteClick}
      />
      <InviteMembersToShepherdModal
        isOpen={isInviteOpen}
        setIsOpen={setIsInviteOpen}
        attendees={nonShepherdAttendees}
        setMembersInvited={setMembersInvited}
      />
    </>
  );
};

export default NonShepherdAttendeesList;
