import React, { HTMLProps } from 'react';
import styled from 'styled-components';
import { darkBlue1 } from '../../../shared/colours';
import CalendarIcon from '../../../shared/icons/CalendarIcon';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  min-width: 44px;
  border-radius: 10px;
  border: 2px solid ${darkBlue1};
  cursor: pointer;
`;

const DateFilterButtonAtom = ({ onClick }: HTMLProps<HTMLElement>) => (
  <Container onClick={onClick}>
    <CalendarIcon />
  </Container>
);

export default DateFilterButtonAtom;
