import React, { Dispatch, SetStateAction } from 'react';
import CreateTask from '../../../shared/components/create-task';
import PreviousMeetingButtonMol from '../../../shared/components/previous-meeting-notes/components/PreviousMeetingButtonMol';
import Templates from '../../../shared/components/templates';
import {
  MeetingData, MeetingSection, PrivateNoteData, TaskItem, UserCenterPage,
} from '../../../shared/types/types';
import ShareMeetingNotes from '../../meeting/share-meeting';
import BackgroundColorContainerAtom from './BackgroundColorContainerAtom';
// import ButtonMolecule from './ButtonMolecule';
import ContainerAtom from './ContainerAtom';

interface Props {
  isEditTemplateBottomBarEnabled: boolean
  meetingData: MeetingData,
  section: UserCenterPage,
  taskItems: TaskItem[],
  meetingTab: MeetingSection,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>,
  isCreateTaskModalOpen: boolean,
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  setIsRecurringMeeting: Dispatch<SetStateAction<boolean>>,
  privateNotes: PrivateNoteData[],
}

const BottomBar = ({
  meetingData,
  section,
  taskItems,
  meetingTab,
  setIsTemplatesOpen,
  isCreateTaskModalOpen,
  setCreateTaskModalOpen,
  setIsRecurringMeeting,
  privateNotes,
  isEditTemplateBottomBarEnabled,
}: Props) => {
  if (isEditTemplateBottomBarEnabled) return null;

  if (section === 'currentMeeting') {
    return (
      <BackgroundColorContainerAtom>
        <ContainerAtom>
          <PreviousMeetingButtonMol
            meetingData={meetingData}
            setIsRecurringMeeting={setIsRecurringMeeting}
          />
          <CreateTask
            meetingData={meetingData}
            isOpen={isCreateTaskModalOpen}
            setIsOpen={setCreateTaskModalOpen}
          />
          <Templates
            meetingData={meetingData}
            meetingTab={meetingTab}
            setIsTemplatesOpen={setIsTemplatesOpen}
          />
          <ShareMeetingNotes
            taskItems={taskItems}
            privateNotes={privateNotes}
            closeDropdown={() => { }}
          />
        </ContainerAtom>
      </BackgroundColorContainerAtom>
    );
  }
  return (
    <BackgroundColorContainerAtom>
      <ContainerAtom>
        <CreateTask
          meetingData={meetingData}
          isOpen={isCreateTaskModalOpen}
          setIsOpen={setCreateTaskModalOpen}
        />
      </ContainerAtom>
    </BackgroundColorContainerAtom>
  );
};

export default BottomBar;
