import React, { useState } from 'react';
import styled from 'styled-components';
import { gray1, surface } from '../../shared/colours';
import MeetingLoadingPage from '../../shared/components/loading/shepherd-logo-loading-page/MeetingLoadingPage';
import { AllNotesSection, GapiMeetingData, UserCenterPage } from '../../shared/types/types';
import { NOTES_LIST_ALL, USER_CENTER_NOTES } from '../../utils/enums';
import AllNotesHeaderMolecule from './AllNotesHeaderMolecule';
import NotesFilterBoardOrganism from './notes-filter-board/NotesFilterBoardOrganism';
import NotesListOrganism from './notes-list/NotesListOrganism';

const BackgroundColorContainer = styled.div`
  background-color: ${surface};
  height: 100%;
`;

const Container = styled.div`
  background-color: ${gray1};
  padding: 24px 16px 24px 16px;
  height: 100%;
  border-radius: 24px 24px 0 0;
`;

interface Props {
  section: UserCenterPage;
  notes: GapiMeetingData[];
  isLoading: boolean;
  // eslint-disable-next-line no-unused-vars
  onAction: (eventId: string, calendarId: string) => void,
}

const AllNotesTemplate = ({
  section, notes, isLoading, onAction,
}: Props) => {
  if (section !== USER_CENTER_NOTES) return null;

  const [notesSection, setNotesSection] = useState<AllNotesSection>(NOTES_LIST_ALL);

  // filter by keywords and date
  const filterNotes = (allNotes: any) => allNotes;

  const filteredNotes = filterNotes(notes);

  if (isLoading) {
    return <MeetingLoadingPage text="Fetching Meeting Data..." />;
  }

  return (
    <BackgroundColorContainer>
      <Container>
        <AllNotesHeaderMolecule />
        <NotesFilterBoardOrganism section={notesSection} setSection={setNotesSection} />
        <NotesListOrganism notes={filteredNotes} onAction={onAction} />
      </Container>
    </BackgroundColorContainer>
  );
};

export default AllNotesTemplate;
