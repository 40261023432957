import React from 'react';
import styled from 'styled-components';
import CssSpinner from '../css-spinner';
import {
  gray1, gray10, gray8, blue1, blue7, darkBlue3, darkBlue1, darkBlue6, surface,
} from '../../colours';
import { uiTextMedium } from '../../typography';

interface StyleProps {
  'data-icon-only': boolean | undefined
  'data-left-icon': boolean | undefined
  'data-right-icon': boolean | undefined
  'data-outline': boolean | undefined
}

export const ButtonStyle = styled.button`
  ${uiTextMedium};

  align-items: center;
  display: flex;
  background: ${(props: StyleProps) => (props['data-outline'] ? gray1 : gray10)};
  border: ${(props: StyleProps) => (props['data-outline'] ? `2px solid ${darkBlue1}` : 'none')};
  border-radius: 8px;
  color: ${(props: StyleProps) => (props['data-outline'] ? surface : gray1)};
  cursor: pointer;
  height: 32px;
  justify-content: center;
  padding: ${(props: StyleProps) => (props['data-icon-only'] ? '4px' : '4px 16px')};

  &:hover {
    background: ${gray8};
    color: ${gray1};
    border-color: ${(props: StyleProps) => (props['data-outline'] && 'transparent')};

    path {
      fill: ${gray1};
    }
  }

  &:disabled {
    background: rgba(9, 30, 66, 0.04);
    color: ${darkBlue3};
    cursor: unset;

    path {
      fill: ${darkBlue3};
    }
  }

  &:not(:disabled) {
    &:focus {
      background: ${darkBlue6};
      color: ${gray1};

      path {
        fill: ${gray1};
      }
    }

    &:active {
      background: ${blue1};
      color: ${blue7};

      path {
        fill: ${blue7};
      }
    }
  }

  svg {
    margin-right: ${(props: StyleProps) => (props['data-left-icon'] ? '4px' : 0)};
    margin-left: ${(props: StyleProps) => (props['data-right-icon'] ? '4px' : 0)};
  }
`;

export interface ButtonSmallProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  isDisabled?: boolean,
  text: string,
  type?: 'submit' | 'reset' | 'button',
  hasLeftIcon?: boolean,
  hasRightIcon?: boolean,
  Icon?: any,
  loading?: boolean,
  isOutline?: boolean,
}

const ButtonSmall = ({
  onClick, isDisabled, text, type, Icon, hasLeftIcon, hasRightIcon, loading, isOutline,
}: ButtonSmallProps) => (
  <ButtonStyle
    data-icon-only={(!hasRightIcon && !hasLeftIcon && Icon !== undefined) || loading}
    data-left-icon={hasLeftIcon}
    data-right-icon={hasRightIcon}
    data-outline={isOutline}
    onClick={onClick}
    disabled={isDisabled || loading}
    type={type}
  >
    {loading ? (
      <CssSpinner />
    ) : (
      <>
        {hasLeftIcon && <Icon />}
        {text}
        {!hasRightIcon && !hasLeftIcon && Icon !== undefined && <Icon />}
        {hasRightIcon && <Icon />}
      </>
    )}
  </ButtonStyle>
);

ButtonSmall.defaultProps = {
  onClick: undefined,
  type: 'button',
  isDisabled: false,
  Icon: undefined,
  hasLeftIcon: false,
  hasRightIcon: false,
  loading: false,
  isOutline: false,
};

export default ButtonSmall;
