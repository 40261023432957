import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import { useIntercom } from 'react-use-intercom';
import styled from 'styled-components';
import { AuthContext } from '../../../../App';
import { dbDeleteUserTemplate } from '../../../../database/firebaseTemplatesAPI';
import { error6 } from '../../../../shared/colours';
import ButtonSmall from '../../../../shared/components/button-small';
import ModalFooterdWrapper, { ModalFooterRightButtonsWrapper } from '../../../../shared/components/Modal/ModalFooterWrapper';
import { editTemplate, mapDatabaseTemplateDataToTemplateData, prepareDatabaseTemplateData } from '../../../../shared/components/templates-modal/utils';
import {
  DatabaseTemplateData, MeetingSection, TemplateData, TemplateVersion,
} from '../../../../shared/types/types';
import { uiTextMedium } from '../../../../shared/typography';
import { DELETE_EVENT, TEMPLATES_NOTE_TEMPLATE_FIELD } from '../../../../utils/analytics/enums';
import { logTemplateUserAction } from '../../../../utils/analytics/eventLogger';
import { createHeadlessFirepad } from '../../../../utils/firebase';
import MeetingDataContext from '../../context/MeetingDataContext';
import ProseMirrorEditorViewContext from '../../context/ProseMirrorEditorViewContext';

interface Props {
  isEnabled: boolean
  meetingTab: MeetingSection
  selectedTemplateToEdit:TemplateData
  setSelectedTemplateToEdit: Dispatch<SetStateAction<TemplateData>>,
}
const DeleteText = styled.div`
  ${uiTextMedium};
  color: ${error6};
  cursor: pointer;
`;

export default function TemplateEditBottomBar(
  {
    isEnabled, selectedTemplateToEdit, setSelectedTemplateToEdit, meetingTab,
  }:Props,
) {
  const [templateData, setTemplateData] = useState<TemplateData>(mapDatabaseTemplateDataToTemplateData('', ''));
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const authData = useContext(AuthContext);
  const meetingData = useContext(MeetingDataContext);
  const { editorView } = useContext(ProseMirrorEditorViewContext);

  const { trackEvent } = useIntercom();

  useEffect(() => {
    setTemplateData(selectedTemplateToEdit);
  }, [selectedTemplateToEdit]);

  const handleReset = () => {
    setSelectedTemplateToEdit(mapDatabaseTemplateDataToTemplateData('', ''));
  };

  const handleSaveClick = () => {
    if (meetingData.version === 2) {
      const headlessFirepad = createHeadlessFirepad(
        meetingTab,
        meetingData.meetingId,
        authData.userId,
      );
      headlessFirepad.getHtml((html: string) => {
        const newTemplateData = prepareDatabaseTemplateData(
          templateData.data.title, html, authData, meetingData,
        );
        handleUpdate(newTemplateData);
      });
    }

    if (meetingData.version === 3 || meetingData.version === 4) {
      const version:TemplateVersion = 2;
      const editorHtml = editorView?.dom?.innerHTML ?? '';
      const newTemplateData = prepareDatabaseTemplateData(
        templateData.data.title,
        editorHtml,
        authData,
        meetingData,
        version,
      );
      handleUpdate(newTemplateData);
    }
  };

  const handleUpdate = (newTemplateData: DatabaseTemplateData) => {
    editTemplate(authData.userId, selectedTemplateToEdit.templateId,
      newTemplateData, handleReset, setIsSubmitLoading);
  };

  const handleDeleteClick = () => {
    dbDeleteUserTemplate(authData.userId, templateData.templateId);
    logTemplateUserAction(trackEvent, DELETE_EVENT,
      TEMPLATES_NOTE_TEMPLATE_FIELD, meetingData.version);
    handleReset();
  };

  if (!isEnabled) return null;

  return (
    <div>
      <ModalFooterdWrapper>
        <DeleteText onClick={handleDeleteClick}>Delete</DeleteText>
        <ModalFooterRightButtonsWrapper>
          <ButtonSmall onClick={handleReset} text="Cancel" isOutline />
          <ButtonSmall onClick={handleSaveClick} text="Save Changes" loading={isSubmitLoading} />
        </ModalFooterRightButtonsWrapper>
      </ModalFooterdWrapper>
    </div>
  );
}
