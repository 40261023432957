import { Dispatch, SetStateAction } from 'react';
import { EmailRecipient } from '../../pages/meeting/modal/ShareNotesModal';
import { meetingPath } from '../../routes';
import {
  MeetingData, MeetingSection, Note, MeetingVersion, AttendeeV2,
} from '../../shared/types/types';
import { currentTimestamp } from '../currentDateNicelyFormatted';
import { createHeadlessFirepad } from '../firebase';
import {
  AGENDA, SHARED_NOTES, PERSONAL_NOTES, TASK,
} from '../enums';

export const getClosestMeeting = (meetings: MeetingData[]) => {
  let closestMeeting = meetings[0];
  const currentTs = currentTimestamp();
  meetings.forEach((candidateMeeting) => {
    if (timeFromNow(candidateMeeting, currentTs) === timeFromNow(closestMeeting, currentTs)) {
      if (timeFromNowSinceLastUpdate(candidateMeeting, currentTs)
        <= timeFromNowSinceLastUpdate(closestMeeting, currentTs)) {
        closestMeeting = candidateMeeting;
      }
    }
    if (timeFromNow(candidateMeeting, currentTs) < timeFromNow(closestMeeting, currentTs)) {
      closestMeeting = candidateMeeting;
    }
  });
  return closestMeeting;
};

export const makeMeetingUrl = (meetingId: string) => `${meetingPath}/${meetingId}`;

export const makeGoogleCalendarUrl = (eventId: string, calendarId: string) => `/google-calendar/${eventId}/${calendarId}`;

// 1630049400    -> 1630049400000
// 1622061799661 -> 1622061799661
export const getTsWithMilliseconds = (ts: number) => {
  if (!ts) return 0; // handle if ts is undefined
  return (ts.toString().length === 10 ? ts * 1000 : ts);
};

const timeFromNow = (meeting: MeetingData, currentTs: number) => {
  if (!meeting?.date?.start?.timestamp) {
    console.log('Meeting doesnt have start timestamp');
    console.log(meeting);
    return currentTs;
  }
  const meetingStartTimestamp = getTsWithMilliseconds(meeting.date.start.timestamp);
  return Math.abs(meetingStartTimestamp - currentTs);
};

const timeFromNowSinceLastUpdate = (meeting: MeetingData, currentTs: number) => {
  if (!meeting?.date?.lastUpdated?.timestamp) {
    console.log('Meeting doesnt have lastUpdated timestamp');
    console.log(meeting);
    return currentTs;
  }
  const meetingUpdateTimestamp = getTsWithMilliseconds(meeting.date.lastUpdated.timestamp);
  return Math.abs(meetingUpdateTimestamp - currentTs);
};

export const setNoteContent = (
  setNote: Dispatch<Note>,
  noteTab: MeetingSection,
  meetingId: string,
  userId: string,
) => {
  const headlessFirepad = createHeadlessFirepad(noteTab, meetingId, userId);
  console.log(headlessFirepad, noteTab, 'headleass pad');
  headlessFirepad.getHtml((html: string) => setNote(html));
};

export const mapAttendeesToEmailRecipients = (attendees: any[]) => {
  if (attendees.length === 0) return [];
  const list = attendees?.map((attendee, index) => {
    const val: EmailRecipient = {
      index,
      displayValue: attendee.email,
    };
    return val;
  }) ?? [];
  return list;
};

export const mapAttendeesToEmailRecipientsV2 = (attendees: AttendeeV2[]): EmailRecipient[] => {
  if (attendees.length === 0) return [];
  return attendees.map((attendee, index): EmailRecipient => ({
    index,
    displayValue: attendee.data.email,
  }));
};

export const mapMeetingSectionToText = (meetingSection: MeetingSection) => {
  if (meetingSection === AGENDA) {
    return 'Agenda';
  }
  if (meetingSection === SHARED_NOTES) {
    return 'Shared Notes';
  }
  if (meetingSection === PERSONAL_NOTES) {
    return 'My notes';
  }
  if (meetingSection === TASK) {
    return 'Tasks';
  }
  return '';
};

export const isValidMeetingSection = (meetingSection: string) => {
  switch (meetingSection) {
    case AGENDA:
    case SHARED_NOTES:
    case PERSONAL_NOTES:
    case TASK:
      return true;
    default:
      return false;
  }
};

export const setMeetingSectionCarefully = (
  meetingSection: MeetingSection,
  version: MeetingVersion,
  setMeetingTab: Dispatch<SetStateAction<MeetingSection>>,
) => {
  if (version >= 4 && meetingSection === AGENDA) {
    return setMeetingTab(SHARED_NOTES);
  }

  return setMeetingTab(meetingSection);
};
