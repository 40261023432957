import React, {
  Dispatch, SetStateAction, useContext, useRef,
} from 'react';
import styled from 'styled-components';
import {
  green6, darkBlue4, surface, red6,
} from '../../../../shared/colours';
import { uiText, uiTextMedium, header200 } from '../../../../shared/typography';
import ActiveTickIcon from '../../../../shared/icons/integrations/ActiveTickIcon';
import ThreeDotsIcon from '../../../../shared/icons/integrations/ThreeDotsIcon';
import OptionsDropdown, { OptionsDropdownItem } from './OptionsDropdown';
import useDetectOutsideClick from '../../../../utils/hook/detectOutsideClick';
import { slackAuthUrl } from '../../../../utils/slack/slackUtils';
import { AuthContext, UserDataContext } from '../../../../App';
import { slackAPIRemoveSlackIntegration } from '../../../../database/firebaseSlackAPI';
import NotionIntegrated from '../NotionModalComponents/NotionIntegrated';
import { IntegrationsTabView } from '../../../../shared/types/types';
import TrelloIntegrated from '../TrelloModalComponents/TrelloIntegrated';
import { SLACK_NOTIFICATIONS_VIEW, SLACK_SELECT_DEFAULT_CHANNEL_VIEW } from '../../../../utils/constants';
import { SlackIcon } from '../../../../shared/icons/integration-icons';

export const NotIntegratedText = styled.p`
  ${uiText};
  color: ${darkBlue4};
`;

export const IntegratedContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AppContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
`;
export const MenuActiveContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ActiveText = styled.h2`
  ${uiTextMedium};
  color: ${green6};
  margin-left: 4px;
  margin-right: 28px;
  cursor: pointer;
`;

export const MenuContainer = styled.div`
  cursor: pointer;
`;

export const NameText = styled.h2`
 ${header200};
 margin-left: 16px;
`;

export const Logo = styled.img`
  height: 32px;
  width: 32px;
`;

interface Props {
  isSlackIntegrated: boolean,
  isNotionIntegrated: boolean,
  setTabView: Dispatch<SetStateAction<IntegrationsTabView>>,
  isTrelloIntegrated: boolean,
}

function Integrated({
  isSlackIntegrated, isNotionIntegrated, setTabView, isTrelloIntegrated,
}: Props) {
  const integratedOptionsDropdownRef = useRef(null);
  const [isSlackDropdownActive,
    setIsSlackDropdownActive] = useDetectOutsideClick(integratedOptionsDropdownRef, false);

  const { userId } = useContext(AuthContext);
  const { integrations } = useContext(UserDataContext);

  const slackDropdownOptions: OptionsDropdownItem[] = [
    {
      id: 1, value: 'Switch Slack workspace', color: surface, onClick: () => window.open(slackAuthUrl),
    },
    {
      id: 2, value: 'Change default channel', color: surface, onClick: () => setTabView(SLACK_SELECT_DEFAULT_CHANNEL_VIEW),
    },
    {
      id: 3,
      value: 'Manage notifications',
      color: surface,
      onClick: () => handleManageNotifications(),
    },
    {
      id: 4, value: 'Disable', color: red6, onClick: () => handleDisableSlack(),
    },
  ];

  const handleDisableSlack = () => {
    slackAPIRemoveSlackIntegration(userId);
    setIsSlackDropdownActive(false);
  };

  const handleManageNotifications = () => {
    // TODO: Check if this is correct
    setTabView(SLACK_NOTIFICATIONS_VIEW);
    setIsSlackDropdownActive(false);
  };

  const handleThreeDotsClick = () => {
    setIsSlackDropdownActive(!isSlackDropdownActive);
  };

  if (!isSlackIntegrated && !isNotionIntegrated && !isTrelloIntegrated) {
    return (
      <NotIntegratedText>
        No apps integrated yet! Check out what’s available below.
      </NotIntegratedText>
    );
  }
  return (
    <>
      {isSlackIntegrated && (
        <IntegratedContainer>
          <AppContainer>
            <SlackIcon width="28" />
            <NameText>Slack (Beta)</NameText>
          </AppContainer>
          <MenuActiveContainer>
            <ActiveTickIcon />
            <ActiveText>Active</ActiveText>
            <MenuContainer>
              <ThreeDotsIcon onClick={handleThreeDotsClick} />
              <OptionsDropdown
                optionsRef={integratedOptionsDropdownRef}
                isOpen={isSlackDropdownActive}
                options={slackDropdownOptions}
              />
            </MenuContainer>
          </MenuActiveContainer>
        </IntegratedContainer>
      )}
      <NotionIntegrated
        enable={isNotionIntegrated}
        dropDownRef={integratedOptionsDropdownRef}
        userId={userId}
        notionWorkspace={integrations.notion}
      />
      <TrelloIntegrated
        enable={isTrelloIntegrated}
        dropDownRef={integratedOptionsDropdownRef}
        userId={userId}
        trelloBoard={integrations.trello}
      />
    </>
  );
}

export default Integrated;
