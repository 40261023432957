import React from 'react';
import { useIntercom } from 'react-use-intercom';
import CalendarScreen from '../../screens/CalendarScreen';
import ConnectIntegrations from '../../screens/connect-integrations';
import MeetScreen from '../../screens/MeetScreen';
import TasksScreen from '../../screens/TasksScreen';
import VideoIntroduction from '../../screens/video-introduction-screen';
import {
  CALENDAR_SCREEN, INTEGRATIONS_SCREEN, MEET_SCREEN, TASKS_SCREEN,
} from '../../utils/constants';
import {
  ONBOARDING_CALENDAR_PAGE, ONBOARDING_INTEGRATIONS_PAGE, ONBOARDING_MEET_PAGE,
  ONBOARDING_TASKS_PAGE, ONBOARDING_VIDEO_PAGE,
} from '../../../../../utils/analytics/enums';
import { WelcomeScreens } from '../../utils/types';
import logAnalytics from './utils/functions';

interface WelcomeScreensSwitcherProps {
  activeScreen: WelcomeScreens;
}

const WelcomeScreensSwitcher = ({
  activeScreen,
}: WelcomeScreensSwitcherProps) => {
  const { trackEvent } = useIntercom();

  if (activeScreen === CALENDAR_SCREEN) {
    logAnalytics(trackEvent, ONBOARDING_CALENDAR_PAGE);
    return (
      <CalendarScreen />
    );
  }

  if (activeScreen === MEET_SCREEN) {
    logAnalytics(trackEvent, ONBOARDING_MEET_PAGE);
    return (
      <MeetScreen />
    );
  }

  if (activeScreen === TASKS_SCREEN) {
    logAnalytics(trackEvent, ONBOARDING_TASKS_PAGE);
    return (
      <TasksScreen />
    );
  }

  if (activeScreen === INTEGRATIONS_SCREEN) {
    logAnalytics(trackEvent, ONBOARDING_INTEGRATIONS_PAGE);
    return (
      <ConnectIntegrations />
    );
  }

  logAnalytics(trackEvent, ONBOARDING_VIDEO_PAGE);
  return (
    <VideoIntroduction />
  );
};

export default WelcomeScreensSwitcher;
