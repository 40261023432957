import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useIntercom } from 'react-use-intercom';
import { AuthContext, UserDataContext } from '../../../App';
import CompanyInfoForm from './forms/CompanyInfoForm';
import ProfileInfoForm from './forms/ProfileInfoForm';
import { ErrorModalData, ErrorModalFunctions, PersonalOnboardingFormInitialValues } from './utils/constants';
import { PersonalOnboardingData } from './utils/types';
import { logOnboardingEvent, logPageEvent } from '../../../utils/analytics/eventLogger';
import { ONBOARDING_FORM_PAGE, SUBMIT_EVENT } from '../../../utils/analytics/enums';
import Header from '../../../shared/components/header';
import { dbUpdateUserDataWithOnboardingDataAndSendWelcomeEmail } from './utils/functions';
import { OnboardingWrapper } from './utils/styles';
import ErrorModal from '../../../shared/components/errors/error-modal';
import { HEADER_ONBOARDING_VIEW } from '../../../shared/components/header/utils/constants';
import { dashboardPath, onboardingWelcomePath } from '../../../routes';
import useFirstUserLoadEffect from '../../../utils/user/useFirstUserLoadEffect';
import ShepherdLogoLoadingPage from '../../../shared/components/loading/shepherd-logo-loading-page';

const PersonalOnboarding = () => {
  const userData = useContext(UserDataContext);
  const authState = useContext(AuthContext);
  const history = useHistory();
  const { trackEvent } = useIntercom();

  useFirstUserLoadEffect(() => {
    if (userData.data.hasOnboarded) {
      history.push(dashboardPath);
    }
  }, [userData]);

  const initialFormValues = {
    ...PersonalOnboardingFormInitialValues,
    firstName: authState.firstName ?? '',
    lastName: authState.lastName ?? '',
  } as PersonalOnboardingData;

  const [formValues, setFormValues] = useState<PersonalOnboardingData>(initialFormValues);
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);

  const handleGoToPreviousStep = (newData: PersonalOnboardingData) => {
    setFormValues((prev) => ({ ...prev, ...newData }));

    if (activeStep === 0) return;
    setActiveStep((prev) => (prev - 1));
  };

  const handleGoToNextStep = (newData: PersonalOnboardingData) => {
    setFormValues((prev) => ({ ...prev, ...newData }));

    if (activeStep === 2) return;
    setActiveStep((prev) => (prev + 1));
  };

  const logAnalytics = () => {
    logOnboardingEvent(trackEvent, ONBOARDING_FORM_PAGE, SUBMIT_EVENT);
  };

  const handleSubmit = async (onboardingValues: PersonalOnboardingData) => {
    const status = await dbUpdateUserDataWithOnboardingDataAndSendWelcomeEmail(
      authState,
      onboardingValues,
      setIsLoading,
      logAnalytics,
    );

    if (status !== 'resolved') {
      setError(true);
      return;
    }

    history.push(onboardingWelcomePath);
  };

  useEffect(() => {
    logPageEvent(trackEvent, ONBOARDING_FORM_PAGE);
  }, []);

  if (userData.resolvedState !== 'resolved') {
    return (
      <ShepherdLogoLoadingPage />
    );
  }

  return (
    <OnboardingWrapper>
      <Header view={HEADER_ONBOARDING_VIEW} />
      <OnboardingStep
        activeStep={activeStep}
        onNextPress={handleGoToNextStep}
        onPreviousPress={handleGoToPreviousStep}
        onSubmitPress={handleSubmit}
        formValues={formValues}
        isLoading={isLoading}
      />
      <ErrorModal isOpen={error} data={ErrorModalData} functions={ErrorModalFunctions} />
    </OnboardingWrapper>
  );
};

export default PersonalOnboarding;

interface OnboardingStepProps {
  activeStep: number;
  // eslint-disable-next-line no-unused-vars
  onNextPress: (newData: PersonalOnboardingData) => void;
  // eslint-disable-next-line no-unused-vars
  onPreviousPress: (newData: PersonalOnboardingData) => void;
  // eslint-disable-next-line no-unused-vars
  onSubmitPress: (newData: PersonalOnboardingData) => void;
  formValues: PersonalOnboardingData;
  isLoading: boolean;
}

const OnboardingStep = ({
  activeStep, onNextPress, onPreviousPress, onSubmitPress, formValues, isLoading,
}: OnboardingStepProps) => {
  if (activeStep === 0) {
    return (
      <ProfileInfoForm
        handleNextStep={onNextPress}
        formValues={formValues}
      />
    );
  }
  return (
    <CompanyInfoForm
      handlePreviousStep={onPreviousPress}
      handleOnSubmit={onSubmitPress}
      formValues={formValues}
      isLoading={isLoading}
    />
  );
};
