import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { NotionData, NotionPageData } from '../../../../shared/types/types';
import NotionMultiInput from './NotionMultiInput';
import PlatformShareCheckbox from '../PlatformShareCheckbox';

const SwitchGroup = styled.div`
  & > div:not(:last-of-type) {
    margin-bottom: 16px;
  }
  margin-bottom: 0px;
`;

export interface Props {
  isNotionEnabled: boolean,
  notionWorkspace: NotionData[],
  isChecked: boolean,
  setIsChecked: Dispatch<SetStateAction<boolean>>
  setPagesToReceive: Dispatch<SetStateAction<NotionPageData[]>>,
}

const ShareNotionCheckboxAndDropdown = ({
  isNotionEnabled, isChecked, setIsChecked, notionWorkspace, setPagesToReceive,
}: Props) => {
  if (!isNotionEnabled) return null;

  return (
    <>
      <SwitchGroup>
        <PlatformShareCheckbox platform="notion" isChecked={isChecked} setIsChecked={setIsChecked} />
        <NotionMultiInput
          workspace={notionWorkspace}
          isChecked={isChecked}
          setPagesToReceive={setPagesToReceive}
        />
      </SwitchGroup>
    </>
  );
};

export default ShareNotionCheckboxAndDropdown;
