import { MenuItemSpec } from 'prosemirror-menu';
import { MarkSpec, MarkType, Schema } from 'prosemirror-model';
import {
  surface, gray8, gray7, gray6, gray4, gray2, blue6, yellow6,
  cyan6, purple6, green6, red6, blue3, yellow3, cyan3, purple3, green3,
  red3, blue7, yellow7, cyan7, purple7, green7, red7, gray1,
} from '../../../../colours';

function hexToRGB(h: string) {
  let r = 0;
  let g = 0;
  let b = 0;

  // 3 digits
  if (h.length === 4) {
    r = parseInt(`${h[1]}${h[1]}`, 16);
    g = parseInt(`${h[2]}${h[2]}`, 16);
    b = parseInt(`${h[3]}${h[3]}`, 16);

  // 6 digits
  } else if (h.length === 7) {
    r = parseInt(`${h[1]}${h[2]}`, 16);
    g = parseInt(`${h[3]}${h[4]}`, 16);
    b = parseInt(`${h[5]}${h[6]}`, 16);
  }

  return [r, g, b];
}

function createHighlightMark(color: string, transparent?: boolean): MarkSpec {
  const [r, g, b] = hexToRGB(color);

  const transparency = transparent ? '0' : '0.2';

  return {
    attrs: {
      style: {
        default: `background-color: rgba(${r}, ${g}, ${b}, ${transparency});`,
      },
      rawColor: {
        default: color,
      },
    },
    group: 'shepherd-custom-highlights',
    excludes: 'shepherd-custom-highlights',
    parseDOM: [
      {
        attrs: { style: `background-color: rgba(${r}, ${g}, ${b}, ${transparency});` },
      },
    ],
    toDOM(node) {
      return ['span', { style: node.attrs.style }, 0];
    },
  };
}

export interface Color {
  value: string;
  name?: string;
  transparent?: boolean;
}

export function buildHighlightMarks(...colors: Color[]): Record<string, MarkSpec> {
  const marks: Record<string, MarkSpec> = {};

  colors.forEach((c: Color) => {
    Object.defineProperty(marks, c.name || c.value, {
      value: createHighlightMark(c.value, c.transparent),
      writable: false,
      enumerable: true,
    });
  });

  return marks;
}

export function
buildHighlightMenuItems(schema: Schema, ...colors: Color[]): [MarkType, Partial<MenuItemSpec>][] {
  return colors.map((c) => [
    schema.marks[c.name || c.value],
    { title: c.name || c.value, label: c.name || c.value },
  ]);
}

export const defaultHighlights = [
  { name: 'empty-highlight', value: gray1, transparent: true },
  { name: 'surface-highlight', value: surface },
  { name: 'gray8-highlight', value: gray8 },
  { name: 'gray7-highlight', value: gray7 },
  { name: 'gray6-highlight', value: gray6 },
  { name: 'gray4-highlight', value: gray4 },
  { name: 'gray2-highlight', value: gray2 },
  { name: 'blue6-highlight', value: blue6 },
  { name: 'yellow6-highlight', value: yellow6 },
  { name: 'cyan6-highlight', value: cyan6 },
  { name: 'purple6-highlight', value: purple6 },
  { name: 'green6-highlight', value: green6 },
  { name: 'red6-highlight', value: red6 },
  { name: 'blue3-highlight', value: blue3 },
  { name: 'yellow3-highlight', value: yellow3 },
  { name: 'cyan3-highlight', value: cyan3 },
  { name: 'purple3-highlight', value: purple3 },
  { name: 'green3-highlight', value: green3 },
  { name: 'red3-highlight', value: red3 },
  { name: 'blue7-highlight', value: blue7 },
  { name: 'yellow7-highlight', value: yellow7 },
  { name: 'cyan7-highlight', value: cyan7 },
  { name: 'purple7-highlight', value: purple7 },
  { name: 'green7-highlight', value: green7 },
  { name: 'red7-highlight', value: red7 },
];
