import {
  blue6, cyan6, green6, purple6, red6,
} from '../shared/colours';

// ======= AUTH STATE ========
export const LOGGED_IN_STATE = 'loggedIn';
export const LOGGED_OUT_STATE = 'loggedOut';
export const UNRESOLVED_STATE = 'unresolved';

// ======= MEETING SECTION TYPES  ========
export const AGENDA = 'agenda';
export const SHARED_NOTES = 'shared';
export const PERSONAL_NOTES = 'private';
export const TASK = 'task';
export const PRIVATE_NOTES = 'secret';

// ======= TASK PAGES  ========
export const ALL_TASKS_PAGE = 'allTasks';
export const MEETING_PAGE = 'meeting';

// ======= TASK TABS  ========
export const TODO = 'todo';
export const IN_PROGRESS = 'inProgress';
export const COMPLETED = 'completed';

// ======= USER CENTER SECTION TYPES  ========
export const USER_CENTER_TASKS = 'tasks';
export const USER_CENTER_MEETINGS = 'meetings';
export const USER_CENTER_CURRENT_MEETING = 'currentMeeting';
export const USER_CENTER_TAGS = 'tags';
export const USER_CENTER_NOTES = 'notes';

// ======= WINDOW MESSAGE TYPES  ========
export const SIDEBAR_STATE = 'SIDEBARE_STATE';
export const REDIRECT_ROUTE = 'REDIRECT_ROUTE';
export const BUTTON_EVENT = 'BUTTON_EVENT';
export const PREVIOUS_MEETING_STATE = 'PREVIOUS_MEETING_STATE';

// ======= DEFAULT SECTION  ========
export const DEFAULT_SECTION = SHARED_NOTES;

// ======= TASK NOTIFICATION TYPES  ========
export const TASK_NOTIFICATION_ASSIGN = 'assign';
export const TASK_NOTIFICATION_DELETE = 'delete';
export const TASK_NOTIFICATION_UPDATE = 'update';

// ======= TASK UPDATE FIELDS  ========
export const TASK_UPDATE_FIELD_TITLE = 'title';
export const TASK_UPDATE_FIELD_DUE_DATE = 'dueDate';
export const TASK_UPDATE_FIELD_STATUS = 'status';
export const TASK_UPDATE_FIELD_DESCRIPTION = 'description';

// ======= ALL NOTES SECTION  ========
export const NOTES_LIST_ALL = 'allNotes';
export const NOTES_LIST_MEETING = 'meetingNotes';
export const NOTES_LIST_QUICK = 'quickNotes';

// ============ Leaf Hex Colors ===============

export const PURPLE = purple6;
export const GREEN = green6;
export const CYAN = cyan6;
export const RED = red6;
export const BLUE = blue6;
