import React, {
  useState, createContext, useContext, useEffect,
} from 'react';
import { useIntercom } from 'react-use-intercom';
import SendIcon from '../../../shared/icons/send';
import ShareNotesModal from '../modal/ShareNotesModal';
import { logEventButtonClickSendEmail, logEventButtonClickSendEmailCancel } from '../../../utils/analytics/eventLogger';
import PreviewEmailModal from '../modal/PreviewEmailModal';
import { defaultEmailHeadTemplate, populateEmailHeadTemplate } from '../../../utils/email/emailUtils';
import MeetingDataContext from '../context/MeetingDataContext';
import {
  Note, PrivateNoteData, PrivateNoteHtml, SlackChannel, TaskItem,
} from '../../../shared/types/types';
import ButtonMolecule from '../../all-pages/bottom-bar/ButtonMolecule';
import { toastSuccess } from '../../../utils/notifications';
import FetchProseMirrorTextEditors from '../modal/FetchProseMirrorTextEditors';
import RealisticConfetti from '../../../shared/confetti/RealisticConfetti';
import { getUTCString } from '../../../utils/dateUtils/date';

export const MeetingIdContext = createContext('');

export const MeetingTitleContext = createContext('...');

type Props = {
  privateNotes: PrivateNoteData[],
  taskItems: TaskItem[];
  closeDropdown: () => void;
}

const ShareMeetingNotes = ({ taskItems, privateNotes, closeDropdown }: Props) => {
  const [isShareNotesModalOpen, setShareNotesModalOpen] = useState(false);
  const [isPreviewEmailModalOpen, setPreviewEmailModalOpen] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [agendaChecked, setAgendaChecked] = useState<boolean>(false);
  const [sharedNotesChecked, setSharedNotesChecked] = useState<boolean>(false);
  const [personalNotesChecked, setPersonalNotesChecked] = useState<boolean>(false);
  const [taskChecked, setTaskChecked] = useState<boolean>(false);
  const [emailHeadTemplate, setEmailHeadTemplate] = useState<string>('');
  const [channelsToReceive, setChannelsToReceive] = useState<SlackChannel[]>([]);
  const [privateSpaceNotesChecked, setPrivateSpaceNotesChecked] = useState<string[]>([]);

  const [agendaHtml, setAgendaHtml] = useState<Note>('');
  const [sharedNotesHtml, setSharedNotesHtml] = useState<Note>('');
  const [personalNotesHtml, setPersonalNotesHtml] = useState<Note>('');
  const [privateNotesHtml, setPrivateNotesHtml] = useState<PrivateNoteHtml[]>([]);

  const { trackEvent } = useIntercom();
  const { data: { title }, date: { start } } = useContext(MeetingDataContext);

  const initialEmailHeadTemplate = populateEmailHeadTemplate(
    defaultEmailHeadTemplate, title, getUTCString(start.date),
  );

  useEffect(() => {
    setEmailHeadTemplate(initialEmailHeadTemplate);
  }, [initialEmailHeadTemplate]);

  const closeShareNotesModal = () => {
    setShareNotesModalOpen(false);
  };
  const closePreviewEmailModal = () => setPreviewEmailModalOpen(false);

  const handleShareButtonClick = () => {
    closeDropdown();
    setShareNotesModalOpen(true);
    logEventButtonClickSendEmail(trackEvent);
  };

  const handleCancel = () => {
    closeShareNotesModal();
    logEventButtonClickSendEmailCancel(trackEvent);
  };

  const handleEmailSent = (success: boolean) => {
    setIsEmailSent(success);
    if (success) toastSuccess('Successfully shared!', '');
    setShareNotesModalOpen(false);
  };

  const onBackClick = () => {
    closePreviewEmailModal();
    setShareNotesModalOpen(true);
  };

  const onPreviewClick = () => {
    closeShareNotesModal();
    setPreviewEmailModalOpen(true);
  };

  const handlePrivateSpaceChecked = (noteId: string) => {
    setPrivateSpaceNotesChecked((prev) => {
      if (prev.filter((ids) => ids === noteId).length === 0) return [...prev, noteId];
      return prev.filter((ids) => ids !== noteId);
    });
  };

  useEffect(() => {
    // After the an email is sent this const is used to triger the confetti fireworks,
    // after 5 seconds it resets to false
    if (isEmailSent) {
      setTimeout(() => {
        setIsEmailSent(false);
      }, 5000);
    }
  }, [isEmailSent]);

  return (
    <>
      <ButtonMolecule text="Send" icon={<SendIcon />} onClick={handleShareButtonClick} tooltipText="Share a summary of this meeting" />
      <ShareNotesModal
        handleEmailSent={handleEmailSent}
        isModalOpen={isShareNotesModalOpen}
        closeModal={closeShareNotesModal}
        handleCancel={handleCancel}
        onPreviewClick={onPreviewClick}
        setEmailHeadTemplate={setEmailHeadTemplate}
        emailHeadTemplate={emailHeadTemplate}
        agendaChecked={agendaChecked}
        sharedNotesChecked={sharedNotesChecked}
        personalNotesChecked={personalNotesChecked}
        taskChecked={taskChecked}
        setAgendaChecked={setAgendaChecked}
        setSharedNotesChecked={setSharedNotesChecked}
        setPersonalNotesChecked={setPersonalNotesChecked}
        setTaskChecked={setTaskChecked}
        taskItems={taskItems}
        agendaHtml={agendaHtml}
        sharedNotesHtml={sharedNotesHtml}
        personalNotesHtml={personalNotesHtml}
        setAgendaHtml={setAgendaHtml}
        setSharedNotesHtml={setSharedNotesHtml}
        setPersonalNotesHtml={setPersonalNotesHtml}
        channelsToReceive={channelsToReceive}
        setChannelsToReceive={setChannelsToReceive}
        privateNotes={privateNotes}
        privateSpaceNotesChecked={privateSpaceNotesChecked}
        handlePrivateSpaceChecked={handlePrivateSpaceChecked}
        privateNotesHtml={privateNotesHtml}
        setPrivateNotesHtml={setPrivateNotesHtml}
      />
      <RealisticConfetti isEmailSent={isEmailSent} />
      <PreviewEmailModal
        isModalOpen={isPreviewEmailModalOpen}
        onBackClick={onBackClick}
        emailHeadTemplate={emailHeadTemplate}
        agendaChecked={agendaChecked}
        sharedNotesChecked={sharedNotesChecked}
        personalNotesChecked={personalNotesChecked}
        taskChecked={taskChecked}
        taskItems={taskItems}
        agendaHtml={agendaHtml}
        sharedNotesHtml={sharedNotesHtml}
        personalNotesHtml={personalNotesHtml}
        setAgendaHtml={setAgendaHtml}
        setSharedNotesHtml={setSharedNotesHtml}
        setPersonalNotesHtml={setPersonalNotesHtml}
        privateNotesHtml={privateNotesHtml}
        privateSpaceNotesChecked={privateSpaceNotesChecked}
      />
      {isShareNotesModalOpen && (
        <FetchProseMirrorTextEditors
          setAgendaHtml={setAgendaHtml}
          setSharedNotesHtml={setSharedNotesHtml}
          setPersonalNotesHtml={setPersonalNotesHtml}
          setPrivateNotesHtml={setPrivateNotesHtml}
          privateNotes={privateNotes}
        />
      )}
    </>
  );
};

export default ShareMeetingNotes;
