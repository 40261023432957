import { emptyInviteData } from '../../shared/components/quick-settings/invite-members/utils/constants';
import {
  GoogleMeetingIds, IntercomTrackEvent, InviteData, InviterData, ResolvedState,
  DatabaseGapiMeetingData,
} from '../../shared/types/types';
import { INVITED_SIGN_UP, LOGIN_EVENT, SIGNUP_EVENT } from '../analytics/enums';
import { logAuthenticationEvent } from '../analytics/eventLogger';
import { functions } from '../firebase';

export const gapiInsertFirstMeetingSummary = () => {
  const lightningEmojiCode = String.fromCodePoint(0x26A1);
  const summary = `${lightningEmojiCode} Boom - You're All Set ${lightningEmojiCode}`;

  return summary;
};

export const gapiFormatTimeForFirstMeeting = () => {
  const startTime = new Date();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const endTime = new Date(startTime.getTime() + (60 * 60 * 1000)); // add 1 hour

  return { startTime: startTime.toISOString(), endTime: endTime.toISOString(), timeZone };
};

export const mapGapiInsertMeetingResponseToGoogleMeetingIdsObj = (gapiResponse: any) => {
  const GoogleMeetingIdsObj: GoogleMeetingIds = {
    eventId: gapiResponse.result?.id ?? '',
    calendarId: gapiResponse.result?.creator?.email ?? '',
    resolvedState: 'resolved',
  };

  return GoogleMeetingIdsObj;
};

export const mapDatabaseGapiMeetingDataToGapiMeetingIdsObj = (data: DatabaseGapiMeetingData) => {
  const GoogleMeetingIdsObj: GoogleMeetingIds = {
    eventId: data.id ?? '',
    calendarId: data.creator?.email ?? '',
    resolvedState: 'resolved',
  };

  return GoogleMeetingIdsObj;
};

export const RejectedGoogleMeetingIdsObj: GoogleMeetingIds = {
  eventId: '',
  calendarId: '',
  resolvedState: 'rejected',
};

export const PendingGoogleMeetingIdsObj: GoogleMeetingIds = {
  eventId: '',
  calendarId: '',
  resolvedState: 'pending',
};

export const trackLoginAnalytics = async (
  intercomTrackEvent: IntercomTrackEvent,
) => {
  logAuthenticationEvent(intercomTrackEvent, LOGIN_EVENT);
};

export const trackSignUpAnalytics = async (
  intercomTrackEvent: IntercomTrackEvent,
  signUpEmail: string,
) => {
  if (signUpEmail.length === 0) return;

  const { resolvedState, inviteId, invitedBy } = await cfGetInviteDataOfSignUpEmail(signUpEmail);

  if (resolvedState === 'rejected' || inviteId.length === 0) {
    logAuthenticationEvent(intercomTrackEvent, SIGNUP_EVENT);
    return;
  }

  logAuthenticationEvent(intercomTrackEvent, SIGNUP_EVENT, INVITED_SIGN_UP);
  cfUpdateInviteDataSignedUpStatusToTrue(inviteId);
  cfUpdateInviteeSignedUpStatusInUserDataToTrue(invitedBy, inviteId);
};

const cfGetInviteDataOfSignUpEmail = (email: string) => (
  functions()
    .httpsCallable('getInviteDataByEmail')({ email })
    .then((res) => {
      const { resolvedState, inviteData } = res.data;

      if (resolvedState === 'rejected') {
        console.log('error fetching invite data of signup email');
        return { resolvedState: 'rejected', ...emptyInviteData as InviteData };
      }

      console.log('successfully fetched invite data of signup email');
      return { resolvedState: 'resolved', ...inviteData as InviteData };
    })
    .catch((error) => {
      console.log('error fetching invite data of signup email', error);
      return { resolvedState: 'rejected', ...emptyInviteData as InviteData };
    })
);

const cfUpdateInviteDataSignedUpStatusToTrue = (inviteId: string) => (
  functions()
    .httpsCallable('updateInviteDataSignedUpStatusToTrue')({ inviteId })
    .then(() => {
      console.log('successfully updated signed up status of invite data');
      return 'resolved' as ResolvedState;
    })
    .catch((error) => {
      console.log('error updating signed up status of invite', error);
      return 'rejected' as ResolvedState;
    })
);

const cfUpdateInviteeSignedUpStatusInUserDataToTrue = (
  inviters: InviterData[],
  inviteId: string,
) => {
  if (inviters.length === 0) return;

  inviters.map((inviter: InviterData) => functions()
    .httpsCallable('updateInviteeSignedUpStatusInUserDataToTrue')({ inviterUserId: inviter.userId, inviteId })
    .then(() => {
      console.log('successfully updated invitee signed up status in user data');
      return 'resolved' as ResolvedState;
    })
    .catch((error) => {
      console.log('error updating invitee signed up status in user data', error);
      return 'rejected' as ResolvedState;
    }));
};

export const appendTextToDescriptionString = (description: string, agenda: string) => `${description}\n${agenda}`;
