/* eslint-disable no-unused-vars */
import React, {
  Dispatch, SetStateAction, useState,
} from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import ModalLine from '../../../../shared/components/Modal/ModalLine';
import { header200, header600, uiText } from '../../../../shared/typography';
import Integrated from './Integrated';
import Integrations from './Integrations';
import { darkBlue4 } from '../../../../shared/colours';
import SlackIconLarge from '../../../../shared/icons/integrations/SlackIconLarge';
import {
  IntegrationsTabView, SlackChannel, SlackNotifications, TrelloData,
} from '../../../../shared/types/types';
import SlackDefaultChannelsInputDropdown from './InputDropdown';
import SlackIntegrationProcessStatus from './SlackIntegrationProcessStatus';
import IntegrationSuccessModal from './IntegrationSuccessModal';
import TrelloSettings from '../TrelloModalComponents/TrelloSettings';
import SlackNotificationsOrganism from './SlackNotifications/SlackNotificationsOrganism';
import NotionIntegrationProcessStatus from './NotionIntegrationProcessStatus';
import TrelloIntegrationProcessStatus from './TrelloIntegrationProcessStatus';
import SlackSelectDefaultChannel from './SlackSelectDefaultChannel';
import {
  NOTION_PROCESSING_VIEW, OVERVIEW_VIEW, SLACK_NOTIFICATIONS_VIEW, SLACK_PROCESSING_VIEW,
  SLACK_SELECT_DEFAULT_CHANNEL_VIEW, TRELLO_PROCESSING_VIEW,
} from '../../../../utils/constants';
import SlackNotificationsModalCtrl from '../../../../shared/components/quick-settings/slack-notifications-modal/SlackNotificationsModalCtrl';

const EnabledHeader = styled.h2`
  ${header200};
`;

const IntegratedContainer = styled.div`
  margin-bottom: 24px;
`;

const NotEnabledContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

interface Props {
  isSlackEnabled: boolean,
  isNotionEnabled: boolean,
  isTrelloEnabled: boolean,
  view: IntegrationsTabView,
  setView: Dispatch<SetStateAction<IntegrationsTabView>>,
  setNewDefaultChannels: Dispatch<SetStateAction<SlackChannel[]>>,
  trelloData: TrelloData,
  setTrelloData: Dispatch<SetStateAction<TrelloData>>,
  slackChannels: SlackChannel[],
  setActiveConfetti: Dispatch<SetStateAction<boolean>>,
  setIsIntegratingSlack: Dispatch<SetStateAction<boolean>>,
  isIntegratingSlack: boolean,
}

const IntegrationsTab = ({
  isSlackEnabled, isNotionEnabled, isTrelloEnabled,
  view, setView, slackChannels, setNewDefaultChannels, trelloData,
  setTrelloData, setActiveConfetti, setIsIntegratingSlack, isIntegratingSlack,
}: Props) => {
  const isSlackNotificationModalOpen = view === SLACK_NOTIFICATIONS_VIEW;

  const onCloseSlackNotificationModal = () => {
    setIsIntegratingSlack(false);
    setView(OVERVIEW_VIEW);
  };

  const setDefaultSlackChannel = (channel: SlackChannel) => {
    setNewDefaultChannels([channel]);
  };

  if (view === OVERVIEW_VIEW) {
    return (
      <>
        <EnabledHeader>Enabled</EnabledHeader>
        <IntegratedContainer>
          <Integrated
            setTabView={setView}
            isSlackIntegrated={isSlackEnabled}
            isNotionIntegrated={isNotionEnabled}
            isTrelloIntegrated={isTrelloEnabled}
          />
        </IntegratedContainer>
        <ModalLine />
        <NotEnabledContainer>
          <Integrations
            isSlackEnabled={isSlackEnabled}
            isNotionIntegrated={isNotionEnabled}
            isTrelloIntegrated={isTrelloEnabled}
            setView={setView}
            setIsIntegratingSlack={setIsIntegratingSlack}
          />
        </NotEnabledContainer>
      </>
    );
  }
  if (view === SLACK_PROCESSING_VIEW) {
    return (
      <SlackIntegrationProcessStatus />
    );
  }
  if (view === SLACK_SELECT_DEFAULT_CHANNEL_VIEW) {
    return (
      <SlackSelectDefaultChannel
        slackChannels={slackChannels}
        setNewDefaultChannels={setNewDefaultChannels}
      />
    );
  }
  if (view === SLACK_NOTIFICATIONS_VIEW) {
    return (
      <SlackNotificationsModalCtrl
        isOpen={isSlackNotificationModalOpen}
        onClose={onCloseSlackNotificationModal}
        isIntegratingSlack={isIntegratingSlack}
      />
    );
  }
  if (view === NOTION_PROCESSING_VIEW) {
    return (
      <NotionIntegrationProcessStatus setActiveConfetti={setActiveConfetti} />
    );
  }
  if (view === TRELLO_PROCESSING_VIEW) {
    return (
      <TrelloIntegrationProcessStatus
        setTrelloData={setTrelloData}
        trelloData={trelloData}
      />
    );
  }
  return null;
};

export default IntegrationsTab;
