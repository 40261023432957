import styled from 'styled-components';
import {
  darkBlue1, gray1, gray9, layoutWhite,
} from '../../../../shared/colours';
import { uiTextMedium } from '../../../../shared/typography';

export const OnboardingWrapper = styled.div`
  width: 100%;
  height: 100%;

  background-color: ${gray1};
`;

export const FormContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  height: 100%;

  padding: 0px 32px;
`;

export const Title = styled.div`
  font-family: 'Inter', SF Pro Text;
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
`;

export const Subtitle = styled.div`
  font-family: 'Inter', SF Pro Text;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  margin-top: 25px;
`;

export const ProfileForm = styled.form`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;

  & > div:first-of-type {
    margin-top: 66px;
    margin-bottom: calc(30px - 8px);
  };
  & > div:last-of-type {
    margin-top: calc(30px - 8px);
    margin-bottom: 100px;
  };
`;

export const CompanyForm = styled.form`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;

  width: 100%;

  & > div:first-of-type {
    margin-top: 66px;
    margin-bottom: calc(30px - 8px);
  };
  & > div:nth-child(2) {
    margin-bottom: calc(30px - 8px);
  };
  & > div:nth-child(4) {
    margin-top: calc(30px - 8px);
    margin-bottom: 100px;
  };
`;

export const NavigationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  column-gap: 10px;

  width: 100%;
`;

export const BackButton = styled.button`
  all: unset;

  ${uiTextMedium};
  color: ${gray9};

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 45px;

  background-color: ${gray1};

  border-radius: 8px;
  border: 2px solid ${darkBlue1};

  &:hover {
    cursor: pointer;
    background-color: ${layoutWhite};
  }
`;
