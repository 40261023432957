import { useState, useEffect } from 'react';

// OBS OBS: You should be very careful when using this hook, as it
// typically introduces a lot of bugs
const useSkipEffect = (callback: any, deps: any[], skipTimes: number = 1) => {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    setCounter(counter + 1);
    if (counter >= skipTimes) {
      callback();
    }
  }, deps);
};

export default useSkipEffect;
