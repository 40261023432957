import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { small } from '../../../shared/typography';
import { surface } from '../../../shared/colours';

const Container = styled.div`
  ${small}
  color: ${surface};
  margin-bottom: 16px;
`;

interface Props {
  children: ReactNode;
}

const NotesTabInfoTextAtom = ({ children }: Props) => (
  <Container>
    {children}
  </Container>
);

export default NotesTabInfoTextAtom;
