import { SlackNotifications } from '../../shared/types/types';

const defaultSlackNotifications: SlackNotifications = {
  meetingStartsSoon: false,
  mentionedInNotes: false,
  taskOverdue: false,
  taskCreated: false,
  taskUpdated: false,
  taskDeleted: false,
};

export default defaultSlackNotifications;
