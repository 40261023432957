import { useField } from 'formik';
import React, {
  useRef, useEffect, Dispatch, SetStateAction, useContext,
} from 'react';
import styled from 'styled-components';
import {
  blue6, darkBlue2, gray1, gray2, gray7, surface,
} from '../../../../shared/colours';
import UpArrow from '../../../../shared/icons/upArrow';
import { link, uiText } from '../../../../shared/typography';
import useDetectOutsideClick from '../../../../utils/hook/detectOutsideClick';
import DownArrow from '../../../../shared/icons/downArrow';
import { AuthContext, UserDataContext } from '../../../../App';
import { SlackChannel } from '../../../../shared/types/types';
import Scrollbar from '../../../../shared/components/scrollbar';

const Trigger = styled.button`
  align-items: center; 
  background: ${gray2};
  border-radius: 8px;
  border: none;
  border: ${(props: MenuProps) => (props['data-active'] ? `2px solid ${blue6}` : `2px solid ${gray2}`)};
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: space-between;
  font-size: 16px;
  line-height: 24px;
  padding: 8px 12px;
  text-align: left;
  transition: box-shadow 0.4s ease;
  width: 409px; 

  &:hover {
    border: 2px solid ${darkBlue2};
  }
`;

const ValueText = styled.div`
  ${uiText};
  color: ${gray7};
`;

const Menu = styled.nav`
  background: ${gray1};
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  position: ${(props: MenuProps) => (props['data-active'] ? 'relative' : 'absolute')};
  top: 0px;
  left: 0;
  width: 100%;
  transition: opacity 0.4s ease, transform 1s ease, visibility 0.4s;

  opacity: ${(props: MenuProps) => (props['data-active'] ? 1 : 0)};
  visibility: ${(props: MenuProps) => (props['data-active'] ? 'visible' : 'hidden')};
  transform: ${(props: MenuProps) => (props['data-active'] ? 'translateY(0)' : 'translateY(130px)')};

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    button {
      ${link};
      border: none;
      background: none;
      padding: 6px 16px;
      color: ${surface};
      cursor: pointer;
      width: 100%;
      text-align: left;
      
      &:hover {
        background: ${gray2};
      }
    }
  }
`;

const Container = styled.div``;

interface MenuProps {
  'data-active': boolean
}

interface Props {
  name: string,
  placeholder: string,
  channels: SlackChannel[],
  setChannel: Dispatch<SetStateAction<SlackChannel>>,
}

// TODO: Use general component
const SlackDefaultChannelsInputDropdown = ({
  name, placeholder, channels, setChannel,
}: Props) => {
  const defaultChannelDropdown = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(defaultChannelDropdown, false);

  const [, meta, helpers] = useField(name);

  const authState = useContext(AuthContext);
  const userData = useContext(UserDataContext);

  const { value } = meta;
  const { setValue } = helpers;

  useEffect(() => {
    setValue(userData?.integrations?.slack[0]?.defaultChannels[0]?.name);
  }, [authState, channels]);

  const handleClick = (item: SlackChannel) => {
    setValue(item.name);
    setIsActive(false);
    setChannel(item);
  };

  const onClick = () => {
    setIsActive(!isActive);
  };

  return (
    <Container>
      <Trigger type="button" data-active={isActive} onClick={onClick}>
        <ValueText>{value || placeholder}</ValueText>
        {isActive ? <UpArrow /> : <DownArrow />}
      </Trigger>
      <Scrollbar maxHeight="29vh">
        <Menu
          data-active={isActive}
          ref={defaultChannelDropdown}
        >
          {isActive && (
            <ul>
              {channels.map((channel) => (
                <li key={channel?.id}>
                  <button type="button" onClick={() => handleClick(channel)}>{channel?.name}</button>
                </li>
              ))}
            </ul>
          )}
        </Menu>
      </Scrollbar>
    </Container>
  );
};

export default SlackDefaultChannelsInputDropdown;
