/* eslint-disable no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import { gray7, layoutWhite } from '../../../shared/colours';
import ProfileIconsRow from '../../../shared/components/profile-icon/ProfileIconsRow';
import { AttendeeV2, GapiMeetingData } from '../../../shared/types/types';
import NoteItemDateAtom from './NoteItemDateAtom';
import NoteItemTitleAtom from './NoteItemTitleAtom';
import NoteItemDropdownMolecule from './NoteItemDropdownMolecule';
import EditSquareIcon from '../../../shared/icons/editSquare';
import GoToMeetingAtom from './GoToMeetingAtom';
import ConsoleImproved from '../../../shared/classes/ConsoleImproved';

const Container = styled.div`
  padding: 10px 16px;
  background-color: ${layoutWhite};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 5px;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  & > *:not(:first-child) {
    margin-left: 8px;
  }
/* last child */
  & > *:last-child {
    margin-left: 20px;
  }
`;

const AttendeesContainer = styled.div`
  width: 72px;
`;

interface Props {
  note: GapiMeetingData
  onAction: (eventId: string, calendarId: string) => void,
  // title: string;
  // // attendees: AttendeeV2[];
  // attendees: any[];
  // // replace date type
  // date: any;
}

const NoteItemMolecule = ({ note, onAction }: Props) => {
  const numberOfDisplayAttendees = 2;
  // const displayAttendees = attendees.slice(0, numberOfDisplayAttendees);
  // const extraAttendees = attendees.slice(numberOfDisplayAttendees);

  const handleGoToMeeting = () => {
    ConsoleImproved.log('Clicked go to meeting', note);
    onAction(note.id, note.organizer.email);
  };

  return (
    <Container>
      <LeftContainer>
        <NoteItemTitleAtom text={note.summary} />
      </LeftContainer>
      <RightContainer>
        {/* <AttendeesContainer>
          <ProfileIconsRow
            profileIconAttendees={displayAttendees}
            extraAttendees={extraAttendees}
            size={22}
          />
        </AttendeesContainer> */}
        <NoteItemDateAtom date={note.start.dateTime} />
        {/* <NoteItemDropdownMolecule note={null} /> */}
        <GoToMeetingAtom onClick={handleGoToMeeting} />
      </RightContainer>
    </Container>
  );
};

export default NoteItemMolecule;
