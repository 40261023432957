import { MenuItemSpec } from 'prosemirror-menu';
import { MarkSpec, MarkType, Schema } from 'prosemirror-model';
import {
  blue3, blue6, blue7, cyan3, cyan6, cyan7, gray2, gray4, gray6, gray7,
  gray8, green3, green6, green7, purple3, purple6, purple7, red3, red6,
  red7, surface, yellow3, yellow6, yellow7,
} from '../../../../colours';

function createColorMark(color: string): MarkSpec {
  return {
    attrs: {
      style: {
        default: `color: ${color};`,
      },
      rawColor: {
        default: color,
      },
    },
    group: 'shepherd-custom-colors',
    excludes: 'shepherd-custom-colors',
    parseDOM: [
      { style: `color=${color}`, attrs: { style: `color: ${color}` } },
    ],
    toDOM(node) {
      return ['span', { style: node.attrs.style }, 0];
    },
  };
}

export interface Color {
  value: string;
  name?: string;
}

export function buildColorMarks(...colors: Color[]): Record<string, MarkSpec> {
  const marks: Record<string, MarkSpec> = {};

  colors.forEach((c: Color) => {
    Object.defineProperty(marks, c.name || c.value, {
      value: createColorMark(c.value),
      writable: false,
      enumerable: true,
    });
  });

  return marks;
}

export function
buildColorMenuItems(schema: Schema, ...colors: Color[]): [MarkType, Partial<MenuItemSpec>][] {
  return colors.map((c) => [
    schema.marks[c.name || c.value],
    { title: c.name || c.value, label: c.name || c.value },
  ]);
}

/**
 * If changing the font color variety is desired,
 * these values need to change.
 *
 * However, removing marks from here may cause
 * issues with prosemirror.
 *
 * The suggested approach would be marking
 * these colors with an additional field (possibly
 * a field called 'deprecated' of type boolean),
 * then filtering these colors in the menu, so the user
 * doesn't see them anymore, but they still are loaded
 * into the Schema.
 */
export const defaultColors = [
  {
    value: surface,
    name: 'surface',
  },
  {
    value: gray8,
    name: 'gray8',
  },
  {
    value: gray7,
    name: 'gray7',
  },
  {
    value: gray6,
    name: 'gray6',
  },
  {
    value: gray4,
    name: 'gray4',
  },
  {
    value: gray2,
    name: 'gray2',
  },
  {
    value: blue6,
    name: 'blue6',
  },
  {
    value: yellow6,
    name: 'yellow6',
  },
  {
    value: cyan6,
    name: 'cyan6',
  },
  {
    value: purple6,
    name: 'purple6',
  },
  {
    value: green6,
    name: 'green6',
  },
  {
    value: red6,
    name: 'red6',
  },
  {
    value: blue3,
    name: 'blue3',
  },
  {
    value: yellow3,
    name: 'yellow3',
  },
  {
    value: cyan3,
    name: 'cyan3',
  },
  {
    value: purple3,
    name: 'purple3',
  },
  {
    value: green3,
    name: 'green3',
  },
  {
    value: red3,
    name: 'red3',
  },
  {
    value: blue7,
    name: 'blue7',
  },
  {
    value: yellow7,
    name: 'yellow7',
  },
  {
    value: cyan7,
    name: 'cyan7',
  },
  {
    value: purple7,
    name: 'purple7',
  },
  {
    value: green7,
    name: 'green7',
  },
  {
    value: red7,
    name: 'red7',
  },
];
