/* eslint-disable */
/*
   This file is in JS purposely, dependencies were giving error on TS
   Logic here converts markdown to slack blocks (better way to for integration like shepherd to share notes)

   jsx-slack lib converts JSX to slack block JSON objects which can be sent in payload, here instead of 
   rendering the JSX we are grabbing the JSON objects and sending it as a JSON to slack apis
   DOC: https://github.com/yhatt/jsx-slack

   -- Conversion Overhead --
   HTML -> Markdown -> Slack Blocks

*/
import { jsxslack } from '@speee-js/jsx-slack';
import customMarkdown from './customMarkdownParser';

// Converts JSX which uses custom markdown formatting to slack block objects
 
const Markdown = ({ markdown }) => {
  let parsed = jsxslack.fragment([customMarkdown.render(markdown)]);
  if (!Array.isArray(parsed)) parsed = [parsed];

  let section = [];
  const blocks = [];

  for (const content of [...parsed, Symbol('end')]) {
    if (typeof content.type === 'string') {
      section.push(content);
    } else {
      if (section.length > 0) {
        const sec = jsxslack.fragment`<Section children=${section} />`;
        if (sec.props.text.text) blocks.push(sec);
      }
      section = [];

      if (typeof content !== 'symbol') blocks.push(content);
    }
  }

  return blocks;
};

const checkAndFixCharLimit = (blocks)=>{
  const fixedBlocks = []
  blocks.forEach((block) => {
    if (block.type === 'section' && block.text.text.length > 3000) {
      const blockTxtWithinLimit = block.text.text.match(/(.|[\r\n]){1,3000}/g);
      blockTxtWithinLimit.forEach((slicedText) => {
        const obj = {
          text: {
            text: slicedText,
            type: 'mrkdwn',
            verbatim: true,
          },
          type: 'section',
        };
        fixedBlocks.push(obj);
      });
    } else {
      fixedBlocks.push(block);
    }
  });
  return fixedBlocks
}

const mdToSlackBlocks = (md1) => {
  const blocks =checkAndFixCharLimit(jsxslack`<Blocks><${Markdown} markdown=${md1} /></Blocks>`)
  return blocks
};

export default mdToSlackBlocks;