// Shepherd Employee User Ids
// and other users we can show the
// about modal to
const KNOWN_USERS_USER_IDS = [
  'O4jbwuIWHPgQ9W2PJ1qihMDdXw83',
  'Ayh0RCja5YQj4NhBDyDuXcyB7Vn2',
  'yRdSSxHb7QTpsf5DqJ7KbQX0YfH2',
  'aRfTQTdMYma6fpC9DfLkjcv1OdU2',
  'qhC2OKtyISfpyKAAsgCbrHEEbRH3',
  'nS1cAVcJstb0eXmRciseVLjSqgv1',
  'sU4utl5KKNVafdbjrk2Btq18DIy2',
  'i9kDBZRgWNYB8ax4lZUGSoYoAOe2',
  'hmY5emSFdJXgD9P7BL9xla1lXMr2',
  'LbFmRzo6niXcFms4XmHVMtzCeNN2',
  'KkXdVQY9cygfCaBDfMrZkdxLqUb2',
  '0SqMX2J03qQKtQrSWx1UVFWPDBz2',
  'ydXu1byTKCW3SsU4TwtiqmBLHQC3',
  'T5CRtZU9Gvh1yTc8LFYWYlQp4ts2',
  '7xJNO9pZh6ccYFdcgHoA6V1EfK12',
  'd67OjUf5CjWGifRNou5uCF8j0Cm2',
];

export default KNOWN_USERS_USER_IDS;

export const isKnownUserUserId = (
  userId: string,
): boolean => KNOWN_USERS_USER_IDS.includes(userId);

// vashish888@gmail.com - O4jbwuIWHPgQ9W2PJ1qihMDdXw83 -
// matthewchen@g.ucla.edu Ayh0RCja5YQj4NhBDyDuXcyB7Vn2
// pamela@blott.io yRdSSxHb7QTpsf5DqJ7KbQX0YfH2
// shaokun.xie.fr@gmail.com aRfTQTdMYma6fpC9DfLkjcv1OdU2
// nadia.chaudry@placewise.com qhC2OKtyISfpyKAAsgCbrHEEbRH3
// gigi@batelle.com nS1cAVcJstb0eXmRciseVLjSqgv1
// bencyang1@gmail.com sU4utl5KKNVafdbjrk2Btq18DIy2
// jamesgotz@gmail.com i9kDBZRgWNYB8ax4lZUGSoYoAOe2
// jamie@meetshepherd.com hmY5emSFdJXgD9P7BL9xla1lXMr2
// harald@meetshepherd.com LbFmRzo6niXcFms4XmHVMtzCeNN2
// haraldlons@gmail.com KkXdVQY9cygfCaBDfMrZkdxLqUb2
// alexandru.garleanu@inventiff.co 0SqMX2J03qQKtQrSWx1UVFWPDBz2
// ivanmarinchev00@gmail.com ydXu1byTKCW3SsU4TwtiqmBLHQC3
// tomfilmer2@gmail.com, 'T5CRtZU9Gvh1yTc8LFYWYlQp4ts2',
// thomas@meetshepherd.com, 7xJNO9pZh6ccYFdcgHoA6V1EfK12
// ana@meetshepherd.com, d67OjUf5CjWGifRNou5uCF8j0Cm2
