import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import ReactSwitch from 'react-switch';
import ReactTooltip from '../tooltip/ReactTooltip';
import { gray6, blue6, gray1 } from '../../colours';
import { messageText } from '../../typography';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled.div`
  ${messageText};
`;

interface SwitchProps {
  label?: string,
  checked: boolean,
  setChecked: Dispatch<SetStateAction<boolean>>,
  disabled?: boolean,
  disabledTooltipText?: string,
}

const Switch = ({
  label, checked, setChecked, disabled, disabledTooltipText,
}: SwitchProps) => (
  <Wrapper>
    <Label>{label}</Label>
    <ReactTooltip place="left" tip={disabledTooltipText} disabled={!disabled}>
      <ReactSwitch
        onChange={setChecked}
        checked={!disabled && checked}
        uncheckedIcon={false}
        checkedIcon={false}
        height={22}
        width={44}
        boxShadow="0px 2px 4px rgba(0, 35, 11, 0.2)"
        activeBoxShadow="0px 2px 4px rgba(0, 35, 11, 0.2)"
        handleDiameter={18}
        onColor={blue6}
        onHandleColor={gray1}
        offColor={gray6}
        disabled={disabled}
      />
    </ReactTooltip>
  </Wrapper>
);

Switch.defaultProps = {
  label: '',
  disabled: false,
  disabledTooltipText: 'Disabled',
};

export default Switch;
