import React, { useState, useContext } from 'react';
import { useIntercom } from 'react-use-intercom';
import Modal from '../Modal';
import { AuthContext } from '../../../App';
import SaveTemplateModalContent from './components/SaveTemplateModalContent';
import { MeetingSection, TemplateVersion, DatabaseTemplateData } from '../../types/types';
import MeetingDataContext from '../../../pages/meeting/context/MeetingDataContext';
import { createHeadlessFirepad } from '../../../utils/firebase';
import {
  createTemplate, prepareDatabaseTemplateData,
} from './utils';
import { toastInfo } from '../../../utils/notifications';
import { logTemplateUserAction } from '../../../utils/analytics/eventLogger';
import ProseMirrorEditorViewContext from '../../../pages/meeting/context/ProseMirrorEditorViewContext';
import {
  CLOSE_EVENT,
  CREATE_EVENT,
  OPEN_EVENT,
  TEMPLATES_BROWSE_MY_TEMPLATES_BUTTON_FIELD,
  TEMPLATES_NOTE_TEMPLATE_FIELD,
  TEMPLATES_SAVE_TEMPLATES_MODAL_FIELD,
} from '../../../utils/analytics/enums';

interface Props {
  isTemplateModalOpen: boolean
  setModalClose: () => void
  openTemplates: () => void
  meetingTab: MeetingSection
}

const SaveTemplateModal = ({
  isTemplateModalOpen, setModalClose, openTemplates, meetingTab,
}: Props) => {
  const authData = useContext(AuthContext);
  const meetingData = useContext(MeetingDataContext);
  const { editorView } = useContext(ProseMirrorEditorViewContext);
  const { trackEvent } = useIntercom();

  const [title, setTitle] = useState('');
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const isButtonDisabled = title.length === 0;

  const validateTemplateName = () => (title.length !== 0);

  const handleClickToSave = () => {
    if (!validateTemplateName()) {
      console.log('Template name cannot be empty');
      toastInfo('Ops', 'Template name cannot be empty');
      return;
    }

    if (meetingData.version <= 2) {
      const headlessFirepad = createHeadlessFirepad(
        meetingTab,
        meetingData.meetingId,
        authData.userId,
      );
      headlessFirepad.getHtml((html: string) => {
        const newTemplateData = prepareDatabaseTemplateData(title, html, authData, meetingData);
        handleCreate(newTemplateData);
      });
    }

    if (meetingData.version >= 3) {
      const version: TemplateVersion = 2;
      const editorHtml = editorView?.dom?.innerHTML ?? '';
      const newTemplateData = prepareDatabaseTemplateData(
        title,
        editorHtml,
        authData,
        meetingData,
        version,
      );
      handleCreate(newTemplateData);
    }
  };

  const handleCreate = (newTemplateData: DatabaseTemplateData) => {
    createTemplate(authData.userId, newTemplateData, setIsSaved, setIsSubmitLoading);
    logTemplateUserAction(trackEvent, CREATE_EVENT, TEMPLATES_NOTE_TEMPLATE_FIELD,
      meetingData.version);
  };

  const handleSetModalClose = () => {
    setModalClose();
    logTemplateUserAction(trackEvent, CLOSE_EVENT, TEMPLATES_SAVE_TEMPLATES_MODAL_FIELD,
      meetingData.version);
    setIsSaved(false);
  };

  const handleOpenTemplates = () => {
    openTemplates();
    logTemplateUserAction(trackEvent, OPEN_EVENT, TEMPLATES_BROWSE_MY_TEMPLATES_BUTTON_FIELD,
      meetingData.version);
    handleSetModalClose();
  };

  const modalTitle = `Save ${meetingTab} notes as a template`;
  return (
    <Modal title={modalTitle} isOpen={isTemplateModalOpen} setModalClosed={handleSetModalClose}>
      <SaveTemplateModalContent
        title={title}
        setTitle={setTitle}
        handleSave={handleClickToSave}
        handleModalClose={handleSetModalClose}
        submitLoading={isSubmitLoading}
        isButtonDisabled={isButtonDisabled}
        isSaved={isSaved}
        openTemplates={handleOpenTemplates}
      />
    </Modal>
  );
};

export default SaveTemplateModal;
