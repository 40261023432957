import React from 'react';
import styled from 'styled-components';
import { gray7 } from '../../../shared/colours';
import ReactTooltip from '../../../shared/components/tooltip/ReactTooltip';
import EditSquareIcon from '../../../shared/icons/editSquare';

const Container = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

interface Props {
  onClick: () => void;
}

const GoToMeetingAtom = ({ onClick }: Props) => (
  <ReactTooltip tip="Go to meeting">
    <Container onClick={onClick}>
      <EditSquareIcon fill={gray7} />
    </Container>
  </ReactTooltip>
);

export default GoToMeetingAtom;
