/* eslint-disable no-unused-vars */
import React, {
  Dispatch, SetStateAction,
} from 'react';
import styled from 'styled-components';
import { gray7, gray9 } from '../../../colours';
import { defaultSmall, header200 } from '../../../typography';
import Modal from '../../Modal';
import ModalLine from '../../Modal/ModalLine';
import NotificationSettings from '../notification-settings';
import SettingDefaultMeetingTab from '../setting-default-meeting-tab';
import SlackNotificationsSection from '../slack-notifications/SlackNotificationsSection';

const Section = styled.div`
  padding: 0 24px 24px 24px;

  h2 {
    ${header200};
    color: ${gray9};
    margin: 24px 0 4px 0;
  }

  p {
    ${defaultSmall};
    color: ${gray7};

    a {
      color: ${gray7};
    }
    margin: 0 0 12px 0;
  }
`;

interface Props {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleSlackNotifModalOpen: () => void;
  isSlackEnabled: boolean;
}

const Settings = ({
  isOpen, setIsOpen, handleSlackNotifModalOpen, isSlackEnabled,
}: Props) => {
  const closeModal = () => setIsOpen(false);

  return (
    <Modal isOpen={isOpen} setModalClosed={closeModal} title="Settings">
      <Section>
        <SettingDefaultMeetingTab />
        <ModalLine />
        <NotificationSettings />
        {isSlackEnabled && (
          <>
            <ModalLine />
            <SlackNotificationsSection handleSlackNotifModalOpen={handleSlackNotifModalOpen} />
          </>
        )}
        <ModalLine />
        <h2>Close account</h2>
        <p>
          Don’t need Shepherd any longer?
          We understand, and appreciate you using our service!
          Please email
          {' '}
          <a href="mailto:support@meetshepherd.com">support@meetshepherd.com</a>
          {' '}
          to delete your account.
        </p>
      </Section>
    </Modal>
  );
};

export default Settings;
