import React, {
  Dispatch, SetStateAction,
} from 'react';
import styled from 'styled-components';
import TaskTabs, { TabButton, TabButtonsContainer, TabContent } from '../task-tabs';
import {
  TaskItem, TasksPage, TaskTab, UserCenterPage,
} from '../../types/types';
import TaskTabContent from './TaskTabContent';
import {
  mapTaskIndexToTaskTab,
  mapTaskScopeToOrderField, mapTaskTabToTabIndex,
} from '../../../utils/tasks/tasksUtils';
import { COMPLETED, IN_PROGRESS, TODO } from '../../../utils/enums';
import { surface } from '../../colours';
import BorderRadiusComponent from './BorderRadiusBar';

const Container = styled.div`
  background: ${surface};
`;
interface Props {
  taskItems: TaskItem[],
  setSection?: Dispatch<SetStateAction<UserCenterPage>>
  taskTab: TaskTab,
  setTaskTab: Dispatch<SetStateAction<TaskTab>>,
  setCreateTaskModalOpen: Dispatch<SetStateAction<boolean>>,
  height?: string,
  disableCalendarSelect?: boolean,
  disableAssigneeSelect?: boolean,
  page: TasksPage,
  disableGoToMeeting?: boolean,
}

const Tasks = ({
  taskItems, setSection, taskTab, setTaskTab, setCreateTaskModalOpen, height, page,
  disableAssigneeSelect, disableCalendarSelect, disableGoToMeeting,
}: Props) => {
  const todoTasks = taskItems.filter((taskItem) => taskItem.data.status === 'todo');
  const inProgressTasks = taskItems.filter((taskItem) => taskItem.data.status === 'inProgress');
  const completedTasks = taskItems.filter((taskItem) => taskItem.data.status === 'completed');
  const tasksLists = { todoTasks, inProgressTasks, completedTasks };
  const tasksOrderField = mapTaskScopeToOrderField(page);
  const taskTabIndex = mapTaskTabToTabIndex(taskTab);

  return (
    <>
      <BorderRadiusComponent page={page} borderRadiusTop="24px" />
      <Container>
        <TaskTabs
          tabIndex={taskTabIndex}
          setTabIndex={(index) => setTaskTab(mapTaskIndexToTaskTab(index))}
          height={height as string}
        >
          <TabButtonsContainer>
            <TabButton>To do</TabButton>
            <TabButton>In progress</TabButton>
            <TabButton>Completed</TabButton>
          </TabButtonsContainer>
          <TabContent key={TODO}>
            <TaskTabContent
              tasks={tasksLists.todoTasks}
              setSection={setSection}
              taskOrderField={tasksOrderField}
              setCreateTaskModalOpen={setCreateTaskModalOpen}
              disableAssigneeSelect={disableAssigneeSelect}
              disableCalendarSelect={disableCalendarSelect}
              disableGoToMeeting={disableGoToMeeting}
              page={page}
            />
          </TabContent>
          <TabContent key={IN_PROGRESS}>
            <TaskTabContent
              tasks={tasksLists.inProgressTasks}
              setSection={setSection}
              taskOrderField={tasksOrderField}
              setCreateTaskModalOpen={setCreateTaskModalOpen}
              disableAssigneeSelect={disableAssigneeSelect}
              disableCalendarSelect={disableCalendarSelect}
              disableGoToMeeting={disableGoToMeeting}
              page={page}
            />
          </TabContent>
          <TabContent key={COMPLETED}>
            <TaskTabContent
              tasks={tasksLists.completedTasks}
              setSection={setSection}
              taskOrderField={tasksOrderField}
              setCreateTaskModalOpen={setCreateTaskModalOpen}
              disableAssigneeSelect={disableAssigneeSelect}
              disableCalendarSelect={disableCalendarSelect}
              disableGoToMeeting={disableGoToMeeting}
              page={page}
            />
          </TabContent>
        </TaskTabs>
      </Container>

    </>
  );
};

Tasks.defaultProps = {
  height: 'calc(100vh - 310px)',
  setSection: () => { },
  disableCalendarSelect: false,
  disableAssigneeSelect: false,
  disableGoToMeeting: false,
};

export default Tasks;
