import {
  Assignee, AssigneeV2, AttendeeV2, DatabaseAssignee, PublicUserDataV2, PrivateNoteAttendeeV2,
} from '../../../shared/types/types';

export const mapPublicUserDataV2ToBeSure = (databaseData: any) => {
  // databaseData should actually be in the correct format, but to make sure we
  // don't have any undefined values, we do an extra mapping here
  const result: PublicUserDataV2 = {
    resolvedState: 'resolved',
    userId: databaseData?.userId ?? '',
    isShepherdUser: databaseData?.isShepherdUser ?? false,
    data: {
      name: databaseData.data?.name ?? '',
      email: databaseData?.data?.email ?? '',
      firstName: databaseData?.data?.firstName ?? '',
      lastName: databaseData?.data?.lastName ?? '',
      photoUrl: databaseData?.data?.photoUrl ?? '',
    },
    external: {
      email: {
        receiveTaskEmail: databaseData.external?.email?.receiveTaskEmail ?? false,
      },
      slack: {
        hasEnabledSlack: databaseData.external?.slack?.hasEnabledSlack ?? false,
        notifications: {
          meetingStartsSoon: databaseData
            .external?.slack?.notifications?.meetingStartsSoon ?? false,
          mentionedInNotes: databaseData
            .external?.slack?.notifications?.mentionedInNotes ?? false,
          taskOverdue: databaseData.external?.slack?.notifications?.taskOverdue ?? false,
          taskCreated: databaseData.external?.slack?.notifications?.taskCreated ?? false,
          taskUpdated: databaseData.external?.slack?.notifications?.taskUpdated ?? false,
          taskDeleted: databaseData.external?.slack?.notifications?.taskDeleted ?? false,
        },
      },
      trello: {
        version: databaseData.external?.trello?.version ?? 1,
        isTrelloEnabled: databaseData.external?.trello?.isTrelloEnabled ?? false,
        isAutoSyncEnabled: databaseData.external?.trello?.isAutoSyncEnabled ?? false,
        isAllowOtherToSyncEnabled: databaseData
          ?.external?.trello?.isAllowOtherToSyncEnabled ?? false,
      },
    },
  };
  return result;
};

export const rejectedPublicUserDataV2: PublicUserDataV2 = {
  ...mapPublicUserDataV2ToBeSure({}),
  resolvedState: 'rejected',
  isShepherdUser: false,
};

export const pendingPublicUserDataV2: PublicUserDataV2 = {
  ...mapPublicUserDataV2ToBeSure({}),
  resolvedState: 'pending',
};

export const emptyPublicUserDataV2: PublicUserDataV2 = {
  ...mapPublicUserDataV2ToBeSure({}),
};

export const nonShepherdUser = (email: string): PublicUserDataV2 => {
  const user = mapPublicUserDataV2ToBeSure({});
  user.data.email = email;
  return user;
};

export const splitArrayInSectionsOfTen = (array: any[]) => array.reduce((acc, cur, index) => {
  if (index % 10 === 0) {
    acc.push([]);
  }
  acc[acc.length - 1].push(cur);
  return acc;
}, []);

export const mapPublicUserDataV2ToAssignee = (publicUserDataV2: PublicUserDataV2):
  DatabaseAssignee => ({
  userId: publicUserDataV2.userId,
  name: publicUserDataV2.data.name,
  email: publicUserDataV2.data.email,
  photoUrl: publicUserDataV2.data.photoUrl,
});

export const mapAssigneeV2ToAssignee = (assigneeV2: AssigneeV2): Assignee => ({
  userId: assigneeV2.userId,
  name: assigneeV2.data.name,
  email: assigneeV2.data.email,
  photoUrl: assigneeV2.data.photoUrl,
});

export const mapAssigneeV2ToDatabaseAssignee = (
  assigneeV2: AssigneeV2,
): DatabaseAssignee => ({
  userId: assigneeV2.userId,
  name: assigneeV2.data.name,
  email: assigneeV2.data.email,
  photoUrl: assigneeV2.data.photoUrl,
});

export const mapAssigneeV2ToAttendeeV2 = (
  assignee: PrivateNoteAttendeeV2,
): AttendeeV2 => ({
  responseStatus: '',
  resolvedState: assignee.resolvedState,
  userId: assignee.userId,
  isShepherdUser: assignee.isShepherdUser,
  data: {
    name: assignee.data.name,
    email: assignee.data.email,
    firstName: assignee.data.firstName,
    lastName: assignee.data.lastName,
    photoUrl: assignee.data.photoUrl,
  },
  external: {
    email: {
      receiveTaskEmail: assignee.external.email.receiveTaskEmail,
    },
    slack: {
      hasEnabledSlack: assignee.external.slack.hasEnabledSlack,
      notifications: assignee.external.slack.notifications,
    },
    trello: {
      isTrelloEnabled: assignee.external.trello.isTrelloEnabled,
      isAutoSyncEnabled: assignee.external.trello.isAutoSyncEnabled,
      isAllowOtherToSyncEnabled: assignee.external.trello.isAllowOtherToSyncEnabled,
      version: assignee.external.trello.version,
    },
  },
});

export const mapAttendeeV2ToPrivateNoteAttendeeV2 = (
  attendee: AttendeeV2,
): PrivateNoteAttendeeV2 => ({
  isNotified: false,
  resolvedState: attendee.resolvedState,
  userId: attendee.userId,
  isShepherdUser: attendee.isShepherdUser,
  data: {
    name: attendee.data.name,
    email: attendee.data.email,
    firstName: attendee.data.firstName,
    lastName: attendee.data.lastName,
    photoUrl: attendee.data.photoUrl,
  },
  external: {
    email: {
      receiveTaskEmail: attendee.external.email.receiveTaskEmail,
    },
    slack: {
      hasEnabledSlack: attendee.external.slack.hasEnabledSlack,
      notifications: attendee.external.slack.notifications,
    },
    trello: {
      isTrelloEnabled: attendee.external.trello.isTrelloEnabled,
      isAutoSyncEnabled: attendee.external.trello.isAutoSyncEnabled,
      isAllowOtherToSyncEnabled: attendee.external.trello.isAllowOtherToSyncEnabled,
      version: attendee.external.trello.version,
    },
  },
});

export const mapAssigneeV2ToSecretChatAttendeeV2 = (
  assignee: AssigneeV2,
): PrivateNoteAttendeeV2 => ({
  isNotified: false,
  resolvedState: assignee.resolvedState,
  userId: assignee.userId,
  isShepherdUser: assignee.isShepherdUser,
  data: {
    name: assignee.data.name,
    email: assignee.data.email,
    firstName: assignee.data.firstName,
    lastName: assignee.data.lastName,
    photoUrl: assignee.data.photoUrl,
  },
  external: {
    email: {
      receiveTaskEmail: assignee.external.email.receiveTaskEmail,
    },
    slack: {
      hasEnabledSlack: assignee.external.slack.hasEnabledSlack,
      notifications: assignee.external.slack.notifications,
    },
    trello: {
      isTrelloEnabled: assignee.external.trello.isTrelloEnabled,
      isAutoSyncEnabled: assignee.external.trello.isAutoSyncEnabled,
      isAllowOtherToSyncEnabled: assignee.external.trello.isAllowOtherToSyncEnabled,
      version: assignee.external.trello.version,
    },
  },
});

export const mapPrivateNoteAttendeeV2ToAttendeeV2 = (
  attendee: PrivateNoteAttendeeV2,
): AttendeeV2 => ({
  responseStatus: '',
  resolvedState: attendee.resolvedState,
  userId: attendee.userId,
  isShepherdUser: attendee.isShepherdUser,
  data: {
    name: attendee.data.name,
    email: attendee.data.email,
    firstName: attendee.data.firstName,
    lastName: attendee.data.lastName,
    photoUrl: attendee.data.photoUrl,
  },
  external: {
    email: {
      receiveTaskEmail: attendee.external.email.receiveTaskEmail,
    },
    slack: {
      hasEnabledSlack: attendee.external.slack.hasEnabledSlack,
      notifications: attendee.external.slack.notifications,
    },
    trello: {
      isTrelloEnabled: attendee.external.trello.isTrelloEnabled,
      isAutoSyncEnabled: attendee.external.trello.isAutoSyncEnabled,
      isAllowOtherToSyncEnabled: attendee.external.trello.isAllowOtherToSyncEnabled,
      version: attendee.external.trello.version,
    },
  },
});
