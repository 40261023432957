/* eslint-disable no-unused-vars */
import React, { createContext, useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import firebase from 'firebase';
import ReactNotification from 'react-notifications-component';
import OnboardingRoutes from './pages/onboarding';
import {
  dashboardPath,
  homePath, signinPath, onboardingPath,
  tutorialVideoPath, slackRedirectPath, notionRedirectPath, trelloRedirectPath,
  ivanTestingPath, haraldTestingPath, shaokunTestingPath,
  matthewTestingPath, asishTestingPath, guestTestingPath, previousMeetingPath,
  GOOGLE_MEET_ROUTE,
  GOOGLE_CALENDAR_ROUTE,
} from './routes';
import initializeGoogleAPI from './utils/google/GoogleCalendarSetup';
import { AuthState, User } from './shared/types/types';
import './utils/firebase';
import { LOGGED_IN_STATE, UNRESOLVED_STATE } from './utils/enums';
import AuthenticatedRoute from './shared/components/routes';
import GoogleMeet from './pages/googleMeet';
import GlobalStyle from './utils/globalStyle';
import {
  defaultAuthContext,
  pendingUser,
} from './utils/user/UserDataUtils';
import GoogleCalendar from './pages/googleCalendar';
import IntercomContainer from './IntercomContainer';
import { startTrackingEvent, stopTrackingEvent } from './utils/analytics/eventLogger';
import ListenToRouteMessageContainer from './ListenToRouteMessageContainer';
import ListenToSidebarMessageContainer from './ListenToSidebarMessageContainer';
import { setMixpanelUserAndLogAppStart } from './utils/mixpanel';
import UserCenter from './pages/userCenter';
import 'react-notifications-component/dist/theme.css';
import './shared/typography/font-faces.css';
import ChromeExtensionEventLogger from './ChromeExtensionEventLogger';
import { sendWelcomeEmailIfNotSent, dbUserListenToMyUserData } from './database/firebaseUsersAPI';
import PreviousMeetingNotes from './shared/components/previous-meeting-notes';

import TutorialVideo from './pages/tutorialVideo';
import AllPages from './pages/all-pages';
import NotionRedirectPage from './pages/NotionRedirectPage/NotionRedirectPage';
import TrelloRedirectPage from './pages/TrelloRedirectPage/TrelloRedirectPage';
import SlackRedirectPage from './pages/SlackRedirectPage/SlackRedirectPage';
import IvanTestingPage from './pages/testing/IvanTestingPage';
import HaraldTestingPage from './pages/testing/HaraldTestingPage';
import ShaokunTestingPage from './pages/testing/ShaokunTestingPage';
import MatthewTestingPage from './pages/testing/MatthewTestingPage';
import AsishTestingPage from './pages/testing/AsishTestingPage';
import GuestTestingPage from './pages/testing/GuestTestingPage';
import logAppAnalytics from './utils/analytics/functions';
import setSentryUser from './utils/analytics/sentry';
import DetectingOperatingSystemContainer from './utils/context/OperatingSystemContext';
import ShepherdLogoLoadingPage from './shared/components/loading/shepherd-logo-loading-page';
import DetectInternetConnectionContainer from './utils/context/DetectInternetConnectionContainer';
import Signin from './pages/signin';
import DashboardWelcomePage from './pages/dashboard-welcome-page';
import { UIProvider } from './pages/all-pages/ui-context/uiContext';
import useFirstUserLoadEffect from './utils/user/useFirstUserLoadEffect';

export const AuthContext = createContext<AuthState>(defaultAuthContext);
export const UserDataContext = createContext<User>(pendingUser);

function App() {
  const [authState, setAuthState] = useState<AuthState>(defaultAuthContext);
  const [userData, setUserData] = useState<User>(pendingUser);
  const [hasSidebarOpened, setHasSidebarOpened] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    startTrackingEvent('appLoad');
    initializeGoogleAPI(setAuthState);
  }, []);

  useFirstUserLoadEffect(() => {
    console.log('UserData first loaded');
    console.log(userData);
  }, [userData]);

  useEffect(() => {
    if (authState.userState !== 'loggedIn') return () => { };
    firebase.analytics().setUserId(authState.userId);
    setSentryUser(authState);
    setMixpanelUserAndLogAppStart(authState);
    sendWelcomeEmailIfNotSent(authState);
    return dbUserListenToMyUserData(authState, setUserData);
  }, [authState]);

  useEffect(() => {
    logAppAnalytics(authState.userId, hasSidebarOpened);
  }, [authState, hasSidebarOpened]);

  useEffect(() => {
    const timer = setTimeout(() => { setIsLoading(false); }, 2500);
    return () => { clearTimeout(timer); };
  }, []);

  const isAuthenticated = authState.userState === LOGGED_IN_STATE;
  const isUnresolved = authState.userState === UNRESOLVED_STATE || isLoading;
  const isOnboarded = userData.data.hasOnboarded;

  if (isUnresolved) {
    return <ShepherdLogoLoadingPage />;
  }

  stopTrackingEvent('appLoad');

  return (
    <AuthContext.Provider value={authState}>
      <UserDataContext.Provider value={userData}>
        <DetectingOperatingSystemContainer>
          <DetectInternetConnectionContainer>
            <IntercomContainer>
              <ListenToSidebarMessageContainer setHasSidebarOpened={setHasSidebarOpened}>
                <UIProvider>
                  <GlobalStyle />
                  <ReactNotification />
                  <ChromeExtensionEventLogger />
                  <Router>
                    <ListenToRouteMessageContainer>
                      <Switch>
                        {/* When adding new routes, you need to do it here.
                  You cannot make a new file and with additional routes and do it in that file. */}
                        <Route path={signinPath}>
                          <Signin
                            isAuthenticated={isAuthenticated}
                            isOnboarded={isOnboarded}
                            isLoading={isUnresolved}
                          />
                        </Route>
                        <AuthenticatedRoute
                          exact
                          path={homePath}
                          component={DashboardWelcomePage}
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                        />
                        <AuthenticatedRoute
                          path={dashboardPath}
                          component={DashboardWelcomePage}
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                        />
                        <AuthenticatedRoute
                          path={onboardingPath}
                          component={OnboardingRoutes}
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                        />
                        <AuthenticatedRoute
                          path={previousMeetingPath}
                          component={PreviousMeetingNotes}
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                        />
                        <AuthenticatedRoute
                          path="/meeting/:meetingId"
                          component={AllPages}
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                        />
                        <AuthenticatedRoute
                          path={tutorialVideoPath}
                          component={TutorialVideo}
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                        />
                        <AuthenticatedRoute
                          path={slackRedirectPath}
                          component={SlackRedirectPage}
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                        />
                        <AuthenticatedRoute
                          path={notionRedirectPath}
                          component={NotionRedirectPage}
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                        />
                        <AuthenticatedRoute
                          path={trelloRedirectPath}
                          component={TrelloRedirectPage}
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                        />
                        <AuthenticatedRoute
                          path={ivanTestingPath}
                          component={IvanTestingPage}
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                        />
                        <AuthenticatedRoute
                          path={haraldTestingPath}
                          component={HaraldTestingPage}
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                        />
                        <AuthenticatedRoute
                          path={shaokunTestingPath}
                          component={ShaokunTestingPage}
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                        />
                        <AuthenticatedRoute
                          path={matthewTestingPath}
                          component={MatthewTestingPage}
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                        />
                        <AuthenticatedRoute
                          path={asishTestingPath}
                          component={AsishTestingPage}
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                        />
                        <AuthenticatedRoute
                          path={guestTestingPath}
                          component={GuestTestingPage}
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                        />
                        <AuthenticatedRoute
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                          path={GOOGLE_CALENDAR_ROUTE}
                          component={GoogleMeet}
                        />
                        <AuthenticatedRoute
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                          path={GOOGLE_MEET_ROUTE}
                          component={GoogleCalendar}
                        />
                        <AuthenticatedRoute
                          exact
                          path={'/*'}
                          component={DashboardWelcomePage}
                          isAuthenticated={isAuthenticated}
                          isOnboarded={isOnboarded}
                        />
                      </Switch>
                    </ListenToRouteMessageContainer>
                  </Router>
                </UIProvider>
              </ListenToSidebarMessageContainer>
            </IntercomContainer>
          </DetectInternetConnectionContainer>
        </DetectingOperatingSystemContainer>
      </UserDataContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
