import React, { useState } from 'react';
import styled from 'styled-components';
import { darkBlue3, blue6 } from '../../shared/colours';
import InfoQuestionIcon from '../../shared/icons/infoQuestion';

const Container = styled.span`
  display: flex;
  cursor: pointer;
`;

const HelpIconAtom = () => {
  const [color, setColor] = useState(darkBlue3);
  const mouseEnter = () => setColor(blue6);
  const mouseLeave = () => setColor(darkBlue3);

  return (
    <Container onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <InfoQuestionIcon size={20} color={color} />
    </Container>
  );
};

export default HelpIconAtom;
