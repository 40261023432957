import React from 'react';
import { darkBlue4 } from '../../../../colours';

interface OrderedListProps {
  fill?: string;
  width?: number;
  height?: number;
}

const OrderedListIcon = ({
  fill,
  width,
  height,
}: OrderedListProps) => (
  <svg width={width} height={height} viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M 2.237,10.5 H 0 V 9.75 H 1.492 V 9.375 H 0.746 V 8.625 H 1.493 V 8.25 H 0 V 7.5 H 2.237 Z M 12.75,9 c 0,0.4142 -0.336,0.75 -0.75,0.75 H 4.552 C 4.138,9.75 3.802,9.4142 3.802,9 3.802,8.5858 4.138,8.25 4.552,8.25 H 12 c 0.414,0 0.75,0.3358 0.75,0.75 z M 2.237,6.75 H 0 V 6.075 L 1.343,4.5 H 0 V 3.75 H 2.237 V 4.425 L 0.895,6 H 2.237 Z M 12.75,5.25 C 12.75,5.6642 12.414,6 12,6 H 4.552 C 4.138,6 3.802,5.6642 3.802,5.25 3.802,4.8358 4.138,4.5 4.552,4.5 H 12 c 0.414,0 0.75,0.3358 0.75,0.75 z M 1.865,3 H 1.118 V 0.75 H 0.375 V 0 h 1.49 z M 12.75,1.5 c 0,0.4142 -0.336,0.75 -0.75,0.75 H 4.552 c -0.414,0 -0.75,-0.3358 -0.75,-0.75 0,-0.4142 0.336,-0.75 0.75,-0.75 H 12 c 0.414,0 0.75,0.3358 0.75,0.75 z"
      fill={fill}
    />
  </svg>
);

OrderedListIcon.defaultProps = {
  fill: darkBlue4,
  width: 13,
  height: 11,
};

export default OrderedListIcon;
