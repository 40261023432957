/* eslint-disable no-unreachable */
import { Dispatch, SetStateAction } from 'react';
import * as Sentry from '@sentry/browser';
import TurndownService from 'turndown';
import {
  AuthState,
  SlackChannel, SlackMessageContent, SlackUser, TaskItem,
} from '../../shared/types/types';
import { functions } from '../firebase';
import { toastDanger } from '../notifications';
import {
  Block,
  Notification,
  sortSlackChannelsAlphabetically,
} from './slackUtils';

import {
  mapSlackAPIUserToSlackUser, isValidBlock,
  createSlackMessageContent, mapSlackAPIChannelToSlackChannel,
} from './SlackAPIUtils';

import mdToSlackBlocks from './SlackFormatting';
import { dbUdpateSlackNotificationSent } from '../../database/firebaseTasksAPI';
import { logSlackNotification, logSlackUserAction } from '../analytics/eventLogger';
import {
  GET_EVENT, SEND_EVENT, SLACK_CHANNEL, SLACK_DM, SLACK_NOTIFICATION, SLACK_USER,
} from '../analytics/enums';

const turndownService = new TurndownService();

const slackCoreAPISendMessage = (
  messageContent: SlackMessageContent,
  accessToken: string,
) => functions()
  .httpsCallable('sendMessageToSlackv2')({ accessToken, messageContent })
  .then((response) => response.data)
  .catch((error) => {
    console.log('Got NO response from slackAPISendMessage');
    console.log(error);
    throw error;
  });

export default slackCoreAPISendMessage;

export const slackCoreSendMessages = (
  channels: SlackChannel[],
  accessToken: string,
  notes: string,
) => {
  const promises = channels.map((channel) => {
    const convertedBlocks = coreConvertToSlackBlocks(notes);
    const messageContent = createSlackMessageContent(channel, convertedBlocks);
    return slackCoreAPISendMessage(messageContent, accessToken);
  });
  return Promise.all(promises).then((responses) => {
    logSlackUserAction(SEND_EVENT, SLACK_CHANNEL, channels.length);
    console.log(responses);
    return responses;
  });
};

export const coreConvertToSlackBlocks = (notes: string) => {
  const mdNotes = turndownService.turndown(notes);
  const slackBlocks = mdToSlackBlocks(mdNotes);
  return slackBlocks;
};

export const slackCoreAPISendNotificationForTaskUpdate = (
  task: TaskItem,
  context: AuthState,
  updatedSection: string,
  newDueDate?: string,
  newTitle?: string,
) => {
  if (task.assignee.data.email === context.email) return;
  if (!task.assignee.external.slack.hasEnabledSlack) return;
  if (!task.assignee.external.slack.notifications.taskUpdated) return;
  const updatedTaskDescription = task.data.description;
  const taskCreatorNames = task.data.reporter.name;
  const updaterNames = `${context.firstName} ${context.lastName}`;
  const taskTitle = newTitle?.length ? newTitle : task.data.title;
  const meeting = task.meeting.name;

  const dueDateText = generateDueDateText(task, newDueDate);

  const blocks = [
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*${updaterNames}* updated the ${updatedSection} of a task assigned to you \t ${dueDateText} \n*Task:* ${taskTitle} ${updatedTaskDescription.length ? `\n*Description* ${updatedTaskDescription}` : ''} \n :bust_in_silhouette: *Created by:* ${taskCreatorNames} ${meeting.length ? `\t :virtual-meeting: *Meeting*: ${meeting}` : ''} `,
      },
    },
  ];
  if (!isValidBlock(blocks)) return;
  const dataProps = {
    email: task.assignee.data.email,
    blocks,
    context,
  };
  functions().httpsCallable('sendSlackNotification')(dataProps)
    .then(() => {
      console.log('slack notification sent successfully');
      // TODO - Not sure which action we should use. SEND / CREATE / UPDATE for notifications?
      logSlackNotification(SEND_EVENT, SLACK_NOTIFICATION, updatedSection);
    })
    .catch((error) => {
      console.log(error);
      toastDanger('Something has went wrong with the slack task updated notification');
      Sentry.captureException(error);
    });
};

export const slackCoreAPISendNotificationForTaskCreate = (
  task: TaskItem,
  context: AuthState,
) => {
  if (task.assignee.data.email === context.email) return;
  if (!task.assignee.external.slack.hasEnabledSlack) return;
  if (!task.assignee.external.slack.notifications.taskCreated) return;
  const meeting = task.meeting.name;
  const { description } = task.data;
  const taskCreatorNames = task.data.reporter.name;
  const dueDateText = generateDueDateText(task);

  const blocks = [
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*${taskCreatorNames}* created a task assigned to you \t ${dueDateText}\n* Name:* ${task.data.title} ${description.length ? `\n*Description* ${description}` : ''} ${meeting.length ? `\n :virtual-meeting: *Meeting*: ${meeting}` : ''}`,
      },
    },
  ];
  if (!isValidBlock(blocks)) return;
  const dataType: Notification = {
    email: task.assignee.data.email,
    blocks,
    context,
  };
  console.log('DataType sent to slack');
  console.log(dataType);
  functions().httpsCallable('sendSlackNotification')(dataType)
    .then((result) => {
      logSlackNotification(SEND_EVENT, SLACK_NOTIFICATION, 'create');
      return result;
    })
    .catch((error) => {
      console.error(error);
      toastDanger('Something has went wrong with the slack new task notification');
      Sentry.captureException(error);
    });
};

export const slackCoreAPISendNotificationForTaskDelete = (
  task: TaskItem,
  context: AuthState,
) => {
  if (task.assignee.data.email === context.email) return;
  if (!task.assignee.external.slack.hasEnabledSlack) return;
  if (!task.assignee.external.slack.notifications.taskDeleted) return;
  const meeting = task.meeting.name;
  const { description } = task.data;
  const deleterNames = `${context.firstName} ${context.lastName}`;
  const taskCreatorNames = task.data.reporter.name;
  const dueDateText = generateDueDateText(task);

  const blocks: Block[] = [
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `*${deleterNames}* deleted a task assigned to you \t ${dueDateText} \n*Task Name:* ${task.data.title} \n :bust_in_silhouette: *Created by:* ${taskCreatorNames} ${description.length ? `\n*Description* ${description}` : ''} ${meeting.length ? `\n :virtual-meeting: *Meeting*: ${meeting}` : ''}`,
      },
    },
  ];
  if (!isValidBlock(blocks)) return;
  const deletedTaskData: Notification = {
    email: task.assignee.data.email,
    blocks,
    context,
  };
  functions().httpsCallable('sendSlackNotification')(deletedTaskData)
    .then((result) => {
      logSlackNotification(SEND_EVENT, SLACK_NOTIFICATION, 'delete');
      return result;
    })
    .catch((error) => {
      console.log(error);
      Sentry.captureException(error);
    });
};

export const slackCoreAPISendNotificationForTaskOverdue = (
  task: TaskItem,
  context: AuthState,
) => {
  if (!task.assignee.external.slack.notifications.taskOverdue) return;
  if (task.integrations.slack.isOverdueNotificationSent) return;
  const taskDescription = task.data.description;
  const meeting = task.meeting.name;
  const taskCreatorNames = task.data.reporter.name;
  const dueDateText = generateDueDateText(task);

  const blocks = [
    {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: `You have an overdue task \t ${dueDateText} \n *Task Due:* ${task.data.title} ${taskDescription.length ? `\n*Description* ${taskDescription}` : ''} \n :bust_in_silhouette: *Created by:* ${taskCreatorNames} ${meeting.length ? `\n :virtual-meeting: *Meeting*: ${meeting}` : ''}`,
      },
    },
  ];
  if (!isValidBlock(blocks)) return;
  const overdueTaskData: Notification = {
    email: task.assignee.data.email,
    blocks,
    context,
  };
  functions().httpsCallable('sendSlackNotification')(overdueTaskData)
    .then((result) => {
      dbUdpateSlackNotificationSent(task);
      logSlackNotification(SEND_EVENT, SLACK_NOTIFICATION, 'overdue');
      return result;
    })
    .catch((error) => {
      console.log(error);
      toastDanger('Something has went wrong with the slack task overdue notification');
      Sentry.captureException(error);
    });
};

export const slackCoreAPIGetUserInfo = async (
  accessToken: string,
  slackUserId: string,
  setSlackDmUser: Dispatch<SetStateAction<SlackUser>>,
) => {
  if (slackUserId.length === 0 || accessToken.length === 0) return;
  functions()
    .httpsCallable('getSlackUserInfo')({
      slackUserId,
      accessToken,
    })
    .then((response) => {
      const user = mapSlackAPIUserToSlackUser(response.data);
      setSlackDmUser(user);
      logSlackUserAction(GET_EVENT, SLACK_USER);
    })
    .catch((error) => {
      console.log(error);
      toastDanger('Failed to retrieve Slack user data');
      Sentry.captureException(error);
    });
};

export const slackCoreGetChannelNames = async (
  accessToken: string,
  setSlackChannels: Dispatch<SetStateAction<SlackChannel[]>>,
) => {
  functions()
    .httpsCallable('getAllTypesSlackChannels')(accessToken)
    .then((response) => {
      const channels = response?.data?.channels ?? [];
      const channelsWithoutBots = channels.filter(
        (channel: any) => !channel?.is_bot,
      );
      const slackChannels = channelsWithoutBots.map(mapSlackAPIChannelToSlackChannel) ?? [];
      const sortedChannels = sortSlackChannelsAlphabetically(slackChannels);
      logSlackUserAction(GET_EVENT, SLACK_CHANNEL, slackChannels.length);
      if (sortedChannels.length === 0) return;
      setSlackChannels(sortedChannels);
    })
    .catch((error) => {
      console.log(error);
      toastDanger('Failed to get Slack channels', 'Please try refreshing the page');
      Sentry.captureException(error);
    });
};

export const slackCoreGetDirectMessages = async (
  accessToken: string,
  setSlackChannels: Dispatch<SetStateAction<SlackChannel[]>>,
) => {
  if (accessToken.length === 0) return;
  functions()
    .httpsCallable('slackGetDirectMessages')({ accessToken })
    .then((response) => {
      const directMessages = response?.data ?? [];
      const channelsWithoutBots = directMessages.filter(
        (channel: any) => !channel?.is_bot,
      );
      const channelsWithoutDeactivated = channelsWithoutBots.filter(
        (channel: any) => !channel?.deleted,
      );
      const slackChannels = channelsWithoutDeactivated.map(mapSlackAPIChannelToSlackChannel) ?? [];
      const sortedSlackChannels = sortSlackChannelsAlphabetically(slackChannels);
      logSlackUserAction(GET_EVENT, SLACK_DM, slackChannels.length);
      if (sortedSlackChannels.length === 0) return;
      setSlackChannels(sortedSlackChannels);
    })
    .catch((error) => {
      console.log(error);
      toastDanger('Failed to get direct Slack messages', 'Please try refreshing the page');
      Sentry.captureException(error);
    });
};

/**
 * If there exist a newDueDate, it will generate a new message using that date
 *
 * If not, will check if there exist an old due date, and if so generate a message using that date
 *
 * If no due dates, then will return an empty string
 *
 * @returns {string}
 * `(Date Emoji) Due: Tue Jan 25`
 */
const generateDueDateText = (task: TaskItem, newDueDate?: string): string => {
  if (typeof newDueDate === 'undefined' || newDueDate.length === 0) {
    // No new due date was provided
    // Evaluating if there is an existing due date
    if (task.date.dueDate.type !== 'date') return '';
    if (task.date.dueDate.date.date.length === 0) return '';
    const dueDate = new Date(task.date.dueDate.date.date);
    if (dueDate?.toISOString()?.length === 0 ?? true) return '';
    const text = `:date: Due: ${dueDate.toDateString()}`;
    return text;
  }
  // New due date provided
  const dueDate = new Date(newDueDate);
  return `:date: Due: ${dueDate.toDateString()}`;
};
