import React from 'react';
import styled from 'styled-components';
import NoteItemMolecule from './NoteItemMolecule';
import SelectAllMolecule from './SelectAllMolecule';
import NotesListLabelAtom from './NotesListLabelAtom';
import { GapiMeetingData } from '../../../shared/types/types';
import Scrollbar from '../../../shared/components/scrollbar';

const Container = styled.div`

`;

interface Props {
  notes: GapiMeetingData[];
  // eslint-disable-next-line no-unused-vars
  onAction: (eventId: string, calendarId: string) => void,
}

// TODO add loading state

const NotesListOrganism = ({ notes, onAction }: Props) => (
  <Container>
    <NotesListLabelAtom text="Most recent" />
    <SelectAllMolecule />
    {/* TODO: Add unique key */}
    <Scrollbar maxHeight="58vh">
      {notes.map((note) => (
        <NoteItemMolecule note={note} onAction={onAction} />
      ))}
    </Scrollbar>
  </Container>
);

export default NotesListOrganism;
