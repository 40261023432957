import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import useWindowMessageReceiver from './utils/hook/windowMessageReceiver';
import { BUTTON_EVENT } from './utils/enums';
import { CEButtonEvent } from './shared/types/types';
import { logEventButtonClickCEButton } from './utils/analytics/eventLogger';

const ChromeExtensionEventLogger = () => {
  const { data: buttonEventData } = useWindowMessageReceiver<CEButtonEvent>({ field: '' }, BUTTON_EVENT);
  const { trackEvent } = useIntercom();

  useEffect(() => {
    if (buttonEventData.field.length === 0) return;
    console.log('%c[W] received button event message', 'color: navy');
    console.log(buttonEventData.field);
    logEventButtonClickCEButton(trackEvent, buttonEventData.field);
  }, [buttonEventData]);

  return (null);
};

export default ChromeExtensionEventLogger;
