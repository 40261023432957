import React, { Dispatch, SetStateAction } from 'react';
import EnableCrossIcon from '../../../../shared/icons/EnableCrossIcon';
import {
  CrossIconContainer, EnableButton, InnerContainer,
  IntegrationContainer, IntegrationText,
} from '../integrations/Integrations';
import { trelloAuthUrl } from '../../../../utils/trello/trelloUtils';
import { IntegrationsTabView } from '../../../../shared/types/types';
import { TRELLO_PROCESSING_VIEW } from '../../../../utils/constants';
import { TrelloIcon } from '../../../../shared/icons/integration-icons';

interface Props {
  enable: boolean,
  setView: Dispatch<SetStateAction<IntegrationsTabView>>,
}

export default function TrelloNotIntegrated({ enable, setView }: Props) {
  const handleButtonClick = () => {
    try {
      window.open(trelloAuthUrl);
      setView(TRELLO_PROCESSING_VIEW);
    } catch (error) {
      console.log(error);
    }
  };
  if (enable) return null;

  // TODO Asish and Matt: Seems like both of you have made changes to this file.
  // I'm not sure which one we should use. Can you please review this?
  // Which one looks the best?
  // TODO:Reply :- I feel the commented code is not align with designs we are following
  return (
    <>
      <IntegrationContainer>
        <InnerContainer>
          <TrelloIcon width="28" />
          <IntegrationText>Trello</IntegrationText>
        </InnerContainer>
        <InnerContainer>
          <CrossIconContainer onClick={handleButtonClick}>
            <EnableCrossIcon />
            <EnableButton>Enable</EnableButton>
          </CrossIconContainer>
        </InnerContainer>
      </IntegrationContainer>
      {/* <div>
        <OuterContainer>
          <InnerContainer>
            <TrelloIcon width="28" />
            <IntegrationText>Trello</IntegrationText>
          </InnerContainer>
          <InnerContainer>
            <CrossIconContainer onClick={handleButtonClick}>
              <EnableCrossIcon />
              <EnableButton>Enable</EnableButton>
            </CrossIconContainer>
          </InnerContainer>
        </OuterContainer>
      </div> */}
    </>
  );
}
