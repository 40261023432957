import React from 'react';
import styled from 'styled-components';
import { darkBlue1, darkBlue2 } from '../../../shared/colours';
import SearchIconAlt from '../../../shared/icons/SearchIconAlt';
import { uiText } from '../../../shared/typography';

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin-right: 8px;
`;

const IconContainer = styled.span`
  position: absolute;
  left: 14px;
  display: flex;
`;

const InputBox = styled.input`
  padding: 10px 14px;
  // make space for the search icon
  padding-left: 46px;
  border-radius: 10px;
  border: 2px solid ${darkBlue1};
  outline: none;
  width: 100%;
  ${uiText}

  &::-webkit-input-placeholder {/* Chrome/Opera/Safari/Edge */
    color: ${darkBlue2};
  }
  
  &::-ms-input-placeholder { /* Microsoft Edge */
     color: ${darkBlue2};
  }
  
  &::placeholder {
    color: ${darkBlue2};
  }
`;

const TextFilterBoxAtom = () => (
  <Container>
    <IconContainer>
      <SearchIconAlt color={darkBlue2} />
    </IconContainer>
    <InputBox type="text" placeholder="Search your unattached notes" />
  </Container>
);

export default TextFilterBoxAtom;
