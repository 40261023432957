import { Form, Formik } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { darkBlue4 } from '../../../../shared/colours';
import SlackIconLarge from '../../../../shared/icons/integrations/SlackIconLarge';
import { SlackChannel } from '../../../../shared/types/types';
import { header600, uiText } from '../../../../shared/typography';
import SlackDefaultChannelsInputDropdown from './InputDropdown';

const View3Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SelectChannelHeader = styled.h1`
  ${header600};
`;

const ChangeChannelText = styled.h3`
  ${uiText}
  color: ${darkBlue4};
  text-align: center;
`;

interface Props {
  slackChannels: SlackChannel[],
  setNewDefaultChannels: Dispatch<SetStateAction<SlackChannel[]>>,
}

const SlackSelectDefaultChannel = ({
  slackChannels,
  setNewDefaultChannels,
}:Props) => {
  const setDefaultSlackChannel = (channel: SlackChannel) => {
    setNewDefaultChannels([channel]);
  };

  return (
    <View3Container>
      <SlackIconLarge />
      <SelectChannelHeader>Select your default Slack channel</SelectChannelHeader>
      <ChangeChannelText>
        You can change your default channel before you share notes, anytime.
      </ChangeChannelText>
      <Formik
        initialValues={{ defaultChannel: '' }}
        onSubmit={() => { }}
      >
        {() => (
          <Form>
            <SlackDefaultChannelsInputDropdown
              name="defaultSlackChannel"
              placeholder="Choose default channel"
              channels={slackChannels}
              setChannel={setDefaultSlackChannel as Dispatch<SetStateAction<SlackChannel>>}
            />
          </Form>
        )}
      </Formik>
    </View3Container>
  );
};

export default SlackSelectDefaultChannel;
