import React, { useContext } from 'react';
import styled from 'styled-components';
import DefaultMeetingTabDropdown from '../../default-tab-dropdown';
import { header200 } from '../../../typography';
import { gray9 } from '../../../colours';
import { AuthContext, UserDataContext } from '../../../../App';
import { dbUserUpdateInfo } from '../../../../database/firebaseUsersAPI';
import { toastDanger, toastSuccess } from '../../../../utils/notifications';
import { mapMeetingSectionToText } from '../../../../utils/meetings/meetingsUtils';
import { MeetingSection } from '../../../types/types';

const HeadText = styled.h2`
  ${header200};
  color: ${gray9};
  margin-bottom: 4px;
`;

const SettingDefaultMeetingTab = () => {
  const { settings } = useContext(UserDataContext);
  const { userId } = useContext(AuthContext);
  const meetingSectionPlaceholder = mapMeetingSectionToText(settings.defaultUserTab);

  const onChange = (newTab: MeetingSection) => {
    const newSettings = {
      ...settings,
      defaultUserTab: newTab,
    };

    dbUserUpdateInfo(
      userId,
      { settings: newSettings },
    ).then(
      () => {
        toastSuccess('Updated', `Default tab set to ${mapMeetingSectionToText(newTab)}.`);
      },
    ).catch(() => {
      toastDanger('Failed', 'Failed to update default tab.');
    });
  };

  return (
    <>
      <HeadText>Default tab</HeadText>
      <DefaultMeetingTabDropdown
        placeholder={meetingSectionPlaceholder}
        label="This tab will be open by default when you use Shepherd"
        name="defaultTab"
        onChange={onChange}
      />
    </>
  );
};

export default SettingDefaultMeetingTab;
