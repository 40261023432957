import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { surface } from '../../../shared/colours';

const Container = styled.div`
  position:absolute;
  bottom:0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 520px;
  height: 60px;
  border-radius: 0px 24px 0px 0px;
  background: ${surface};
  padding: 10px 29.5px;

  & > {
    div:first-child{
      margin-right: 8px;
    }
  }
`;

interface Props {
  children: ReactNode,
}

const ContainerAtom = ({ children }: Props) => (
  <Container>
    {children}
  </Container>
);

export default ContainerAtom;
