import { meetingPath, tutorialVideoPath } from '../../routes';
import {
  Assignee, DatabaseTaskItem,
  User, Reporter, TaskItem, TaskOrderField, TasksPage, TaskStatus, TaskTab,
} from '../../shared/types/types';
import { ensureNotUndefined } from '../array';
import {
  ALL_TASKS_PAGE, COMPLETED, IN_PROGRESS, MEETING_PAGE, TODO,
} from '../enums';
import {
  COMPLETED_ICON_URL, IN_PROGRESS_ICON_URL, OVERDUE_ICON_URL, TODO_ICON_URL,
} from '../constants';
import { dateISOObject, dateToSDateObject } from '../dateUtils/date';
import { mapAssigneeV2ToDatabaseAssignee } from '../user/publivUserDataV2/PublicUserDataV2Utils';

export const isDateOverdue = (
  dueDateTimestamp: number,
) => dueDateTimestamp !== 0
&& !datesAreOnSameDay(new Date(dueDateTimestamp), new Date())
  && dueDateTimestamp <= Date.now();

const datesAreOnSameDay = (
  first: Date,
  second: Date,
) => first.getFullYear() === second.getFullYear()
&& first.getMonth() === second.getMonth()
  && first.getDate() === second.getDate();

export const isCompleted = (
  status: TaskStatus,
) => status === 'completed';

export const isTaskOverdue = (
  task: TaskItem | DatabaseTaskItem,
) => isDateOverdue(task.date.dueDate.date.timestamp) && !isCompleted(task.data.status);

export const getStatusName = (status: TaskStatus) => {
  if (status === 'completed') {
    return 'Completed';
  }
  if (status === 'inProgress') {
    return 'In Progress';
  }
  if (status === 'overdue') {
    return 'Overdue';
  }
  if (status === 'todo') {
    return 'To Do';
  }
  return '';
};

export const sortTasksByCreated = (one: TaskItem, two: TaskItem) => {
  if (one.date.created.timestamp > two.date.created.timestamp) return 1;
  return -1;
};

export const userDataToAssignee = (userData: User, userId: string): Assignee => ({
  userId,
  email: userData.data.email,
  name: userData.data.name,
  photoUrl: userData.data.photoUrl,
});

export const userDataToReporter = (
  userData: User,
  userId: string,
): Reporter => userDataToAssignee(userData, userId);

export const getUnviewedTasksAssignedToMe = (tasks: TaskItem[], userEmail: string): number => {
  const matchingTasks = tasks.filter(
    (task) => task.data.assignee.email === userEmail
      && !task.data.isViewed,
  );
  return matchingTasks.length;
};

export const getAssigneeCandidatesList = (
  registeredAttendees: Assignee[], attendeeEmails: string[],
): Assignee[] => {
  const registeredAttendeeEmails: string[] = registeredAttendees
    .map((user: Assignee) => user.email);

  return attendeeEmails.map((candidatesEmail: string) => {
    if (registeredAttendeeEmails.includes(candidatesEmail)) {
      return ensureNotUndefined(registeredAttendees
        .find((attendee: Assignee) => attendee.email === candidatesEmail));
    }
    return {
      userId: '',
      name: '',
      email: candidatesEmail,
      photoUrl: '',
    };
  });
};

export const mapTaskScopeToOrderField = (scope: TasksPage): TaskOrderField => {
  const mapping = {
    meeting: 'meetingIndex' as TaskOrderField,
    allTasks: 'privateIndex' as TaskOrderField,
  };
  return mapping[scope];
};

export const getTaskPage = (): TasksPage => {
  if (window.location.pathname.startsWith(meetingPath)) return MEETING_PAGE;
  if (window.location.pathname.startsWith(tutorialVideoPath)) return ALL_TASKS_PAGE;
  throw Error('invalida task page');
};

export const mapTaskStatusToIconURL = (status: TaskStatus) => {
  switch (status) {
    case 'todo':
      return TODO_ICON_URL;
    case 'inProgress':
      return IN_PROGRESS_ICON_URL;
    case 'completed':
      return COMPLETED_ICON_URL;
    case 'overdue':
      return OVERDUE_ICON_URL;
    default:
      return TODO_ICON_URL;
  }
};

export const mapTaskStatusToEmoji = (status: TaskStatus) => {
  switch (status) {
    case 'todo':
      return ':white_square:';
    case 'inProgress':
      return ':arrows_counterclockwise:';
    case 'completed':
      return ':white_check_mark:';
    case 'overdue':
      return '⭕️';
    default:
      return TODO_ICON_URL;
  }
};

export const mapTaskTabToTabIndex = (taskTab: TaskTab) => {
  switch (taskTab) {
    case TODO:
      return 0;
    case IN_PROGRESS:
      return 1;
    case COMPLETED:
      return 2;
    default:
      console.error("DEVELOPER NOTICE: mapTaskTabToTabIndex doesn't have a case for this status");
      return 0;
  }
};

export const mapTaskIndexToTaskTab = (status: number): TaskTab => {
  switch (status) {
    case 0:
      return TODO;
    case 1:
      return IN_PROGRESS;
    case 2:
      return COMPLETED;
    default:
      console.error("DEVELOPER NOTICE: mapTaskIndexToTaskTab doesn't have a case for this status");
      return TODO;
  }
};

export const isValidTaskTab = (status: string) => {
  switch (status) {
    case TODO:
    case IN_PROGRESS:
    case COMPLETED:
      return true;
    default:
      return false;
  }
};

export const filterTasksExcludeOtherUsersPrivateTasks = (
  task: TaskItem, userEmail: string,
) => {
  const { isPrivate } = task.data;
  const isAssignedToMe = task.data.assignee.email === userEmail;
  if (isPrivate && !isAssignedToMe) {
    return false;
  }
  return true;
};

export const getTaskWithNewDueDate = (oldTask: TaskItem, newDueDate: Date) => ({
  ...oldTask,
  date: {
    ...oldTask.date,
    updated: dateToSDateObject(new Date()),
    dueDate: {
      type: 'date',
      date: dateToSDateObject(newDueDate),
      meeting: {
        meetingId: '',
        startDate: dateISOObject(),
        name: '',
      },
    },
  },
} as TaskItem);

export const getTaskWithNewAssignee = (oldTask: TaskItem, newAssignee: Assignee) => ({
  ...oldTask,
  date: {
    ...oldTask.date,
    updated: dateToSDateObject(new Date()),
  },
  data: {
    ...oldTask.data,
    assignee: newAssignee,
  },
} as TaskItem);

export const getTaskWithNewTitle = (oldTask: TaskItem, newTitle: string) => ({
  ...oldTask,
  date: {
    ...oldTask.date,
    updated: dateToSDateObject(new Date()),
  },
  data: {
    ...oldTask.data,
    title: newTitle,
  },
});

export const getTaskWithNewStatus = (oldTask: TaskItem, newStatus: TaskStatus) => ({
  ...oldTask, data: { ...oldTask.data, status: newStatus },
});

export const getDuplicatedTaskWithNewUpdatedTime = (taskItem: TaskItem): DatabaseTaskItem => {
  const databaseTaskItem = mapTaskItemToDatabaseTaskItem(taskItem);
  databaseTaskItem.date.updated = dateToSDateObject(new Date());
  return databaseTaskItem;
};

export const mapTaskItemToDatabaseTaskItem = (taskItem: TaskItem): DatabaseTaskItem => ({
  version: taskItem.version,
  data: taskItem.data,
  date: taskItem.date,
  integrations: taskItem.integrations,
  meeting: taskItem.meeting,
  order: taskItem.order,
  permissions: taskItem.permissions,
  assignee: mapAssigneeV2ToDatabaseAssignee(taskItem.assignee),
  reporter: mapAssigneeV2ToDatabaseAssignee(taskItem.reporter),
} as DatabaseTaskItem);
