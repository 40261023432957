import React, {
  Dispatch, SetStateAction, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import { Transaction } from 'prosemirror-state';
import { DOMParser } from 'prosemirror-model';
import { logTemplateUserAction } from '../../../../utils/analytics/eventLogger';
import { gray9 } from '../../../../shared/colours';
import { header600 } from '../../../../shared/typography';
import CloseIcon from '../../../../shared/icons/closeIcon';
import TemplateCardsContent from './TemplateCardsContent';
import { AuthContext } from '../../../../App';
import { dbListenForUserTemplates, dbDeleteUserTemplate, dbListenForShepherdTemplates } from '../../../../database/firebaseTemplatesAPI';
import { MeetingSection, TemplateData, MeetingVersion } from '../../../../shared/types/types';
import { createHeadlessFirepad } from '../../../../utils/firebase';
import MeetingDataContext from '../../context/MeetingDataContext';
import { toastDanger, toastInfo } from '../../../../utils/notifications';
import ProseMirrorEditorViewContext from '../../context/ProseMirrorEditorViewContext';
import {
  CLOSE_EVENT,
  DELETE_EVENT,
  INSERT_EVENT,
  TEMPLATES_BROWSE_TEMPLATES_VIEW_FIELD,
  TEMPLATES_NOTE_TEMPLATE_FIELD,
} from '../../../../utils/analytics/enums';

const TemplateHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;

  margin-bottom: 24px;
`;

const TemplateHeader = styled.div`
  ${header600}
`;

const TemplateIconWrapper = styled.div`
  border: none;
  background: none;
  cursor: pointer;

  height: 24px;
`;

const ShepherdContainer = styled.div`
  padding: 24px 0px;
`;

interface Props {
  section: string
  tab: MeetingSection,
  setIsTemplatesOpen: Dispatch<SetStateAction<boolean>>,
  setSelectedTemplateToEdit: Dispatch<SetStateAction<TemplateData>>,
}

const TemplatesContent = ({
  section,
  tab,
  setIsTemplatesOpen,
  setSelectedTemplateToEdit,
}: Props) => {
  const { editorView } = useContext(ProseMirrorEditorViewContext);

  const { meetingId, version } = useContext(MeetingDataContext);
  const authData = useContext(AuthContext);
  const { trackEvent } = useIntercom();
  const [templates, setTemplates] = useState<TemplateData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (authData.userId.length && section === 'user') {
      return dbListenForUserTemplates(authData.userId, setTemplates, setLoading);
    }

    if (authData.userId.length && section === 'shepherd') {
      return dbListenForShepherdTemplates(setTemplates, setLoading);
    }
    return () => { };
  }, [authData.userId]);

  const handleInsert = (templateHtml: string) => {
    console.log('Pressed handle insert click');

    console.log('meeting version', version);

    if (version <= 2) {
      const headless = createHeadlessFirepad(tab, meetingId, authData.userId);
      headless.setHtml(templateHtml);
      toastInfo('Inserted', 'Template inserted');
      logTemplateUserAction(trackEvent, INSERT_EVENT, TEMPLATES_NOTE_TEMPLATE_FIELD, version);
      setIsTemplatesOpen(false);
    }

    if (version >= 3 && editorView) {
      const { schema } = editorView.state;
      const { state } = editorView;
      const parser = DOMParser.fromSchema(schema);

      const tmpNode = document.createElement('div');
      tmpNode.innerHTML = templateHtml;

      const newNodes = parser.parse(tmpNode);

      const { tr } = state;
      const head = state.selection.$head.pos ?? 0;
      if (head === 0) {
        console.log('Head is zero');
        toastDanger('Error', 'Cannot insert template at the start of the document.');
        return;
      }
      editorView.dispatch((tr as Transaction).insert(head, newNodes.content));
      toastInfo('Inserted', 'Template inserted');
      logTemplateUserAction(trackEvent, INSERT_EVENT, TEMPLATES_NOTE_TEMPLATE_FIELD, version);
      setIsTemplatesOpen(false);
    }
  };

  const handleDelete = (templateId: string) => {
    console.log('Pressed handle delete click');
    dbDeleteUserTemplate(authData.userId, templateId);
    logTemplateUserAction(trackEvent, DELETE_EVENT, TEMPLATES_NOTE_TEMPLATE_FIELD, version);
  };

  const handleEdit = (template:TemplateData) => {
    setSelectedTemplateToEdit(template);
    handleInsert(template.template);
  };

  const handleClose = () => {
    setIsTemplatesOpen(false);
    logTemplateUserAction(trackEvent, CLOSE_EVENT, TEMPLATES_BROWSE_TEMPLATES_VIEW_FIELD, version);
  };

  const filteredTemplates = templates.filter(
    (template) => !isFirepadMeetingAndProseMirrorTemplate(version, template),
  );

  if (section === 'user') {
    return (
      <>
        <TemplateHeaderContainer>
          <TemplateHeader>
            My saved templates
          </TemplateHeader>
          <TemplateIconWrapper onClick={handleClose}>
            <CloseIcon fill={gray9} />
          </TemplateIconWrapper>
        </TemplateHeaderContainer>
        <TemplateCardsContent
          section={section}
          loading={loading}
          templates={filteredTemplates}
          handleEdit={handleEdit}
          handleInsert={handleInsert}
          handleDelete={handleDelete}
          setIsTemplatesOpen={setIsTemplatesOpen}
        />
      </>
    );
  }
  if (section === 'shepherd' && filteredTemplates.length !== 0) {
    return (
      <ShepherdContainer>
        <TemplateHeaderContainer>
          <TemplateHeader>
            Shepherd templates
          </TemplateHeader>
        </TemplateHeaderContainer>
        <TemplateCardsContent
          section={section}
          loading={loading}
          templates={filteredTemplates}
          handleInsert={handleInsert}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          setIsTemplatesOpen={setIsTemplatesOpen}
        />
      </ShepherdContainer>
    );
  }

  return null;
};

export default TemplatesContent;

const isFirepadMeetingAndProseMirrorTemplate = (
  meetingVersion: MeetingVersion, template: TemplateData,
) => {
  if (meetingVersion <= 2) {
    if (template.version <= 2) {
      return true;
    }
  }

  return false;
};
