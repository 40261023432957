import React, { useContext } from 'react';
import styled from 'styled-components';
import ProfileIcon from '../../../shared/components/profile-icon';
import { green6 } from '../../../shared/colours';
import WelcomeMessage from './WelcomeMessage';
import HelpButtonGroup from './HelpButtonGroup';
import { UserCenterPage } from '../../../shared/types/types';
import WelcomeBannerCloseIcon from './WelcomeBannerCloseIcon';
import { UserDataContext } from '../../../App';

const Container = styled.div`
  height: 110px;
  background: black;
  display: flex;
  justify-content: space-between;
`;

const LeftPartContainer = styled.div`
  padding: 18px;
  display: flex;
  align-items: center;
  height: 100%;

  // profile icon
  & > div:first-child {
    cursor: default;
  }
`;

const RightPartContainer = styled.div`
  position: relative;
  width: 170px;
  padding: 20px 38px 20px 21px;
  background: #000;
`;

interface Props {
  section: UserCenterPage,
  handleWelcomeBannerClose: () => void,
  isClosed: boolean,
}

const WelcomeBanner = ({
  section, handleWelcomeBannerClose, isClosed,
}: Props) => {
  const userData = useContext(UserDataContext);

  if (section !== 'tasks' || isClosed) return null;
  return (
    <Container>
      <LeftPartContainer>
        <ProfileIcon size={75} user={userData.publicUserData} color={green6} borderWidth={6} />
        <WelcomeMessage userName={userData.data.firstName} />
      </LeftPartContainer>
      <RightPartContainer>
        <HelpButtonGroup />
        <WelcomeBannerCloseIcon onClick={handleWelcomeBannerClose} />
      </RightPartContainer>
    </Container>
  );
};

export default WelcomeBanner;
